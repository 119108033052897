import { BUY_GUARDIAN, SET_FILTERS, SET_IN_STATE, SET_SELECTED_GUARDIAN } from '../actions/market';
import { AnyAction } from 'redux';
import { Seiren } from '../../types/Seiren';

interface ReducerState {
    guardians?: Seiren.GuardianCard[];
    topGuardians?: Seiren.GuardianCard[];
    pages: number;
    currentPage: number;
    filters?: {
        element?: Seiren.CardElement;
        level?: number;
        price?: {
            min?: number;
            max?: number;
        };
        search?: string;
    };
    status: 'default' | 'buy';
    selected: {
        guardian?: Seiren.GuardianCard;
    };
}

const initialState: ReducerState = {
    guardians: [],
    topGuardians: [],
    selected: {
        guardian: undefined,
    },
    pages: 0,
    currentPage: 1,
    filters: {},
    status: 'default',
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_IN_STATE: {
            return { ...state, ...action.payload };
        }
        case SET_FILTERS: {
            return {
                ...state,
                filters: { ...state.filters, ...action.payload.filters },
            };
        }
        case SET_SELECTED_GUARDIAN: {
            return {
                ...state,
                selected: { ...state.selected, ...action.payload },
            };
        }
        case BUY_GUARDIAN: {
            return {
                ...state,
                guardians: state.guardians!.filter((i) => i.id !== action.payload.guardian.id),
                status: 'default',
                selected: initialState.selected,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
