import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonBase, Typography } from '@mui/material';
import { CardTooltip, GuardianCard, UnselectedCard } from '../../../components/common/cards';
import { ReactComponent as LeftArrow } from '../../../assets/battle/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../../assets/battle/right-arrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { ThumbsMethods } from 'swiper/types';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/grid/grid.min.css';
import SwiperCore, { Grid } from 'swiper';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import { setSelectedMaterial } from '../../../redux/actions/player';
import { CardSkeleton, Dialog } from '../../../components';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';
import { useGuardians } from '../../../api';
import { useSnackbar } from 'notistack';

SwiperCore.use([Grid]);

interface Props {
    className?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    materialCards?: any;
    setMaterialCard?: any;
}

const Component: FC<Props> = ({ className, open, setOpen }) => {
    const { t } = useTranslation();

    const cardsSwiper = useRef<ThumbsMethods>(null);
    const selectedGuardian = useSelector((state: RootState) => state.player.selected.guardian);
    const dispatch = useAppDispatch();
    const [material, setMaterial] = useState<Seiren.GuardianCard>();
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const { enqueueSnackbar } = useSnackbar();

    const [page, setPage] = useState(1);
    const {
        error,
        status,
        isLoading,
        data: guardians,
    } = useGuardians(authKey, {
        page,
        destroyed: true,
    });

    let filterGuardians;

    if (selectedGuardian) {
        filterGuardians = guardians?.list.filter((item: any) => item.id !== selectedGuardian.id);
    }

    const close = () => {
        setOpen(false);
    };

    const confirm = () => {
        if (material) {
            dispatch(setSelectedMaterial({ material: material }));
        }
        setOpen(false);
    };

    const renderCard = (item: Seiren.GuardianCard) => (
        <SwiperSlide key={item.id}>
            <CardTooltip {...item}>
                <GuardianCard
                    {...item}
                    checkbox
                    checked={material ? material.id === item.id : false}
                    setChecked={() => setMaterial(item)}
                />
            </CardTooltip>
        </SwiperSlide>
    );

    const renderCardSkeletons = () => {
        return new Array(4).fill(null).map((tx, index) => (
            <SwiperSlide key={index}>
                <CardSkeleton withTitle size="md" />
            </SwiperSlide>
        ));
    };

    useEffect(() => {
        if (status === 'error') {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    }, [status]);

    return (
        <Dialog
            title={t('pages:player.guardians.guardianUpgradeMaterial.chooseTheMaterial')}
            description={t('pages:player.guardians.guardianUpgradeMaterial.youCanChooseOneMaterialCard')}
            open={open}
            className={className}
            onClose={close}
            fullWidth
            maxWidth="md"
        >
            <Typography>
                {t('pages:player.guardians.guardianUpgradeMaterial.theMaterialCardWillDisappearAfterTheGuardiansUpgra')}
            </Typography>
            <div className="slider-wrapper">
                <ButtonBase onClick={() => cardsSwiper.current!.swiper.slidePrev()}>
                    <LeftArrow />
                </ButtonBase>
                <Swiper
                    // @ts-ignore
                    ref={cardsSwiper}
                    slidesPerView={4}
                    spaceBetween={16}
                    centerInsufficientSlides
                    className="cards-carousel"
                    allowTouchMove={false}
                >
                    {page >= 2 && !isLoading && (
                        <SwiperSlide>
                            <UnselectedCard
                                size="md"
                                className="unselected-md-card"
                                unselectedTitle="Previous page"
                                onClick={() => setPage((prev) => prev - 1)}
                                disabled={isLoading}
                            />
                        </SwiperSlide>
                    )}
                    {!isLoading ? filterGuardians?.map(renderCard) : renderCardSkeletons()}
                    {guardians && guardians.pagination.pages > 1 && !isLoading && page !== guardians.pagination.pages && (
                        <SwiperSlide>
                            <UnselectedCard
                                size="md"
                                className="unselected-md-card"
                                unselectedTitle="Next page"
                                onClick={() => setPage((prev) => prev + 1)}
                                disabled={isLoading}
                            />
                        </SwiperSlide>
                    )}
                </Swiper>
                <ButtonBase onClick={() => cardsSwiper.current!.swiper.slideNext()}>
                    <RightArrow />
                </ButtonBase>
            </div>
            <Button variant="contained" onClick={confirm}>
                {t('pages:player.guardians.guardianUpgradeMaterial.back')}
            </Button>
        </Dialog>
    );
};

export default styled(Component)`
    .slider-wrapper {
        display: flex;
        width: 100%;
        margin: ${({ theme }) => theme.spacing(3, 0)};
        box-sizing: inherit;
    }

    .cards-carousel {
        width: 100%;
        margin: ${({ theme }) => theme.spacing(4, 0)};
    }

    .unselected-md-card {
        align-self: flex-start;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 142px;
    }
`;
