import React, { FC } from 'react';
import styled from 'styled-components';
import BottomFront from '../../../assets/card/wrapper/victory/bottom-front.png';
import { ReactComponent as TopVictory } from '../../../assets/card/wrapper/victory/top-victory.svg';
import topBrightCircle from '../../../assets/card/wrapper/victory/top-bright-circle.png';
import cardWebm from '../../../assets/card.webm';

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
}

const Component: FC<Props> = ({ className, children }) => {
    return (
        <div className={className}>
            <div />
            <video loop={true} autoPlay={true} muted className="background-lights">
                <source src={cardWebm} type="video/webm"></source>
            </video>
            <img src={BottomFront} alt="" className="bottom" />
            <div className="children">
                <div className="top-items">
                    <TopVictory />
                    <img src={topBrightCircle} className="top-circle" alt="top-circle" />
                </div>
                {children}
            </div>
        </div>
    );
};

export default styled(Component)`
    min-height: 630px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    padding: ${({ theme }) => theme.spacing(2, 0)};

    .background-lights {
        position: absolute;
        z-index: -1;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .top-items {
        position: absolute;
        z-index: 1;
        top: -33px;
        left: calc(50% - 87px);
    }

    .top-circle {
        position: absolute;
        top: -135px;
        right: -135px;
    }

    .children {
        position: absolute;
        z-index: -1;
        top: calc(50% - 212px);
    }
`;
