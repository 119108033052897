import React, { FC } from 'react';
import styled from 'styled-components';
import { InputBase, InputBaseProps } from '@mui/material';

interface Props extends InputBaseProps {
    className?: string;
}

const Component: FC<Props> = ({ className, ...props }) => {
    return <InputBase className={className} {...props} />;
};

export default styled(Component)`
    background: #171256;
    border: 1px solid #2c2680;
    border-radius: 5px;
    color: ${({ theme }) => theme.palette.secondary.contrastText} !important;
    padding: ${({ theme }) => theme.spacing(0.5, 1)};
    .Mui-disabled {
        -webkit-text-fill-color: #514b95 !important;
    }
`;
