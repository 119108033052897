import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { Divider } from '../../../components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <Divider />
            <Typography textAlign="center" variant="h5" gutterBottom color="white" className="txt-play-game">
                {t('pages:home.layout.header.playGamesWin')}
            </Typography>
            <Typography textAlign="center" variant="h1" color="white">
                {t('pages:home.layout.header.aokRewards')}
            </Typography>
            <Button disableRipple variant="contained" size="large" className="play-btn" component={Link} to={'/battle'}>
                {t('pages:home.layout.header.playNow')}
                {/*<Typography variant="subtitle2" className="btn-subtitle">*/}
                {/*    {t('pages:home.layout.header.goingToSaveSeiren')}*/}
                {/*</Typography>*/}
            </Button>
        </div>
    );
};

export default styled(Component)`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-transform: uppercase;

    .txt-play-game {
        font-family: 'Poppins', sans-serif;
        padding: ${({ theme }) => theme.spacing(1)};
    }

    .play-btn {
        transition: all 150ms;
        margin-top: ${({ theme }) => theme.spacing(10)};

        &:hover {
            transform: scale(1.3);
        }
    }
`;
