import { useQuery } from 'react-query';
import { Seiren } from '../types/Seiren';
import axios from 'axios';
import _ from 'lodash';

type Params = {
    guardian: Seiren.GuardianCard;
    bonus: Seiren.Bonus;
};

async function fetch(token: string, params: Params) {
    try {
        const { data } = await axios.get(`${axios.defaults.baseURL}/enemies`, {
            headers: { Authentication: token },
            params: {
                guardian: params.guardian.id,
                bonus: params.bonus.id,
            },
        });

        if (!data?.error) {
            return data.data.map((g: Seiren.GuardianCard) =>
                _.mapKeys(g, (v: Seiren.GuardianCard, k: string) => _.camelCase(k)),
            );
        } else {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}

export default function (token: string, params: Params, options?: Record<string, any>) {
    return useQuery<Seiren.GuardianCard[], Error>(['enemies', token, params], () => fetch(token, params), options);
}
