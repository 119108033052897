import { useQuery } from 'react-query';
import axios from 'axios';
import _ from 'lodash';

interface Response {
    authMinutes?: number;
    authPrefix?: string;
    bonusFee?: number;
    bonusPrefix?: string;
    confirmations?: number;
    destroyAddress?: string;
    earlyClaim?: number;
    enhanceFee?: number;
    fightFee?: number;
    fightPayout?: number;
    fightPayoutMax?: number;
    fusionFee?: number;
    guardianFee?: number;
    guardianPrefix?: string;
    minDeposit?: number;
    minWithdrawal?: number;
    releaseHours?: number;
    ticker?: string;
    withdrawalFee?: number;
}

async function fetch() {
    try {
        const { data } = await axios.get(`${axios.defaults.baseURL}/system/settings`);

        if (!data?.error) {
            return _.mapKeys(data.data, (value, key) => _.camelCase(key));
        } else {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}

export default function (options?: Record<string, any>) {
    return useQuery<Response, Error>(['settings'], () => fetch(), options);
}
