import React, { FC, MouseEventHandler, useState } from 'react';
import { ButtonBase, Grid, Typography, useTheme } from '@mui/material';
import defaultAvatar from '../../../assets/buff/avatar-poseidon.svg';
import { CardInfoItem } from '../index';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';
import { a, useSpring } from 'react-spring';
import styled from 'styled-components';

import yellowEl from '../../../assets/elements/yellow.png';
import greenEl from '../../../assets/elements/green.png';
import blueEl from '../../../assets/elements/blue.png';
import redEl from '../../../assets/elements/red.png';
import neutralEl from '../../../assets/elements/neutral.png';
import fightPoints from '../../../assets/card/fightPoints/fightPoints.png';
import heartIcon from '../../../assets/card/heart.svg';
import starIcon from '../../../assets/card/star.svg';
import backImage from '../../../assets/card/noCard/No_Card_Selet_Img_252px@3x.png';
import frame from '../../../assets/card/frames/frame_other.png';
import back_earth from '../../../assets/card/background/earth.png';
import back_fire from '../../../assets/card/background/fire.png';
import back_water from '../../../assets/card/background/water.png';
import back_lightning from '../../../assets/card/background/lightning.png';

interface Props extends Seiren.Bonus {
    className?: string;
    size?: string;
    flipEnabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Component: FC<Props> = ({
    className,
    power,
    name,
    avatar,
    checkbox,
    checked,
    setChecked,
    element,
    stars,
    size,
    durability,
    flipEnabled,
    attributes,
    onClick,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [flipped, set] = useState(false);
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
        config: {
            mass: 5,
            tension: 500,
            friction: 80,
            duration: 150,
        },
    });

    // element and background color logic
    let elImg;
    let color;

    switch (element) {
        case 'lightning':
            elImg = yellowEl;
            color = theme.card.yellowLv2;
            break;
        case 'earth':
            elImg = greenEl;
            color = theme.card.greenLv2;
            break;
        case 'water':
            elImg = blueEl;
            color = theme.card.blueLv2;
            break;
        default:
            elImg = redEl;
            color = theme.card.redLv2;
    }

    const flipCard = () => {
        if (flipEnabled) {
            set((state) => !state);
        }
    };

    const backContent = (
        <div className="back-content">
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Element'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {element && element[0]?.toUpperCase() + element?.slice(1)}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Stars'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {stars}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Durability'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {durability}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Power'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {power}
                </Typography>
            </div>
            {attributes.map((attr) => (
                <div key={attr.id}>
                    <Typography fontWeight="bold" variant="body2" textTransform="capitalize" display="inline" pr={1}>
                        {attr.element}
                    </Typography>
                    <Typography display="inline" variant="body2">
                        {attr.value}
                    </Typography>
                </div>
            ))}
        </div>
    );

    const title = name && (
        <Typography
            color={checked ? 'primary' : 'white'}
            variant="h7"
            textOverflow="ellipsis"
            width="80%"
            align="center"
            minHeight={48}
        >
            {name}
        </Typography>
    );

    const lgBonusContent = (
        <>
            <a.div
                className="lg-card"
                style={{
                    opacity: opacity.to((o) => 1 - o),
                    transform,
                    background:
                        element === 'earth'
                            ? `url(${back_earth})`
                            : element === 'fire'
                            ? `url(${back_fire})`
                            : element === 'lightning'
                            ? `url(${back_lightning})`
                            : `url(${back_water})`,
                }}
            >
                <Grid container alignItems="center" wrap="nowrap" spacing={1}>
                    <Grid xs="auto" item>
                        <CardInfoItem title={stars ? stars : 0} icon={starIcon} />
                    </Grid>
                    <Grid xs="auto" item>
                        <CardInfoItem title={durability ? durability : 0} icon={heartIcon} />
                    </Grid>
                    <Grid xs item display="flex" justifyContent="flex-end">
                        <img src={elImg} alt={t('components:common.cards.bonusCard.element')} height={34} />
                    </Grid>
                </Grid>
                <Grid container py={2} justifyContent="center" marginY={2}>
                    <img
                        src={avatar || defaultAvatar}
                        alt={t('components:common.cards.bonusCard.avatar')}
                        height={140}
                    />
                </Grid>
                <Grid container spacing={1}>
                    <Grid xs="auto" item>
                        <CardInfoItem title={power} icon={fightPoints} />
                    </Grid>
                    {attributes.map((attr) => (
                        <Grid xs="auto" item key={attr.id}>
                            <CardInfoItem
                                title={attr.value}
                                icon={
                                    attr.element === 'earth'
                                        ? greenEl
                                        : attr.element === 'fire'
                                        ? redEl
                                        : attr.element === 'lightning'
                                        ? yellowEl
                                        : attr.element === 'water'
                                        ? blueEl
                                        : neutralEl
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </a.div>
            <a.div
                className="card-back"
                style={{
                    opacity,
                    transform,
                    rotateY: '180deg',
                }}
            >
                {backContent}
            </a.div>
        </>
    );

    const lgBonus = (
        <div onClick={flipCard}>
            {checkbox ? (
                <ButtonBase onClick={() => setChecked!(!checked)} className="lg-card-box">
                    {lgBonusContent}
                </ButtonBase>
            ) : (
                <div className="lg-card-box">{lgBonusContent}</div>
            )}
            <Grid container justifyContent="center" width={250}>
                {title}
            </Grid>
        </div>
    );

    const mdBonusContent = (
        <Grid container flexDirection="column" alignItems="center" rowSpacing={2}>
            <Grid item>
                <img src={avatar || defaultAvatar} alt={t('components:common.cards.bonusCard.avatar')} height={100} />
            </Grid>
            <Grid item>
                <CardInfoItem title={durability ? durability : 0} icon={heartIcon} />
            </Grid>
        </Grid>
    );

    const mdBonus = (
        <div>
            {checkbox ? (
                <ButtonBase onClick={() => setChecked!(!checked)} className="md-card" style={{ background: color }}>
                    {mdBonusContent}
                </ButtonBase>
            ) : (
                <div className="md-card" style={{ background: color }}>
                    {mdBonusContent}
                </div>
            )}
            <Grid container justifyContent="center" width={140}>
                {title}
            </Grid>
        </div>
    );

    if (onClick) {
        return (
            <ButtonBase className={className} onClick={onClick}>
                {size === 'lg' ? lgBonus : mdBonus}
            </ButtonBase>
        );
    }

    return <div className={className}>{size === 'lg' ? lgBonus : mdBonus}</div>;
};

export default styled(Component)`
    .lg-card {
        position: relative;
        //overflow: hidden;
        border-radius: ${({ theme }) => theme.spacing(1)};
        padding: ${({ theme }) => theme.spacing(4, 3)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        width: 250px;
        height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // border: ${({ theme, checked }) => (checked ? `2px solid ${theme.palette.primary.main}` : 'none')};
        opacity: ${({ checked }) => (checked ? '0.6 !important' : '1')};

        /*&:after {
            content: '';
            display: ${({ checked }) => (checked ? 'block' : 'none')};
            position: absolute;
            width: 100%;
            height: 100%;
            background: black;
            opacity: 0.5;
        }*/

        &:before {
            content: '';
            position: absolute;
            background: url(${frame});
            width: calc(100% + 20px);
            height: calc(100% + 25px);
            background-size: contain;
            background-repeat: no-repeat;
            top: -10px;
            left: -10px;
        }
    }

    .md-card {
        position: relative;
        overflow: hidden;
        border-radius: ${({ theme }) => theme.spacing(2)};
        padding: ${({ theme }) => theme.spacing(2)};
        margin-bottom: ${({ theme }) => theme.spacing(1)};
        width: 140px;
        height: 180px;
        border: ${({ theme, checked }) => (checked ? `2px solid ${theme.palette.primary.main}` : 'none')};

        &:after {
            content: '';
            display: ${({ checked }) => (checked ? 'block' : 'none')};
            position: absolute;
            width: 100%;
            height: 100%;
            background: black;
            opacity: 0.5;
        }
    }

    .card-back {
        height: 360px;
        width: 250px;
        position: absolute;
        border-radius: ${({ theme }) => theme.spacing(1)};
        background: ${({ theme }) => theme.palette.secondary.light};
        border: ${({ theme }) => theme.palette.primary.main} solid 1px;

        &:before {
            content: '';
            position: absolute;
            background: url(${frame});
            width: calc(100% + 20px);
            height: calc(100% + 25px);
            background-size: contain;
            background-repeat: no-repeat;
            top: -10px;
            left: -10px;
        }
    }

    .lg-card-box {
        display: flex;
    }

    .back-content {
        color: ${({ theme }) => theme.palette.common.white};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background: url(${backImage}) no-repeat center;
    }
`;
