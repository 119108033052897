import React, { FC } from 'react';
import { GridProps, styled, Typography } from '@mui/material';
import SubHeaderElement from '../../assets/divider/subheader.svg';

interface Props extends GridProps {
    className?: string;
    title: string;
}

const Component: FC<Props> = ({ className, title }) => {
    return (
        <div className={className}>
            <img src={SubHeaderElement} className="element" alt="sub-header" />
            <Typography className="title" align="center" noWrap variant="h5" color="primary">
                {title}
            </Typography>
            <img src={SubHeaderElement} className="element right-element" alt="sub-header" />
        </div>
    );
};

export default styled(Component)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(2)};

    .title {
        margin: 0 ${({ theme }) => theme.spacing(2)};
        word-break: break-word;
        overflow: inherit;
    }

    .element {
        height: 19px;
        object-fit: fill;
        min-width: ${({ theme }) => theme.spacing(2)};
    }

    .right-element {
        transform: scaleX(-1);
    }
`;
