import React, { FC, useState } from 'react';
import { ButtonBase, Typography } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';
import QRCode from 'qrcode.react';

import styled from 'styled-components';
import { RootState } from '../../../redux/Store';
import { useSelector } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import GetTokensDialog from './GetTokensDialog';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const [getTokensOpened, setGetTokensOpened] = useState(false);
    const address = useSelector((state: RootState) => state.player.player.deposit) || '';
    const { enqueueSnackbar } = useSnackbar();

    const onCopy = () => {
        enqueueSnackbar('Address has been successfully copied to clipboard.');
    };

    return (
        <div className={className}>
            <Typography variant="h6" align="center" color="white" gutterBottom>
                {t('pages:player.deposit.deposit.deposit')}
            </Typography>
            <div className="details-container">
                <Typography color="secondary.contrastText" variant="caption" component="p" textAlign="center">
                    {t('pages:player.deposit.deposit.uponSuccessfulDepositTheDepositWillBeReflectedOnTh')}
                </Typography>
                <Typography color="secondary.contrastText" variant="caption" component="p" textAlign="center">
                    {t('pages:player.deposit.deposit.pleaseProceedWithTheDepositByScanningTheQrCodeToEn')}
                </Typography>
            </div>
            <ButtonBase href={`aok://deposit?address=${address}&token=SERG`} target="_blank">
                <QRCode
                    size={174}
                    value={`aok://deposit?address=${address}&token=SERG`}
                    bgColor="white"
                    className="qr-code"
                />
            </ButtonBase>
            <Typography color="white" gutterBottom>
                {address}
                <CopyToClipboard onCopy={onCopy} text={address}>
                    <ButtonBase>
                        <ContentCopyOutlined className="icon-btn" />
                    </ButtonBase>
                </CopyToClipboard>
            </Typography>
            {/*<Button
                disableRipple
                variant="framed"
                color="secondary"
                className="button"
                onClick={() => setGetTokensOpened(true)}
            >
                <Typography variant="title">Get Tokens</Typography>
            </Button>*/}
            <GetTokensDialog open={getTokensOpened} setOpen={setGetTokensOpened} />
        </div>
    );
};

// Deposit.defaultProps = {
//     address: ' ',
// };

export default styled(Component)`
    display: flex;
    flex-direction: column;
    align-items: center;

    .qr-code {
        border: 16px solid ${({ theme }) => theme.palette.common.white};
        margin: ${({ theme }) => theme.spacing(4, 0)};
    }

    .icon-btn {
        color: ${({ theme }) => theme.palette.common.white};
        height: 16px;
    }

    .share-btn {
        background-color: ${({ theme }) => theme.palette.secondary.contrastText};
        margin: ${({ theme }) => theme.spacing(4, 0, 0.5, 0)};

        &:focus {
            background-color: ${({ theme }) => theme.palette.secondary.contrastText};
        }
    }

    .button {
        margin-top: ${({ theme }) => theme.spacing(3)};
        min-width: 200px;
    }
`;
