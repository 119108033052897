import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    ButtonBase,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import styled from 'styled-components';
import axios, { AxiosResponse } from 'axios';
import * as bitcoinMessage from 'bitcoinjs-message';

import loginBack from '../../assets/backgrounds/background-2/background_02@2x.jpg';
import { Caption } from './layout';
import { Divider } from '../../components';
import QRCode from 'qrcode.react';
import { io, Socket } from 'socket.io-client';
import { useAppDispatch } from '../../redux/Store';
import { loginUser } from '../../redux/actions/app';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const API_URL = 'https://api.seirenwar.com/v1';
const CALLBACK_URL = 'https://callback.seirenwar.com';
const PREFIX = '\x14AOK Signed Message:\n';

const uuid4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const dispatch = useAppDispatch();
    const socket = useRef<Socket>(null);
    const [session] = useState(uuid4());
    const [message, setMessage] = useState('');
    const [QRCodeData, setQRCodeData] = useState<string>();
    const [socketData, setSocketData] = useState<{ address: string; signature: string }>();

    const onSocketCallback = useCallback(({ address, signature }) => {
        setSocketData({
            address,
            signature,
        });
    }, []);

    const getNewQRCodeData = () => {
        return `aok://sign?callback=${CALLBACK_URL}/call/${session}&message=${message}`;
    };

    const getServerInfoFromAPI = async () => {
        try {
            const {
                data: {
                    data: { time, prefix },
                },
            }: AxiosResponse<{ data: { time: string; prefix: string } }> = await axios.get(`${API_URL}/system/time`);

            setMessage(`${prefix}/${time}`);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (message) {
            setQRCodeData(getNewQRCodeData());
        }
    }, [message]);

    useEffect(() => {
        if (socketData) {
            if (bitcoinMessage.verify(message!, socketData.address, socketData.signature, PREFIX)) {
                dispatch(
                    loginUser({
                        address: socketData.address,
                        message: message!,
                        signature: socketData.signature,
                    }),
                );

                socket.current!.off(session, onSocketCallback);
            }
        }
    }, [socketData]);

    useEffect(() => {
        // @ts-ignore
        socket.current = io(CALLBACK_URL, {
            transports: ['websocket'],
            upgrade: false,
        });
        socket.current.emit('callback', session);
        socket.current.on(session, onSocketCallback);

        getServerInfoFromAPI();

        return () => {
            // @ts-ignore
            socket.current.off(session, onSocketCallback);
        };
    }, []);

    return (
        <div className={`${className} background`}>
            <Container>
                <div className="wrapper">
                    {QRCodeData ? (
                        <ButtonBase href={QRCodeData} target="_blank">
                            <QRCode size={174} value={QRCodeData} bgColor="white" className="qr-code" />
                        </ButtonBase>
                    ) : (
                        <div className="qr-code-skeleton">
                            <Skeleton width="100%" height="100%" variant="rectangular" />
                        </div>
                    )}
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <div className="item-number">
                                    <Typography variant="caption">1</Typography>
                                </div>
                            </ListItemIcon>
                            <ListItemText
                                //todo hardcoded
                                primary={
                                    <Typography color="white">
                                        {mobile
                                            ? t('pages:login.login.openAokWalletAppOnMobile')
                                            : t('pages:login.login.openAokWalletApp')}
                                    </Typography>
                                }
                                secondary={
                                    <Typography color="grey.500" variant="subtitle2">
                                        {t('pages:login.login.ifYouDonTHaveTheWalletDownloadItUsingTheLinkBelow')}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <div className="item-number">
                                    <Typography variant="caption">2</Typography>
                                </div>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography color="white">
                                        {t('pages:login.login.selectAddressWhatYouWantToSignIn')}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <div className="item-number">
                                    <Typography variant="caption">3</Typography>
                                </div>
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography color="white">{t('pages:login.login.openQrScan')}</Typography>}
                            />
                        </ListItem>
                    </List>
                    <Divider className="login-divider" />
                    <Caption />
                </div>
            </Container>
        </div>
    );
};

export default styled(Component)`
    background-image: url(${loginBack});
    padding-top: ${({ theme }) => theme.spacing(12)};

    .wrapper {
        margin-top: ${({ theme }) => theme.spacing(9)};
        display: flex;
        align-items: center;
        flex-flow: column;
    }

    .qr-code {
        border: ${({ theme }) => theme.spacing(2)} solid ${({ theme }) => theme.palette.common.white};
        margin-bottom: ${({ theme }) => theme.spacing(5)};
    }

    .qr-code-skeleton {
        background-color: ${({ theme }) => theme.palette.common.white};
        margin-bottom: ${({ theme }) => theme.spacing(5)};
        padding: ${({ theme }) => theme.spacing(2)};
        height: 174px;
        width: 174px;
    }

    .item-number {
        border-radius: 12px;
        width: 18px;
        height: 18px;
        color: black;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login-divider {
        padding: ${({ theme }) => theme.spacing(8, 0, 2.5, 0)};
    }
`;
