import React, { FC } from 'react';
import { Dialog, DialogContent, DialogProps, IconButton, styled, Typography } from '@mui/material';
import { Divider } from '../common';
import CloseIcon from '@mui/icons-material/Close';

interface Props extends DialogProps {
    className?: string;
    title: string;
    description?: string;
    open: boolean;
    onClose: () => void;
    closeDisabled?: boolean;
    background?: string;
}

const Component: FC<Props> = ({
    className,
    title,
    description,
    closeDisabled,
    background,
    children,
    onClose,
    open,
    ...props
}) => {
    return (
        <Dialog
            open={open}
            className={className}
            onClose={closeDisabled !== undefined && closeDisabled ? undefined : onClose}
            {...props}
        >
            {background && (
                <>
                    <div className="bg-blurred" />
                    <div className="bg-image" />
                </>
            )}
            <IconButton className="close-btn" disabled={closeDisabled} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent className="content">
                <Divider />
                <Typography variant="h4" color="primary" align="center" marginTop={3}>
                    {title}
                </Typography>
                {description && (
                    <Typography color="primary" variant="subtitle2" marginTop={1} marginBottom={2} align="center">
                        {description}
                    </Typography>
                )}
                {children}
            </DialogContent>
        </Dialog>
    );
};

Component.defaultProps = {
    fullWidth: true,
};

export default styled(Component)`
    text-align: center;

    .MuiPaper-root {
        position: relative;
        background: ${({ theme }) => theme.palette.secondary.dark};
        border-radius: 0;
        border: 10px solid;
        border-image-slice: 1;
        border-width: 5px;
        overflow: hidden;
        border-image-source: linear-gradient(180deg, #fceeae 0%, rgba(252, 238, 174, 0) 100%);
    }

    .bg-image {
        position: absolute;
        background-image: url(${({ background }) => background});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        filter: blur(10px);
        transform: rotate(25deg);
        height: 100%;
        width: 100%;
        top: -25%;
        left: -25%;
        z-index: 1;
    }

    .bg-blurred {
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(
            145.77deg,
            rgba(21, 67, 92, 0.8) -29.5%,
            rgba(50, 118, 156, 0.8) 9.04%,
            rgba(50, 118, 156, 0) 41.55%
        );
        z-index: 2;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 4;
    }

    .content {
        z-index: 3;
    }
`;
