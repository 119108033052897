import React, { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <Typography textAlign="center" className="footer-txt" color="secondary.contrastText">
                {t('app:footer.allRightsReserved')}
            </Typography>
        </div>
    );
};

export default styled(Component)`
    border-top: 2px solid ${({ theme }) => theme.palette.secondary.contrastText};

    .footer-txt {
        padding: ${({ theme }) => theme.spacing(2, 0, 20, 0)};
    }
`;
