import React, { FC } from 'react';
import { styled, Tooltip } from '@mui/material';
import redElement from '../../../assets/elements/red.png';
import { ChevronRight } from '@mui/icons-material';
import greenElement from '../../../assets/elements/green.png';
import blueElement from '../../../assets/elements/blue.png';
import yellowElement from '../../../assets/elements/yellow.png';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <Tooltip title={t('pages:battle.components.elements.strongerThanEarthAndWeakerThanLightning')}>
                <img src={redElement} className="element" alt="red-element" />
            </Tooltip>
            <ChevronRight color="inherit" fontSize="large" />
            <Tooltip title={t('pages:battle.components.elements.strongerThanWaterAndWeakerThanFire')}>
                <img src={greenElement} className="element" alt="green-element" />
            </Tooltip>
            <ChevronRight color="inherit" fontSize="large" />
            <Tooltip title={t('pages:battle.components.elements.strongerThanLightningAndWeakerThanEarth')}>
                <img src={blueElement} className="element" alt="blue-element" />
            </Tooltip>
            <ChevronRight color="inherit" fontSize="large" />
            <Tooltip title={t('pages:battle.components.elements.strongerThanFireAndWeakerThanWater')}>
                <img src={yellowElement} className="element" alt="yellow-element" />
            </Tooltip>
            <ChevronRight color="inherit" fontSize="large" />
            <Tooltip title={t('pages:battle.components.elements.strongerThanEarthAndWeakerThanLightning')}>
                <img src={redElement} className="element" alt="red-element" />
            </Tooltip>
        </div>
    );
};

export default styled(Component)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: ${({ theme }) => theme.palette.secondary.contrastText};

    .element {
        object-fit: contain;
        height: 100%;
    }
`;
