import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk, { ThunkDispatch } from 'redux-thunk';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from '@redux-devtools/extension';

import { app, battle, market, notification, player, swap } from './reducers';
import { useDispatch } from 'react-redux';

const persistConfig = {
    key: 'root',
    whitelist: ['app'],
    blacklist: ['battle'],
    storage,
    stateReconciler: autoMergeLevel2,
};

const reducer = combineReducers({
    app,
    battle,
    market,
    player,
    swap,
    notification,
});

const persistedReducer = persistReducer<ReturnType<typeof reducer>>(persistConfig, reducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export const useAppDispatch = () => useDispatch<AppDispatch & ThunkDispatch<RootState, null, AnyAction>>();

export default store;
