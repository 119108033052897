import React, { FC } from 'react';
import styled from 'styled-components';
import BottomFront from '../../../assets/card/wrapper/defeat/bottom-front.png';
import Front from '../../../assets/card/wrapper/defeat/front.png';

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
}

const Component: FC<Props> = ({ className, children }) => {
    return (
        <div className={className}>
            <div />
            <div className="children">
                <img src={Front} alt="" className="front-item" />
                {children}
            </div>
            <img src={BottomFront} alt="" className="bottom" />
        </div>
    );
};

export default styled(Component)`
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    min-height: 540px;
    padding: ${({ theme }) => theme.spacing(2, 0)};

    .children {
        position: absolute;
    }

    .front-item {
        position: absolute;
        z-index: 1;
        left: 30px;
    }

    .bottom {
        z-index: 1;
    }
`;
