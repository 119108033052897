import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { resetUpgrade } from '../../../redux/actions/player';
import { ReactComponent as UpgradeArrow } from '../../../assets/player/upgrade-arrow.svg';
import FailedPot from '../../../assets/player/upgrade-pot-failed.png';
import victoryBackground from '../../../assets/card/wrapper/victory/background.png';
import { BonusCard, UnselectedCard } from '../../../components/common';
import { BonusTooltip } from '../../../components/common/cards';
import BonusUpgradeMaterial from './BonusUpgradeMaterial';
import { useTranslation } from 'react-i18next';
import { enhance, useBonuses } from '../../../api';
import { useSnackbar } from 'notistack';
import { Dialog } from '../../../components';
import upgradePot from '../../../assets/player/upgrade-pot.svg';
import { useMutation } from 'react-query';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const status = useSelector((state: RootState) => state.player.status);
    const bonus = useSelector((state: RootState) => state.player.selected.bonus);
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const selectedMaterial = useSelector((state: RootState) => state.player.upgrade.material);
    const [bonusUpgrade, setBonusUpgrade] = useState<boolean>(false);
    const { refetch: refetchBonuses } = useBonuses(authKey, { page: 1 });
    const { enqueueSnackbar } = useSnackbar();

    const {
        data: upgraded,
        mutate: makeEnhance,
        status: statusEnhance,
        isError,
        isLoading,
        error,
    } = useMutation(enhance);

    const startUpgrading = () => {
        if (selectedMaterial) {
            makeEnhance({
                token: authKey,
                bonus,
                burn: selectedMaterial,
            });
        }
    };

    const close = () => {
        dispatch(resetUpgrade());
        refetchBonuses();
    };

    const upgradeDialog = () => (
        <>
            <Typography>{t('pages:player.bonuses.bonusUpgrade.sacrificeOneOfYourBonuses')}</Typography>
            <Grid container direction="row" paddingY={3} spacing={6} alignItems="flex-start" justifyContent="center">
                <Grid item md="auto">
                    <Typography variant="h7" color="white" marginBottom={2.5} align="center">
                        {t('pages:player.bonuses.bonusUpgrade.bonus')}
                    </Typography>
                    {bonus && (
                        <BonusTooltip {...bonus}>
                            <BonusCard {...bonus} key={bonus.id} size="lg" />
                        </BonusTooltip>
                    )}
                </Grid>
                <Grid item md="auto">
                    <Typography variant="h7" color="white" marginBottom={2.5} align="center">
                        {t('pages:player.bonuses.bonusUpgrade.material')}
                    </Typography>
                    {selectedMaterial ? (
                        <BonusTooltip {...selectedMaterial}>
                            <BonusCard
                                {...selectedMaterial}
                                size="lg"
                                checkbox={false}
                                onClick={() => setBonusUpgrade(true)}
                            />
                        </BonusTooltip>
                    ) : (
                        <UnselectedCard
                            unselectedTitle="Select Material"
                            size="lg"
                            onClick={() => setBonusUpgrade(true)}
                        />
                    )}
                </Grid>
            </Grid>
            <Button variant="contained" disabled={!selectedMaterial} onClick={startUpgrading}>
                {t('pages:player.bonuses.bonusUpgrade.upgrade')}
            </Button>
            <BonusUpgradeMaterial open={bonusUpgrade} setOpen={setBonusUpgrade} />
        </>
    );

    const upgrading = () => <Typography>{t('pages:player.bonuses.bonusUpgrade.makingANewBonus')}</Typography>;

    const success = () => (
        <div>
            <Typography marginBottom={3}>
                {t('pages:player.bonuses.bonusUpgrade.congratulationsTheBonusYouOwnHasBeenUpgraded')}
            </Typography>
            <BonusTooltip {...upgraded!}>
                <BonusCard {...upgraded!} size="lg" checkbox={false} key={upgraded!.id} />
            </BonusTooltip>
            <div className="upgrade-success-points">
                <Typography color="primary.dark" variant="h4">
                    {bonus.power}
                </Typography>
                <UpgradeArrow className="upgrade-arrow" />
                <Typography color="primary.main" variant="h4">
                    {upgraded!.power}
                </Typography>
            </div>
            <img src={victoryBackground} alt="victory-background" className="victory-background" />
        </div>
    );

    const failed = () => (
        <div>
            <Typography marginBottom={3}>
                {t('pages:player.bonuses.bonusUpgrade.unfortunatelyYourBonusHasBeenDestroyed')}
            </Typography>
            <img src={FailedPot} alt="failed pot" />
        </div>
    );

    useEffect(() => {
        if (statusEnhance === 'error') {
            // @ts-ignore
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        }
    }, [statusEnhance]);

    return (
        <Dialog
            title={t('pages:player.bonuses.bonusUpgrade.upgrade2')}
            description={t('pages:player.bonuses.bonusUpgrade.getANewRewardNow')}
            onClose={close}
            open={bonus && status === 'upgrade'}
            className={className}
            fullWidth
            closeDisabled={isLoading}
            maxWidth="md"
            background={!isLoading && !upgraded && !isError ? upgradePot : undefined}
        >
            {!isLoading && !upgraded && !isError && upgradeDialog()}
            {isLoading && upgrading()}
            {!isLoading && upgraded && success()}
            {!isLoading && isError && failed()}
        </Dialog>
    );
};

export default styled(Component)`
    .upgrade-success-points {
        margin-top: ${({ theme }) => theme.spacing(3)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }

    .upgrade-arrow {
        margin: ${({ theme }) => theme.spacing(0, 3, 0, 3)};
    }

    .victory-background {
        position: absolute;
        z-index: -2;
    }
`;
