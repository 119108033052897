import React, { FC } from 'react';
import { Link, Typography } from '@mui/material';
import styled from 'styled-components';
import { Seiren } from '../../../types/Seiren';
import divider from '../../../assets/divider/default_divider.svg';

interface Props {
    className?: string;
    topPlayer: Seiren.Player;
}

const Component: FC<Props> = ({ className, topPlayer }) => {
    switch (topPlayer.rank) {
        case 1:
            return (
                <div className={className}>
                    <img src={topPlayer.avatar} alt="top-player" className="first-avatar" width={176} height={176} />
                    <Typography color="primary" variant="h3" py={1} fontWeight={700}>
                        {topPlayer.rank}
                    </Typography>
                    <img src={divider} width={144} alt="divider" />
                    <Link href={`https://aokscan.com/address/${topPlayer.address}`} target="_blank" underline="hover">
                        <Typography color="primary" pt={1} width="176px" noWrap variant="h6" fontWeight={700}>
                            {topPlayer.address}
                        </Typography>
                    </Link>
                    <Typography color="primary" pt={1} variant="h7" fontWeight={700}>
                        LVL {topPlayer.level}
                    </Typography>
                    <Typography color="primary" variant="h7" fontWeight={700} fontSize={14}>
                        XP {topPlayer.xp}
                    </Typography>
                </div>
            );
        case 2:
        case 3:
            return (
                <div className={className}>
                    <img src={topPlayer.avatar} alt="top-player" className="second-avatar" width={126} height={126} />
                    <Typography color="primary" variant="h4" pt={1} fontWeight={700}>
                        {topPlayer.rank}
                    </Typography>
                    <Link href={`https://aokscan.com/address/${topPlayer.address}`} target="_blank" underline="hover">
                        <Typography color="primary" pt={1} width="126px" noWrap variant="h7" fontWeight={700}>
                            {topPlayer.address}
                        </Typography>
                    </Link>
                    <Typography color="primary" pt={1} variant="h7" fontWeight={700}>
                        LVL {topPlayer.level}
                    </Typography>
                    <Typography color="primary" variant="h7" fontWeight={700} fontSize={14}>
                        XP {topPlayer.xp}
                    </Typography>
                </div>
            );
        default:
            return null;
    }
};

export default styled(Component)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing(1)};

    .first-avatar {
        border: 4px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: 50%;
    }
    .second-avatar {
        border: 4px solid ${({ theme }) => theme.palette.secondary.light};
        border-radius: 50%;
    }
`;
