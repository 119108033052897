import {
    RESET_ROLL,
    RESET_SACRIFICE,
    RESET_UPGRADE,
    SET_IN_STATE,
    SET_SELECTED_BONUS,
    SET_SELECTED_GUARDIAN,
    SET_SELECTED_MATERIAL,
    RESET_CLAIM,
    RESET_SHOW_INFO,
} from '../actions/player';
import { AnyAction } from 'redux';
import { Seiren } from '../../types/Seiren';

interface ReducerState {
    player?: Seiren.Player;
    withdrawStatus: 'initial' | 'failed' | 'success';
    feeCoefficient: number;
    status: 'default' | 'sell' | 'upgrade' | 'roll' | 'sacrifice' | 'claim';
    selected: {
        guardian?: Seiren.GuardianCard;
        bonus?: Seiren.Bonus;
    };
    upgrade: {
        material?: Seiren.GuardianCard;
    };
}

const initialState: ReducerState = {
    player: {
        username: '',
        level: 0,
        nextXp: 0,
        xp: 0,
        wallet: undefined,
    },
    withdrawStatus: 'initial',
    feeCoefficient: 0,
    status: 'default',
    selected: {
        guardian: undefined,
        bonus: undefined,
    },
    upgrade: {
        material: undefined,
    },
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_IN_STATE: {
            return { ...state, ...action.payload };
        }
        case SET_SELECTED_GUARDIAN: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    guardian: action.payload.guardian,
                },
            };
        }
        case SET_SELECTED_BONUS: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    bonus: action.payload.bonus,
                },
            };
        }
        case SET_SELECTED_MATERIAL: {
            return {
                ...state,
                upgrade: {
                    ...state.upgrade,
                    material: action.payload.material,
                },
            };
        }
        case RESET_UPGRADE: {
            return {
                ...state,
                status: 'default',
                upgrade: initialState.upgrade,
                selected: initialState.selected,
            };
        }
        case RESET_SACRIFICE: {
            return {
                ...state,
                status: 'default',
                selected: initialState.selected,
            };
        }
        case RESET_ROLL: {
            return {
                ...state,
                status: 'default',
                selected: initialState.selected,
            };
        }
        case RESET_CLAIM: {
            return {
                ...state,
                status: 'default',
                selected: initialState.selected,
            };
        }
        case RESET_SHOW_INFO: {
            return {
                ...state,
                status: 'default',
                selected: initialState.selected,
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
