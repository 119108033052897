import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, Button, ButtonBase, Dialog, styled, Typography } from '@mui/material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const Component: FC<Props> = ({ className, open, setOpen }) => {
    const { t } = useTranslation();
    // const [image, setImage] = useState<HTMLInputElement | null>();

    const [fileInput] = useState<HTMLInputElement | null>();
    console.log(fileInput);
    const inputRef = React.useRef<HTMLInputElement>(null);

    function onFileLoad(e: any) {
        const file = e.currentTarget.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
            console.log('IMAGE LOADED: ', fileReader.result);
        };
        fileReader.onabort = () => {
            alert('reading aborted');
        };
        fileReader.onerror = () => {
            alert('reading error');
        };
        fileReader.readAsDataURL(file);
    }

    return (
        <Dialog open={open} className={className} fullWidth maxWidth="lg" PaperComponent={Box}>
            <Typography variant="h3" align="center" color="primary.light">
                {t('pages:player.components.profileImage.profileImage')}
            </Typography>
            <Typography variant="h7" color="white" align="center" className="detail-subtitle">
                {t('pages:player.components.profileImage.registerYourProfileImage')}
            </Typography>
            <ButtonBase className="image-upload-btn">
                <label htmlFor="upload-photo">
                    <input
                        style={{ display: 'none' }}
                        accept="image/*"
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        ref={inputRef}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDrop={onFileLoad.bind(this)}
                        onChange={onFileLoad.bind(this)}
                    />
                    <AddPhotoAlternateOutlinedIcon className="add-image-icon" />
                    <Typography variant="body2">
                        {t('pages:player.components.profileImage.dragTheImageHere')}
                    </Typography>
                </label>
            </ButtonBase>
            <Button variant="outlined" className="close-btn" onClick={() => setOpen(false)}>
                {t('pages:player.components.profileImage.ok')}
            </Button>
        </Dialog>
    );
};

export default styled(Component)`
    .close-btn {
        align-self: center;
    }

    .image-upload-btn {
        height: 220px;
        width: 220px;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.palette.grey[800]};
        color: ${({ theme }) => theme.palette.grey[500]};
        padding: ${({ theme }) => theme.spacing(4)};
        margin: ${({ theme }) => theme.spacing(5)};
    }

    .add-image-icon {
        font-size: 40px;
        margin: ${({ theme }) => theme.spacing(3)};
    }

    .detail-subtitle {
        padding: ${({ theme }) => theme.spacing(4, 0, 4, 0)};
    }
`;
