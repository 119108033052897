import React, { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { GuardianList } from '../../../components';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import { rollCard, sacrificeGuardian, showGuardianInfo, upgradeGuardian } from '../../../redux/actions/player';
import GuardianUpgrade from './GuardianUpgrade';
import GuardianRoll from './GuardianRoll';
import { UnselectedCard } from '../../../components/common';
import GuardianSacrifice from './GuardianSacrifice';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';
import GuardianInfo from './GuardianInfo';

interface Props {
    className?: string;
    cardArr?: Seiren.GuardianCard[];
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const playerStatus = useSelector((state: RootState) => state.player.status);
    const dispatch = useAppDispatch();

    const onRoll = () => {
        dispatch(rollCard());
    };

    const onUpgrade = (item: Seiren.GuardianCard) => {
        dispatch(
            upgradeGuardian({
                guardian: item,
            }),
        );
    };

    const onSacrifice = (item: Seiren.GuardianCard) => {
        dispatch(
            sacrificeGuardian({
                guardian: item,
            }),
        );
    };

    const onCardClick = (item: Seiren.GuardianCard) => {
        dispatch(
            showGuardianInfo({
                guardian: item,
            }),
        );
    };

    return (
        <div className={className}>
            <Typography variant="h6" align="center" color="white" gutterBottom>
                {t('pages:player.guardians.guardians.guardians')}
            </Typography>
            <Typography color="secondary.contrastText" variant="caption" component="p" textAlign="center">
                {t('pages:player.guardians.guardians.youCanDoDetailedInformationSalesAndUpgradesOfTheGu')}
            </Typography>
            <GuardianList
                onSacrifice={onSacrifice}
                onUpgrade={onUpgrade}
                onCardClick={onCardClick}
                extra={<UnselectedCard onClick={onRoll} unselectedTitle="Roll New Guardian" size="lg" />}
            />
            {playerStatus === 'upgrade' && <GuardianUpgrade />}
            {playerStatus === 'roll' && <GuardianRoll />}
            {playerStatus === 'sacrifice' && <GuardianSacrifice />}
            {playerStatus === 'info' && <GuardianInfo />}
        </div>
    );
};

export default styled(Component)``;
