import { useQuery } from 'react-query';
import { Seiren } from '../types/Seiren';
import axios from 'axios';
import _ from 'lodash';

interface Response extends Seiren.Player {}

async function fetch(token: string) {
    try {
        const { data } = await axios.get(`${axios.defaults.baseURL}/user/profile`, {
            headers: { Authentication: token },
        });

        if (!data?.error) {
            return _.mapKeys(data.data, (v: any, k: string) => _.camelCase(k)) as Seiren.Player;
        } else {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}

export default function (token: string, options?: Record<string, any>) {
    return useQuery<Response, Error>(['player', token], () => fetch(token), options);
}
