import React, { FC } from 'react';
import { Box, Button, Dialog, styled, Typography } from '@mui/material';
import { CardWrapper, GuardianCard } from '../../../components';
import { ReactComponent as TreasureChest } from '../../../assets/market/treasure-chest.svg';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { sellGuardian, setStatus } from '../../../redux/actions/player';
import { CardTooltip } from '../../../components/common/cards';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const card = useSelector((state: RootState) => state.player.selected.guardian);
    const status = useSelector((state: RootState) => state.player.status);

    return (
        <Dialog
            open={card ? status === 'sell' : false}
            className={className}
            fullWidth
            maxWidth="lg"
            PaperComponent={Box}
        >
            <div className="wrapper">
                <Typography variant="h3" color="primary.light" className="uppercase" align="center">
                    {t('pages:market.components.guardianSell.guardianSales')}
                </Typography>
                <Typography variant="h7" color="white" className="detail-subtitle uppercase" align="center">
                    {t('pages:market.components.guardianSell.setThePriceToSellOnTheMarket')}
                </Typography>
                {card && (
                    <CardWrapper className="guardian-card-wrapper" price={card.price} priceOnly={true}>
                        <CardTooltip {...card}>
                            <GuardianCard {...card} size="lg" checkbox={false} key={card.id} />
                        </CardTooltip>
                        <TreasureChest className="treasure-chest" />
                    </CardWrapper>
                )}
                <Button variant="confirm" fullWidth onClick={() => dispatch(sellGuardian({ guardian: card }))}>
                    <Typography variant="buttonLg" color="white">
                        {t('pages:market.components.guardianSell.ok')}
                    </Typography>
                </Button>
                <Button variant="confirm" fullWidth onClick={() => dispatch(setStatus({ status: 'default' }))}>
                    <Typography variant="buttonLg" color="white">
                        {t('pages:market.components.guardianSell.cancel')}
                    </Typography>
                </Button>
            </div>
        </Dialog>
    );
};

export default styled(Component)`
    .wrapper {
        display: grid;
        justify-items: center;
        align-items: center;
    }

    .guardian-card-wrapper {
        position: relative;
    }

    .close-btn {
        align-self: center;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .detail-subtitle {
        margin: ${({ theme }) => theme.spacing(4, 0, 8, 0)};
    }

    .treasure-chest {
        position: absolute;
        z-index: -1;
        left: -40%;
        bottom: 16%;
    }

    Button:not(:last-child) {
        margin: ${({ theme }) => theme.spacing(4, 0, 2, 0)};
    }
`;
