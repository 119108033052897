import React, { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props extends Seiren.Bonus {
    className?: string;
    children: ReactElement<any, any>;
}

const Component: FC<Props> = ({ className, children, ...props }) => {
    const { power, stars, durability, element, attributes } = props;
    const { t } = useTranslation();

    const tooltipContent = (
        <div>
            {element && (
                <div>
                    <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                        {t('components:common.cards.bonusTooltip.element')}
                    </Typography>
                    <Typography display="inline" variant="body2">
                        {element[0]?.toUpperCase() + element?.slice(1)}
                    </Typography>
                </div>
            )}
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {t('components:common.cards.bonusTooltip.stars')}
                </Typography>
                <Typography display="inline" variant="body2">
                    {stars}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {t('components:common.cards.bonusTooltip.durability')}
                </Typography>
                <Typography display="inline" variant="body2">
                    {durability}/200
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {t('components:common.cards.bonusTooltip.power')}
                </Typography>
                <Typography display="inline" variant="body2">
                    {power}
                </Typography>
            </div>
            {attributes.map((attr) => (
                <div key={attr.id}>
                    <Typography fontWeight="bold" variant="body2" textTransform="capitalize" display="inline" pr={1}>
                        {attr.element}
                    </Typography>
                    <Typography display="inline" variant="body2">
                        {attr.value}
                    </Typography>
                </div>
            ))}
        </div>
    );

    return (
        <Tooltip className={className} describeChild title={tooltipContent} followCursor arrow placement="right">
            <div>{children}</div>
        </Tooltip>
    );
};

export default styled(Component)``;
