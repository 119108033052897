import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/Store';

interface Props extends RouteProps {
    children: JSX.Element | JSX.Element[];
}

const PrivateRoute: FC<Props> = ({ children, ...rest }) => {
    const authKey = useSelector((state: RootState) => state.app.authKey);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                authKey ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
