import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Container className={className} maxWidth="sm">
            <Tabs variant="scrollable" scrollButtons value={location.pathname} textColor="secondary" className="tabs">
                <Tab
                    label={t('pages:player.components.navigation.guardians')}
                    value="/player/guardians"
                    className="tab"
                    component={Link}
                    to="/player/guardians"
                />
                <Tab
                    label={t('pages:player.components.navigation.bonuses')}
                    value="/player/bonuses"
                    className="tab"
                    component={Link}
                    to="/player/bonuses"
                />
                <Tab
                    label={t('pages:player.components.navigation.history')}
                    value="/player/history"
                    className="tab"
                    component={Link}
                    to="/player/history"
                />
                <Tab
                    label={t('pages:player.components.navigation.deposit')}
                    value="/player/deposit"
                    className="tab"
                    component={Link}
                    to="/player/deposit"
                />
                <Tab
                    label={t('pages:player.components.navigation.withdraw')}
                    value="/player/withdraw"
                    className="tab"
                    component={Link}
                    to="/player/withdraw"
                />
            </Tabs>
        </Container>
    );
};

export default styled(Component)``;
