import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { Divider } from '../../../components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <Divider />
            <Typography variant="h3" textAlign="center" color="primary" py={3} fontWeight={700}>
                {t('pages:ranking.layout.header.cardRanking')}
            </Typography>
        </div>
    );
};

export default styled(Component)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
