import { SnackbarKey } from 'notistack';
import { Seiren } from '../../types/Seiren';

export const ENQUEUE_SNACKBAR = 'notification/ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'notification/CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'notification/REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification: Seiren.Notification) => {
    const key = notification.options && notification.options.key;

    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = (key: SnackbarKey) => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = (key: SnackbarKey) => ({
    type: REMOVE_SNACKBAR,
    key,
});
