import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { ReactComponent as DesignArrow } from '../../../assets/player/design-arrow.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    open: boolean;
    setOpen: (arg: boolean) => void;
}

const Component: FC<Props> = ({ className, open, setOpen }) => {
    const { t } = useTranslation();

    const close = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} className={className} fullWidth maxWidth="md" PaperComponent={Box}>
            <div className="wrapper">
                <Typography color="primary.light" variant="h4" align="center">
                    {t('pages:player.deposit.getTokensDialog.getSergTokens')}
                </Typography>

                <Typography
                    color="white"
                    align="center"
                    maxWidth={{
                        xs: '100%',
                        md: '70%',
                    }}
                    pb={3}
                >
                    {t('pages:player.deposit.getTokensDialog.scanQrCodeToGetYourSergTokensForTheBetaTestingOfTh')}
                </Typography>
                <Button variant="outlined" onClick={close}>
                    {t('pages:player.deposit.getTokensDialog.close')}
                </Button>
            </div>
            <DesignArrow className="left-top-arrow" />
            <DesignArrow className="right-top-arrow" />
            <DesignArrow className="right-bottom-arrow" />
            <DesignArrow className="left-bottom-arrow" />
        </Dialog>
    );
};

export default styled(Component)`
    .wrapper {
        background: ${({ theme }) => theme.palette.background.default};
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: ${({ theme }) => theme.spacing(3)};
    }

    .stepper {
        display: flex;
        margin-bottom: ${({ theme }) => theme.spacing(3)};

        .dot {
            width: 12px;
            height: 12px;
            background: ${({ theme }) => theme.palette.common.white};
            border-radius: 30px;
            opacity: 0.4;
            margin: ${({ theme }) => theme.spacing(0, 0.5)};
        }

        .acitve {
            background: ${({ theme }) => theme.palette.primary.light};
            opacity: 1;
        }
    }

    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .roll {
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

    .balance {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .qr-code {
        border: 16px solid ${({ theme }) => theme.palette.common.white};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .left-top-arrow {
        position: absolute;
        left: ${({ theme }) => theme.spacing(2)};
        top: ${({ theme }) => theme.spacing(2)};
        transform: rotate(0.25turn);
    }

    .right-top-arrow {
        position: absolute;
        right: ${({ theme }) => theme.spacing(2)};
        top: ${({ theme }) => theme.spacing(2)};
        transform: rotate(0.5turn);
    }

    .right-bottom-arrow {
        position: absolute;
        bottom: ${({ theme }) => theme.spacing(2)};
        right: ${({ theme }) => theme.spacing(2)};
        transform: rotate(0.75turn);
    }

    .left-bottom-arrow {
        position: absolute;
        bottom: ${({ theme }) => theme.spacing(2)};
        left: ${({ theme }) => theme.spacing(2)};
    }
`;
