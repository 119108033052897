import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Button, Grid, Typography } from '@mui/material';

import coins from '../../../assets/card/wrapper/coins.png';
import NumberFormat from 'react-number-format';
import { animated, useSpring } from 'react-spring';

interface Props {
    className?: string;
    price?: number;
    children?: JSX.Element | JSX.Element[];
    priceOnly?: boolean;
    onUpgrade?: () => void;
    onSacrifice?: () => void;
}

const calc = (x: number, y: number, rect: { left: number; top: number }) => [
    -(y - rect.top - 180) / 15,
    (x - rect.left - 125) / 15,
    1.02,
];
const trans = (x: number, y: number, s: number) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const Component: FC<Props> = ({ className, children, price, onUpgrade, onSacrifice, priceOnly }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: {
            mass: 5,
            tension: 350,
            friction: 40,
        },
    }));

    return (
        <div className={className} ref={ref}>
            <animated.div
                onMouseMove={({ clientX: x, clientY: y }) =>
                    set({ xys: calc(x, y, ref.current!.getBoundingClientRect()) })
                }
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }}
            >
                <div className="content">{children}</div>
            </animated.div>
            {price && (
                <div className="price-box">
                    <img src={coins} alt="price" />
                    <Typography variant="h7" className="price-txt" color="white">
                        <NumberFormat value={price} displayType="text" thousandSeparator /> SERG
                    </Typography>
                </div>
            )}
            <Grid container justifyContent="center">
                <Grid item md="auto" xs="auto">
                    {!priceOnly && onUpgrade && (
                        <Button variant="contained" size="small" onClick={onUpgrade} className="wrapper-btn">
                            Upgrade
                        </Button>
                    )}
                </Grid>
                <Grid item md="auto" xs="auto">
                    {!priceOnly && onSacrifice && (
                        <Button variant="outlined2" size="small" onClick={onSacrifice} className="wrapper-btn">
                            Sacrifice
                        </Button>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default styled(Component)`
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    .price-txt {
        margin-left: ${({ theme }) => theme.spacing(1)};
    }

    .price-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: ${({ theme }) => theme.spacing(1)};
    }

    .wrapper-btn {
        margin-bottom: ${({ theme }) => theme.spacing(0.5)};
        max-width: 250px;
    }
`;
