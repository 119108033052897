import { useQuery } from 'react-query';
import { Seiren } from '../types/Seiren';
import axios from 'axios';
import _ from 'lodash';

type Params = {
    guardian: Seiren.GuardianCard;
};

interface Response extends Seiren.GuardianCard {}

async function fetch(params: Params) {
    try {
        const { data } = await axios.get(`${axios.defaults.baseURL}/info/guardian/${params.guardian.id}`);

        if (!data?.error) {
            return _.mapKeys(data.data, (v: any, k: string) => _.camelCase(k)) as Seiren.GuardianCard;
        } else {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}

export default function (params: Params, options?: Record<string, any>) {
    return useQuery<Response, Error>(['guardian', params], () => fetch(params), options);
}
