import React, { FC } from 'react';
import { Button, Container, Typography } from '@mui/material';
import styled from 'styled-components';
import successImg from '../../../assets/swap/success.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    return (
        <Container maxWidth="xs" className={className}>
            <div className="swap-wrapper-div">
                <Typography variant="h4" textAlign="center" color="textSecondary">
                    {t('pages:swap.layout.swapSuccess.complete')}
                </Typography>
                <Typography align="center" color="textSecondary">
                    {t('pages:swap.layout.swapSuccess.theSwapHasBeenSuccessfullyCompletedCheckTheBalance')}
                </Typography>
                <img src={successImg} alt={t('pages:swap.layout.swapSuccess.success')} className="success-img" />
                <Button variant="confirm" type="submit" component={Link} to={'/player'}>
                    <Typography color="white">{t('pages:swap.layout.swapSuccess.player')}</Typography>
                </Button>
            </div>
        </Container>
    );
};

export default styled(Component)`
    .swap-wrapper-div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .success-img {
        margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
`;
