import React, { FC, useEffect, useState } from 'react';
import { Button, Container, FormControl, FormHelperText, Typography } from '@mui/material';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { PrimaryInput } from '../../../components/common';
import { RootState } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withdraw } from '../../../api';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

interface Props {
    className?: string;
    fee?: number;
    balance?: number;
    summary?: number;
}

interface IFormInput {
    address?: string;
    amount?: number;
    lockup?: string;
    withdrawCurrency?: number;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>();
    const history = useHistory();
    const wallet = useSelector((state: RootState) => state.player.player.wallet);
    const balance = wallet.balance;
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const [formData, setFormData] = useState<IFormInput>({});
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: makeWithdraw, status, error } = useMutation(withdraw);

    useEffect(() => {
        if ('address' in formData) {
            makeWithdraw({
                token: authKey,
                ...formData,
            });
        }
    }, [formData]);

    useEffect(() => {
        if (status === 'success') {
            enqueueSnackbar(t('pages:player.withdraw.withdraw.notificationSuccess'), {
                variant: 'success',
            });
            history.push('/player');
        }
        if (status === 'error') {
            // @ts-ignore
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    }, [status]);

    const onSubmit = (data: IFormInput) => {
        setFormData(data);
    };

    return (
        <Container maxWidth="md">
            <form onSubmit={handleSubmit(onSubmit)} className={className}>
                <Typography variant="h6" align="center" color="white" className="withdraw-header">
                    {t('pages:player.withdraw.withdraw.withdraw')}
                </Typography>
                <FormControl fullWidth variant="outlined">
                    <Controller
                        render={({ field }) => (
                            <FormControl error={errors?.address?.type === 'required'} {...field} className="txt-field">
                                <PrimaryInput placeholder={t('pages:player.withdraw.withdraw.enterTheAddress')} />
                                <FormHelperText>
                                    {errors?.address?.type === 'required' && errors?.address?.message}
                                </FormHelperText>
                            </FormControl>
                        )}
                        name="address"
                        control={control}
                        rules={{
                            required: 'Address required',
                        }}
                        defaultValue=""
                    />
                    <Typography align="right" color="white" variant="body2" className="balance-txt">
                        <NumberFormat
                            prefix="BALANCE "
                            suffix={' SERG'}
                            value={balance}
                            displayType="text"
                            decimalScale={8}
                            fixedDecimalScale
                            thousandSeparator
                        />
                    </Typography>
                    <div className="amount-field-box">
                        <Controller
                            render={({ field }) => (
                                <FormControl error={errors.amount !== null} {...field} className="txt-field" fullWidth>
                                    <NumberFormat
                                        customInput={PrimaryInput}
                                        className="amount-field"
                                        placeholder={t('pages:player.withdraw.withdraw.enterTheAmount')}
                                        allowNegative={false}
                                    />
                                    <FormHelperText>{errors.amount && errors.amount.message}</FormHelperText>
                                </FormControl>
                            )}
                            name="amount"
                            control={control}
                            rules={{
                                max: {
                                    value: balance,
                                    message: "Summary's bigger than your balance",
                                },
                                required: 'Amount required',
                            }}
                        />
                    </div>
                    <Typography align="right" color="white" fontSize={32}>
                        <NumberFormat
                            value={watch('amount')}
                            suffix={' SERG'}
                            decimalScale={8}
                            fixedDecimalScale
                            defaultValue={0}
                            displayType="text"
                            thousandSeparator
                        />
                    </Typography>
                    <div className="upgrade-btn">
                        <Button variant="contained" type="submit">
                            {t('pages:player.withdraw.withdraw.send')}
                        </Button>
                    </div>
                </FormControl>
            </form>
        </Container>
    );
};

Component.defaultProps = {
    fee: 0,
    balance: 0,
    summary: 0,
};

export default styled(Component)`
    .txt-field {
        margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    .upgrade-btn {
        display: flex;
        justify-content: center;
    }

    .balance-txt {
        margin: 2px;
    }

    .withdraw-header {
        margin-bottom: ${({ theme }) => theme.spacing(5)};
    }

    .amount-field-box {
        display: flex;
        flex-direction: row;
    }

    .amount-field {
        border-radius: 5px 0 0 5px;
    }
`;
