import React, { FC, ReactElement, useState } from 'react';
import { Grid, Pagination, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { useGuardians } from '../../api';
import { Seiren } from '../../types/Seiren';
import { CardWrapper, GuardianCard } from '../common';
import { CardSkeleton } from './index';

interface Props {
    className?: string;
    extra?: ReactElement | ReactElement[];
    onUpgrade?: (card: Seiren.GuardianCard) => void;
    onSacrifice?: (card: Seiren.GuardianCard) => void;
    onCardClick?: (card: Seiren.GuardianCard) => void;
}

const Component: FC<Props> = ({ className, extra, onCardClick, onUpgrade, onSacrifice }) => {
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const [page, setPage] = useState(1);
    const { isLoading, data: cards } = useGuardians(authKey, { page });

    const onPageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const renderCard = (item: Seiren.GuardianCard) => (
        <Grid item lg={3} md={4} sm={6} xs={12} key={item.id}>
            <CardWrapper
                onUpgrade={onUpgrade && item.level === item.maxLevel ? () => onUpgrade(item) : undefined}
                onSacrifice={onSacrifice && item.level === item.maxLevel ? () => onSacrifice(item) : undefined}
            >
                <GuardianCard {...item} onClick={onCardClick ? () => onCardClick(item) : undefined} size="lg" />
            </CardWrapper>
        </Grid>
    );

    const renderCardSkeletons = () => {
        return new Array(4).fill(null).map((tx, index) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                <CardSkeleton withButtons={Boolean(onSacrifice || onUpgrade)} withTitle size="lg" />
            </Grid>
        ));
    };

    return (
        <div className={className}>
            <Grid
                marginTop={4}
                container
                alignItems="flex-start"
                justifyContent={{
                    xs: 'center',
                    md: 'flex-start',
                }}
                spacing={4}
                pt={2}
            >
                {!isLoading && extra && (
                    <Grid item lg={3} md={4} sm={6} xs="auto">
                        {extra}
                    </Grid>
                )}
                {!isLoading && cards!.list.map(renderCard)}
                {isLoading && renderCardSkeletons()}
            </Grid>
            {cards && cards.list.length > 0 && (
                <Pagination
                    className="pagination"
                    count={cards.pagination.pages}
                    page={page}
                    onChange={onPageChange}
                    size="large"
                    showFirstButton
                    showLastButton
                    disabled={isLoading}
                />
            )}
        </div>
    );
};

export default styled(Component)``;
