import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

interface Props {
    className?: string;
    size?: 'md' | 'lg';
    type?: 'content' | 'btn';
    children?: JSX.Element | JSX.Element[];
    onClick?: any;
}

const Component: FC<Props> = ({ className, size, type, children, onClick }) => {
    const dots = (
        <div className="wrapper-bar-dots">
            <div className="wrapper-bar-dots-item" />
            <div className="wrapper-bar-dots-item" />
        </div>
    );
    const content = (
        <>
            {dots}
            <div className="content-wrapper">{children}</div>
            {dots}
        </>
    );

    return (
        <div className={className}>
            {type === 'content' ? (
                <div className={size === 'md' ? 'md-content-bar' : 'lg-bar'}>{content}</div>
            ) : (
                <Button className={size === 'md' ? 'md-btn-bar' : 'lg-bar'} onClick={onClick}>
                    {content}
                </Button>
            )}
        </div>
    );
};

Component.defaultProps = {
    size: 'md',
};

export default styled(Component)`
    .md-content-bar {
        max-width: 369px;
        display: flex;
        justify-content: space-between;
        background: linear-gradient(
                    to bottom,
                    rgba(116, 81, 34),
                    rgba(146, 102, 51),
                    rgba(132, 86, 36),
                    rgba(100, 67, 23)
                )
                padding-box,
            linear-gradient(rgba(193, 149, 89), rgba(255, 226, 159), rgba(184, 126, 62), rgba(194, 150, 93)) border-box;
        padding: ${({ theme }) => theme.spacing(0.5, 1.5)};
        border-width: 6px;
        border-style: solid;
        border-color: transparent;
        border-radius: 2rem;
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        margin: ${({ theme }) => theme.spacing(0, 1)};
    }

    .lg-bar {
        display: flex;
        max-width: 474px;
        background: linear-gradient(
                    to bottom,
                    rgba(116, 81, 34),
                    rgba(146, 102, 51),
                    rgba(132, 86, 36),
                    rgba(100, 67, 23)
                )
                padding-box,
            linear-gradient(rgba(193, 149, 89), rgba(255, 226, 159), rgba(184, 126, 62), rgba(194, 150, 93)) border-box;
        padding: ${({ theme }) => theme.spacing(0.5, 1.5)};
        border-width: 6px;
        border-style: solid;
        border-color: transparent;
        border-radius: 2rem;
    }

    .wrapper-bar-dots {
        display: flex;
        flex-direction: column;
        height: 24px;
        justify-content: space-between;
    }

    .wrapper-bar-dots-item {
        height: 2px;
        width: 2px;
        background: linear-gradient(
            to bottom,
            rgba(239, 206, 141),
            rgba(172, 137, 80),
            rgba(120, 84, 33),
            rgba(192, 145, 86)
        );
        border-radius: 50%;
        box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
    }

    .md-btn-bar {
        background: linear-gradient(
                    to bottom,
                    rgba(116, 81, 34),
                    rgba(146, 102, 51),
                    rgba(132, 86, 36),
                    rgba(100, 67, 23)
                )
                padding-box,
            linear-gradient(rgba(193, 149, 89), rgba(255, 226, 159), rgba(184, 126, 62), rgba(194, 150, 93)) border-box;
        padding: ${({ theme }) => theme.spacing(0.5, 1.5)};
        border-width: 6px;
        border-style: solid;
        border-color: transparent;
        border-radius: 2rem;
    }
`;
