import { Dispatch } from 'redux';
import { Seiren } from '../../types/Seiren';

const cardArr: Seiren.GuardianCard[] = [
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/UnbecomingEndurableWildcat?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'water',
        id: 'c6734e3b-8d5b-4fe0-815b-a3995e1d2473',
        level: 15,
        name: 'UnbecomingEndurableWildcat',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: '030d98b36ca5babbdf1240d9e19fd5795562c7351e7caadef08a3eab83db24de',
        stamina: 200,
        price: 2323152,
        power: 522113,
        xp: 0,
    },
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/ElfinEqualSalmon?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'fire',
        id: 'fbc450e1-3b28-4be0-94fc-4b47a5a011cf',
        level: 2,
        name: 'ElfinEqualSalmon',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: 'c724728b58f916ade459b25bcbabac77f9869b6d916d77681dbe45f72c25c7b7',
        stamina: 200,
        price: 56121,
        power: 1316,
        xp: 0,
    },
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/PurpleWeakMagpie?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'fire',
        id: 'b0503eb0-556c-4ac3-8d1f-27a8372b0214',
        level: 1,
        name: 'PurpleWeakMagpie',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: '0429ab9340cc89f778b1f96f315df027094b426612148735ee44aa00a08ba9bf',
        stamina: 200,
        price: 63135,
        power: 2131,
        xp: 0,
    },
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/KnottyPiquantNarwhal?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'lightning',
        id: '2cf313b7-b5b8-4e42-be33-b8a904868c3e',
        level: 0,
        name: 'KnottyPiquantNarwhal',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: '4b92d874b3366dfe2f08cf5c4c73c9a354afb430e043bf3914f35188e119a206',
        stamina: 200,
        price: 10542,
        power: 1041,
        xp: 0,
    },
];

const topArr: Seiren.GuardianCard[] = [
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/UnbecomingEndurableWildcat?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'water',
        id: 'c6734e3b-8d5b-4fe0-815b-a3995e1d2473',
        level: 15,
        name: 'UnbecomingEndurableWildcat',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: '030d98b36ca5babbdf1240d9e19fd5795562c7351e7caadef08a3eab83db24de',
        stamina: 200,
        price: 2323152,
        power: 522113,
        xp: 0,
    },
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/ElfinEqualSalmon?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'fire',
        id: 'fbc450e1-3b28-4be0-94fc-4b47a5a011cf',
        level: 2,
        name: 'ElfinEqualSalmon',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: 'c724728b58f916ade459b25bcbabac77f9869b6d916d77681dbe45f72c25c7b7',
        stamina: 200,
        price: 56121,
        power: 1316,
        xp: 0,
    },
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/PurpleWeakMagpie?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'fire',
        id: 'b0503eb0-556c-4ac3-8d1f-27a8372b0214',
        level: 1,
        name: 'PurpleWeakMagpie',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: '0429ab9340cc89f778b1f96f315df027094b426612148735ee44aa00a08ba9bf',
        stamina: 200,
        price: 63135,
        power: 2131,
        xp: 0,
    },
    {
        avatar: 'https://source.boringavatars.com/bauhaus/120/KnottyPiquantNarwhal?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
        burned: false,
        element: 'lightning',
        id: '2cf313b7-b5b8-4e42-be33-b8a904868c3e',
        level: 0,
        name: 'KnottyPiquantNarwhal',
        nextXp: 16,
        owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
        seed: '4b92d874b3366dfe2f08cf5c4c73c9a354afb430e043bf3914f35188e119a206',
        stamina: 200,
        price: 10542,
        power: 1041,
        xp: 0,
    },
];

export const SET_IN_STATE = 'market/SET_IN_STATE';
export const SET_FILTERS = 'market/SET_FILTERS';
export const SET_SELECTED_GUARDIAN = 'market/SET_SELECTED_GUARDIAN';
export const BUY_GUARDIAN = 'market/BUY_GUARDIAN';

export const setGuardians = (payload: { guardians?: Seiren.GuardianCard[] }) => ({
    type: SET_IN_STATE,
    payload,
});

export const setPages = (payload: { pages: number }) => ({
    type: SET_IN_STATE,
    payload,
});

export const setFilters = (payload: {
    filters: {
        element?: Seiren.CardElement;
        level?: number;
        price?: {
            min?: number;
            max?: number;
        };
        search?: string;
    };
}) => ({
    type: SET_FILTERS,
    payload,
});

export const setCurrentPage = (payload: { currentPage: number }) => ({
    type: SET_IN_STATE,
    payload,
});

export const setTopGuardians = (payload: { topGuardians?: Seiren.GuardianCard[] }) => ({
    type: SET_IN_STATE,
    payload,
});

export const setSelectedGuardian = (payload: { guardian: Seiren.GuardianCard }) => ({
    type: SET_SELECTED_GUARDIAN,
    payload,
});

export const setStatus = (payload: { status: 'default' | 'buy' }) => ({
    type: SET_IN_STATE,
    payload,
});

export const loadTopGuardians = () => {
    return (dispatch: Dispatch) => {
        setTimeout(() => {
            dispatch(setTopGuardians({ topGuardians: topArr }));
        }, 20);
    };
};

export const loadGuardians = (payload: { page: number }) => {
    return (dispatch: Dispatch) => {
        console.log(payload);
        dispatch(setPages({ pages: 5 }));
        dispatch(setGuardians({ guardians: cardArr }));
    };
};

export const filterBy = (payload: {
    filters: {
        element?: Seiren.CardElement;
        level?: number;
        price?: {
            min?: number;
            max?: number;
        };
        search?: string;
    };
}) => {
    return (dispatch: Dispatch) => {
        dispatch(setFilters(payload));
    };
};

export const openBuyDialog = (payload: { guardian: Seiren.GuardianCard }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedGuardian(payload));
        dispatch(setStatus({ status: 'buy' }));
    };
};

export const buyGuardian = (payload: { guardian: Seiren.GuardianCard }) => ({
    type: BUY_GUARDIAN,
    payload,
});
