import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonBase, Grid, Typography } from '@mui/material';
import { BonusCard, BonusTooltip } from '../../../components/common/cards';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import { resetSacrifice } from '../../../redux/actions/player';
import QRCode from 'qrcode.react';
import { loadDestroyAddress } from '../../../redux/actions/app';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from '../../../redux/actions/notification';
import { useBonus, useBonuses } from '../../../api';
import { Dialog } from '../../../components';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const destroyAddress = useSelector((state: RootState) => state.app.destroyAddress);
    const status = useSelector((state: RootState) => state.player.status);
    const bonus = useSelector((state: RootState) => state.player.selected.bonus);
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const { refetch: refetchBonuses } = useBonuses(authKey, { page: 1 });
    const {
        data: updated,
        isRefetching,
        status: statusBonus,
        error,
    } = useBonus({ guardian: bonus }, { refetchInterval: 10000 });

    const close = () => {
        dispatch(resetSacrifice());
        refetchBonuses();
    };

    useEffect(() => {
        dispatch(loadDestroyAddress());
    }, []);

    useEffect(() => {
        if (updated?.destroyed) {
            dispatch(
                enqueueSnackbar({
                    message: t('pages:player.bonuses.bonusSacrifice.theBonusCardIsSacrificedSuccessfully'),
                    options: {
                        variant: 'success',
                    },
                }),
            );

            close();
        }
    }, [isRefetching]);

    useEffect(() => {
        if (statusBonus === 'error') {
            dispatch(
                enqueueSnackbar({
                    message: error?.message,
                    options: {
                        variant: 'error',
                    },
                }),
            );
        }
    }, [statusBonus]);

    return (
        <Dialog
            title={t('pages:player.bonuses.bonusSacrifice.sacrificeTheMaterialCard')}
            description={t('pages:player.bonuses.bonusSacrifice.theMaterialCardWillDisappearAfterTheGuardiansUpgra')}
            open={status === 'sacrifice'}
            className={className}
            onClose={close}
            fullWidth
            maxWidth="md"
        >
            <Grid container spacing={6} paddingY={6} alignItems="center" justifyContent="center">
                <Grid item md="auto">
                    <BonusTooltip {...bonus}>
                        <BonusCard {...bonus} size="lg" />
                    </BonusTooltip>
                </Grid>
                <Grid item md={4}>
                    <div className="qr-code-box">
                        {destroyAddress && bonus && (
                            <ButtonBase
                                href={`aok://deposit?address=${destroyAddress}&amount=1&token=${bonus.ticker}`}
                                target="_blank"
                            >
                                <QRCode
                                    size={200}
                                    value={`aok://deposit?address=${destroyAddress}&amount=1&token=${bonus.ticker}`}
                                    bgColor="white"
                                    className="qr-code"
                                />
                            </ButtonBase>
                        )}
                        <Typography color="white" align="center">
                            {t('pages:player.bonuses.bonusSacrifice.scanQrCodeWithAokWalletToSacrificeMaterialCard')}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default styled(Component)`
    .qr-code-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .qr-code {
            border: 16px solid ${({ theme }) => theme.palette.common.white};
            margin-bottom: ${({ theme }) => theme.spacing(5)};
        }
    }
`;
