import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { BonusList } from '../../../components';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import { rollCard, sacrificeBonus, showBonusInfo, upgradeBonus } from '../../../redux/actions/player';
import { UnselectedCard } from '../../../components/common/cards';
import BonusUpgrade from './BonusUpgrade';
import BonusRoll from './BonusRoll';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';
import BonusBurn from './BonusBurn';
import BonusInfo from './BonusInfo';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const playerStatus = useSelector((state: RootState) => state.player.status);
    const dispatch = useAppDispatch();

    const onUpgrade = (item: Seiren.Bonus) => {
        dispatch(
            upgradeBonus({
                bonus: item,
            }),
        );
    };

    const onRoll = () => {
        dispatch(rollCard());
    };

    const onBurn = (item: Seiren.Bonus) => {
        dispatch(
            sacrificeBonus({
                bonus: item,
            }),
        );
    };

    const onCardClick = (item: Seiren.Bonus) => {
        dispatch(
            showBonusInfo({
                bonus: item,
            }),
        );
    };

    return (
        <div className={className}>
            <Typography color="white" variant="h6" align="center" component="div">
                {t('pages:player.bonuses.bonuses.bonuses')}
            </Typography>
            <BonusList
                onSacrifice={onBurn}
                onUpgrade={onUpgrade}
                onCardClick={onCardClick}
                extra={<UnselectedCard onClick={onRoll} unselectedTitle="Roll New Bonus" size="lg" />}
            />
            {playerStatus === 'upgrade' && <BonusUpgrade />}
            {playerStatus === 'roll' && <BonusRoll />}
            {playerStatus === 'sacrifice' && <BonusBurn />}
            {playerStatus === 'info' && <BonusInfo />}
        </div>
    );
};

export default styled(Component)``;
