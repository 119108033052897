import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';
import { useAppDispatch } from '../../redux/Store';
import { setCards } from '../../redux/actions/battle';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../types/Seiren';
import { BonusList, Dialog } from '../../components';

interface Props {
    className?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const Component: FC<Props> = ({ className, open, setOpen }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const confirm = (card?: Seiren.Bonus) => {
        dispatch(
            setCards({
                bonus: card,
            }),
        );

        setOpen(false);
    };

    const close = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            title={t('pages:battle.cardSelect.selectYourBonus')}
            description={t('pages:battle.cardSelect.pleaseChooseOneBonus')}
            className={className}
            onClose={close}
            fullWidth
            maxWidth="lg"
        >
            <BonusList onCardClick={confirm} />
        </Dialog>
    );
};

export default styled(Component)``;
