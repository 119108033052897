import { Dispatch } from 'redux';
import { Seiren } from '../../types/Seiren';
import axios from 'axios';

export const SET_IN_STATE = 'SET_IN_STATE';
export const SET_SELECTED_BONUS = 'player/SET_SELECTED_BONUS';
export const SET_SELECTED_GUARDIAN = 'player/SET_SELECTED_GUARDIAN';
export const SET_SELECTED_MATERIAL = 'player/SET_SELECTED_MATERIAL';
export const RESET_UPGRADE = 'player/RESET_UPGRADE';
export const RESET_ROLL = 'player/RESET_ROLL';
export const RESET_SACRIFICE = 'player/RESET_SACRIFICE';
export const SELL_GUARDIAN = 'player/SET_SELL_GUARDIAN';
export const RESET_CLAIM = 'player/RESET_CLAIM';
export const RESET_SHOW_INFO = 'player/RESET_SHOW_INFO';

export const setPlayer = (payload: { player: Seiren.Player }) => ({
    type: SET_IN_STATE,
    payload,
});

export const setSelectedGuardian = (payload: { guardian: Seiren.GuardianCard }) => ({
    type: SET_SELECTED_GUARDIAN,
    payload,
});

export const setSelectedMaterial = (payload: { material: Seiren.GuardianCard }) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: SET_SELECTED_MATERIAL,
            payload,
        });
    };
};

export const setStatus = (payload: {
    status: 'default' | 'sell' | 'upgrade' | 'roll' | 'sacrifice' | 'claim' | 'info';
}) => ({
    type: SET_IN_STATE,
    payload,
});

export const resetUpgrade = () => ({
    type: RESET_UPGRADE,
});

export const resetRoll = () => ({
    type: RESET_ROLL,
});

export const resetClaim = () => ({
    type: RESET_CLAIM,
});

export const resetSacrifice = () => ({
    type: RESET_SACRIFICE,
});
export const resetShowInfo = () => ({
    type: RESET_SHOW_INFO,
});

export const sellGuardian = (payload: { guardian: Seiren.GuardianCard }) => ({
    type: SELL_GUARDIAN,
    payload,
});

export const setSelectedBonus = (payload: { bonus: Seiren.Bonus }) => ({
    type: SET_SELECTED_BONUS,
    payload,
});

export const setWithdrawStatus = (payload: { withdrawStatus?: 'initial' | 'failed' | 'success' }) => ({
    type: SET_IN_STATE,
    payload,
});

export const withdraw = (
    payload: {
        formData: {
            address: string;
            amount: number;
            lockup: string;
            withdrawCurrency: number;
        };
    },
    token: string,
) => {
    return (dispatch: Dispatch) => {
        dispatch(setWithdrawStatus({ withdrawStatus: 'initial' }));

        axios
            .post(
                `${axios.defaults.baseURL}/user/withdraw`,
                {
                    address: payload.formData.address,
                    amount: payload.formData.amount,
                },
                { headers: { Authentication: token } },
            )
            .then((response) => {
                if (!response.data?.error) {
                    dispatch(setWithdrawStatus({ withdrawStatus: 'success' }));
                } else {
                    dispatch(setWithdrawStatus({ withdrawStatus: 'failed' }));
                }
            });
    };
};

export const openSellDialog = (payload: { guardian: Seiren.GuardianCard }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedGuardian(payload));
        dispatch(setStatus({ status: 'sell' }));
    };
};

export const upgradeGuardian = (payload: { guardian: Seiren.GuardianCard }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedGuardian(payload));
        dispatch(setStatus({ status: 'upgrade' }));
    };
};

export const sacrificeGuardian = (payload: { guardian: Seiren.GuardianCard }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedGuardian(payload));
        dispatch(setStatus({ status: 'sacrifice' }));
    };
};

export const showGuardianInfo = (payload: { guardian: Seiren.GuardianCard }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedGuardian(payload));
        dispatch(setStatus({ status: 'info' }));
    };
};

export const showBonusInfo = (payload: { bonus: Seiren.Bonus }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedBonus(payload));
        dispatch(setStatus({ status: 'info' }));
    };
};

export const sacrificeBonus = (payload: { bonus: Seiren.Bonus }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedBonus(payload));
        dispatch(setStatus({ status: 'sacrifice' }));
    };
};

export const rollCard = () => {
    return (dispatch: Dispatch) => {
        dispatch(setStatus({ status: 'roll' }));
    };
};

export const claimCard = () => {
    return (dispatch: Dispatch) => {
        dispatch(setStatus({ status: 'claim' }));
    };
};

export const upgradeBonus = (payload: { bonus: Seiren.Bonus }) => {
    return (dispatch: Dispatch) => {
        dispatch(setSelectedBonus(payload));
        dispatch(setStatus({ status: 'upgrade' }));
    };
};
