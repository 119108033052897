import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import GlobalStyle from './GlobalStyle';
import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import Navigation from './Navigation';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Battle, Home, Login, Market, Player, Ranking } from '../pages';
import { Bubbles } from '../components';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../redux/Store';
import useNotifier from '../hooks/useNotifier';
import { usePlayer } from '../api';
import { setPlayer } from '../redux/actions/player';
import { logoutUser } from '../redux/actions/app';
import { useSnackbar } from 'notistack';

const App = () => {
    useNotifier();
    const dispatch = useAppDispatch();
    const [refetchInterval, setRefetchInterval] = React.useState(10000);
    const [enabledPlayerUpdate, setEnabledPlayerUpdate] = useState(false);
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const { enqueueSnackbar } = useSnackbar();
    const {
        error,
        status,
        data: player,
    } = usePlayer(authKey, {
        refetchInterval,
        retry: false,
        enabled: enabledPlayerUpdate,
        refetchOnWindowFocus: false,
        onError: () => {
            setRefetchInterval(0);
            dispatch(logoutUser());
        },
    });

    useEffect(() => {
        if (player) {
            dispatch(setPlayer({ player }));
        }
    }, [player]);

    useEffect(() => {
        if (authKey) {
            setEnabledPlayerUpdate(true);
        } else {
            setEnabledPlayerUpdate(false);
        }

        setRefetchInterval(10000);
    }, [authKey]);

    useEffect(() => {
        if (status === 'error') {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    }, [status]);

    return (
        <Router>
            <ScrollToTop />
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyle />
                    <Navigation />
                    <Bubbles />
                    <Switch>
                        <PrivateRoute path="/player">
                            <Player />
                        </PrivateRoute>
                        <Route path="/" exact component={Home} />
                        <PrivateRoute path="/ranking" exact>
                            <Ranking />
                        </PrivateRoute>
                        <PrivateRoute path="/market" exact>
                            <Market />
                        </PrivateRoute>
                        {/*<PrivateRoute path="/swap" exact>*/}
                        {/*    <Swap />*/}
                        {/*</PrivateRoute>*/}
                        <PrivateRoute path="/battle" exact>
                            <Battle />
                        </PrivateRoute>
                        {!authKey && <Route path="/login" component={Login} />}
                        <Redirect to={authKey ? '/player' : '/'} />
                    </Switch>
                    <Footer />
                </ThemeProvider>
            </MuiThemeProvider>
        </Router>
    );
};

export default App;
