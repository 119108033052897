import React, { FC, useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import battleBack from '../../assets/backgrounds/background-3/background_03.jpg';
import { BonusCard, Divider, GuardianCard } from '../../components';
import Elements from './components/Elements';
import GuardianSelect from './GuardianSelect';
import BattleResult from './BattleResult';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/Store';
import EnemySelect from './EnemySelect';
import { resetBattle, resetEnemy, setResult, setStatus } from '../../redux/actions/battle';
import { VS } from './components';
import { UnselectedCard } from '../../components/common';
import { BonusTooltip, CardTooltip } from '../../components/common/cards';
import { enqueueSnackbar } from '../../redux/actions/notification';
import { useTranslation } from 'react-i18next';
import { fight, useBonuses, useGuardians } from '../../api';
import '../ranking/components/Guardian';
import BonusSelect from './BonusSelect';
import { useMutation } from 'react-query';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedGuardian = useSelector((state: RootState) => state.battle.selected.guardian);
    const selectedBonus = useSelector((state: RootState) => state.battle.selected.bonus);
    const selectedEnemy = useSelector((state: RootState) => state.battle.selected.enemy);
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const status = useSelector((state: RootState) => state.battle.status);

    const {
        status: statusGuardians,
        error: errorGuardians,
        isLoading: isGuardiansLoading,
        data: guardians,
    } = useGuardians(authKey, { page: 1 });

    const {
        status: statusBonuses,
        error: errorBonuses,
        isLoading: isBonusesLoading,
        data: bonuses,
    } = useBonuses(authKey, { page: 1 });

    const [cardSelectDialog, setCardSelectDialog] = useState<boolean>(false);
    const [bonusSelectDialog, setBonusSelectDialog] = useState<boolean>(false);
    const [enemySelectDialog, setEnemySelectDialog] = useState<boolean>(false);

    const { data: result, mutate: startFight, error: errorFight, status: statusFight } = useMutation(fight);

    const startFighting = () => {
        if (selectedEnemy && selectedBonus && selectedGuardian) {
            dispatch(setStatus({ status: 'fighting' }));
            startFight({
                token: authKey,
                guardian: selectedGuardian,
                bonus: selectedBonus,
                enemy: selectedEnemy,
            });
        }
    };

    const selectCard = () => {
        if (isGuardiansLoading) {
            dispatch(
                enqueueSnackbar({
                    message: t('pages:battle.battle.loadingGuardianBonusWait'),
                    options: {
                        variant: 'warning',
                    },
                }),
            );
        } else if (!guardians) {
            dispatch(
                enqueueSnackbar({
                    message: t('pages:battle.battle.loadingGuardianBonusWait'),
                    options: {
                        variant: 'error',
                    },
                }),
            );
        } else if (guardians.list.length === 0) {
            dispatch(
                enqueueSnackbar({
                    message: t('pages:battle.battle.doesntExistGuardianBonus'),
                    options: {
                        variant: 'error',
                    },
                }),
            );
        } else {
            setCardSelectDialog(true);
        }
    };

    const selectBonus = () => {
        if (isBonusesLoading) {
            dispatch(
                enqueueSnackbar({
                    message: t('pages:battle.battle.loadingGuardianBonusWait'),
                    options: {
                        variant: 'warning',
                    },
                }),
            );
        } else if (!bonuses) {
            dispatch(
                enqueueSnackbar({
                    message: t('pages:battle.battle.loadingGuardianBonusWait'),
                    options: {
                        variant: 'error',
                    },
                }),
            );
        } else if (bonuses.list.length === 0) {
            dispatch(
                enqueueSnackbar({
                    message: t('pages:battle.battle.doesntExistGuardianBonus'),
                    options: {
                        variant: 'error',
                    },
                }),
            );
        } else {
            setBonusSelectDialog(true);
        }
    };

    useEffect(() => {
        if (selectedGuardian) {
            dispatch(resetEnemy());
        }
    }, [selectedGuardian]);

    useEffect(() => {
        if (statusFight === 'success') {
            dispatch(
                setResult({
                    success: result.victory!,
                    reward: result.reward!,
                }),
            );
            dispatch(setStatus({ status: 'finished' }));
        }
        if (statusFight === 'error') {
            dispatch(resetBattle());

            dispatch(
                enqueueSnackbar({
                    // @ts-ignore
                    message: errorFight.message,
                    options: {
                        variant: 'error',
                    },
                }),
            );
        }
    }, [statusFight]);

    useEffect(() => {
        if (statusBonuses === 'error') {
            dispatch(
                enqueueSnackbar({
                    message: errorBonuses?.message,
                    options: {
                        variant: 'error',
                    },
                }),
            );
        }
        if (statusGuardians === 'error') {
            dispatch(
                enqueueSnackbar({
                    message: errorGuardians?.message,
                    options: {
                        variant: 'error',
                    },
                }),
            );
        }
    }, [statusBonuses, statusGuardians]);

    return (
        <div className={`${className} background`}>
            <Container>
                <Divider />
                <Typography variant="h3" textAlign="center" color="primary" py={3} fontWeight={700}>
                    {t('pages:battle.battle.battle')}
                </Typography>
                <Typography color="secondary.contrastText" align="center" gutterBottom>
                    {t('pages:battle.battle.chooseYourGuardian')}
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item md="auto" xs={12}>
                        <div className="card-container">
                            <Typography variant="h7" color="white" className="card-title">
                                {t('pages:battle.battle.yourGuardian')}
                            </Typography>
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item md="auto">
                                    {selectedGuardian ? (
                                        <CardTooltip {...selectedGuardian}>
                                            <GuardianCard
                                                {...selectedGuardian}
                                                size="lg"
                                                checkbox={false}
                                                onClick={() => setCardSelectDialog(true)}
                                            />
                                        </CardTooltip>
                                    ) : (
                                        <UnselectedCard
                                            size="lg"
                                            unselectedTitle={t('pages:battle.battle.noGuardianSelected')}
                                            onClick={selectCard}
                                        />
                                    )}
                                </Grid>
                                <Grid item md="auto">
                                    {selectedBonus ? (
                                        <BonusTooltip {...selectedBonus}>
                                            <BonusCard
                                                {...selectedBonus}
                                                className="bonus-card"
                                                size="lg"
                                                checkbox={false}
                                                onClick={() => setBonusSelectDialog(true)}
                                            />
                                        </BonusTooltip>
                                    ) : (
                                        <UnselectedCard
                                            size="lg"
                                            unselectedTitle={t('pages:battle.battle.noBonusSelected')}
                                            onClick={selectBonus}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            {selectedGuardian && selectedBonus && (
                                <Button
                                    disableRipple
                                    variant="outlined2"
                                    className="button"
                                    onClick={() => setCardSelectDialog(true)}
                                >
                                    {t('pages:battle.battle.modify')}
                                </Button>
                            )}
                        </div>
                    </Grid>
                    <Grid item md textAlign="center" alignSelf="center">
                        <VS className="vs" />
                    </Grid>
                    <Grid item md="auto" xs={12}>
                        <div className="card-container">
                            <Typography variant="h7" color="white" className="card-title">
                                {t('pages:battle.battle.enemyGuardian')}
                            </Typography>
                            {selectedEnemy ? (
                                <>
                                    <CardTooltip {...selectedEnemy}>
                                        <GuardianCard
                                            {...selectedEnemy}
                                            size="lg"
                                            checkbox={false}
                                            onClick={() => setEnemySelectDialog(true)}
                                        />
                                    </CardTooltip>
                                    <Button
                                        variant="outlined2"
                                        className="button"
                                        onClick={() => setEnemySelectDialog(true)}
                                    >
                                        {t('pages:battle.battle.modify')}
                                    </Button>
                                </>
                            ) : (
                                <UnselectedCard
                                    size="lg"
                                    unselectedTitle={t('pages:battle.battle.noEnemySelected')}
                                    onClick={() => setEnemySelectDialog(true)}
                                    disabled={!selectedGuardian || !selectedBonus}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Elements className="elements" />
                <div className="buttons">
                    <Button
                        disableRipple
                        variant="contained"
                        disabled={!selectedGuardian || !selectedBonus || !selectedEnemy}
                        fullWidth
                        className="button"
                        onClick={startFighting}
                    >
                        {t('pages:battle.battle.fight')}
                    </Button>
                </div>
                {cardSelectDialog && <GuardianSelect open={cardSelectDialog} setOpen={setCardSelectDialog} />}
                {bonusSelectDialog && <BonusSelect open={bonusSelectDialog} setOpen={setBonusSelectDialog} />}
                {selectedGuardian && selectedBonus && status === 'preparing' && (
                    <EnemySelect open={enemySelectDialog} setOpen={setEnemySelectDialog} />
                )}
                <BattleResult />
            </Container>
        </div>
    );
};

export default styled(Component)`
    background-image: url(${battleBack});
    padding-top: ${({ theme }) => theme.spacing(21)};
    padding-bottom: ${({ theme }) => theme.spacing(3)};

    .card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-title {
        padding-bottom: ${({ theme }) => theme.spacing(4.5)};
    }

    .buttons {
        width: 200px;
        margin: auto;
    }

    .button {
        margin-top: ${({ theme }) => theme.spacing(3)};
    }

    .vs {
        height: 72px;
        width: 100%;
        margin: ${({ theme }) => theme.spacing(4, 0)};
    }

    .elements {
        margin-top: ${({ theme }) => theme.spacing(3)};
    }
`;
