import * as React from 'react';
import { FC } from 'react';
import { Box, Button, ButtonProps, Divider as MuiDivider, Skeleton, SvgIcon, Tooltip } from '@mui/material';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Coin } from '../../assets/coin.svg';

interface Props extends ButtonProps {
    className?: string;
    aokAmount?: number;
    serAmount?: number;
}

const Component: FC<Props> = ({ className, ...props }) => {
    const { t } = useTranslation();
    const wallet = useSelector((state: RootState) => state.player.player.wallet);
    const history = useHistory();

    return (
        <Button variant="contained" className={className} onClick={() => history.push('/player/deposit')} {...props}>
            {wallet ? (
                <div className="info-bar-wrapper">
                    <Tooltip title={t('components:extended.playerBar.balance')}>
                        <Box display="flex" alignItems="center">
                            <NumberFormat
                                value={wallet.balance}
                                displayType="text"
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                            <SvgIcon className="coin-icon">
                                <Coin width={24} height={24} />
                            </SvgIcon>
                        </Box>
                    </Tooltip>
                    <MuiDivider orientation="vertical" flexItem className="divider" />
                    <Tooltip title={t('components:extended.playerBar.pending')}>
                        <Box display="flex" alignItems="center">
                            <NumberFormat
                                value={wallet.pending}
                                displayType="text"
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                            <SvgIcon className="coin-icon">
                                <Coin width={24} height={24} />
                            </SvgIcon>
                        </Box>
                    </Tooltip>
                </div>
            ) : (
                <div className="info-bar-wrapper">
                    <Skeleton animation="wave" height={20} width={85} />
                    <MuiDivider orientation="vertical" flexItem className="divider" />
                    <Skeleton animation="wave" height={20} width={85} />
                </div>
            )}
        </Button>
    );
};

export default styled(Component)`
    .info-bar-wrapper {
        display: flex;
        align-items: center;
    }

    .coin-txt {
        margin-left: ${({ theme }) => theme.spacing(1)};
    }

    .divider {
        margin: ${({ theme }) => theme.spacing(0, 2)};
        width: 2px;
        background: ${({ theme }) => theme.palette.primary.main};
    }

    .coin-icon {
        margin-left: ${({ theme }) => theme.spacing(1)};
    }
`;
