import React, { FC, useEffect, useState } from 'react';
import {
    Link,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/Store';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';
import { useHistory } from '../../../api';
import { useSnackbar } from 'notistack';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const [page, setPage] = useState(1);
    const { error, status, data: history, isLoading } = useHistory(authKey, { page });
    const { enqueueSnackbar } = useSnackbar();

    const historySkeleton = new Array(10).fill(null);
    const skeleton = (
        <>
            {historySkeleton.map((item, idx) => (
                <TableRow key={idx}>
                    {new Array(4).fill(null).map((value, index) => (
                        <TableCell key={index} align="left">
                            <Typography variant="body2">
                                <Skeleton variant="text" animation="wave" />
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );

    const categoryFilter = (value: string) => {
        switch (value) {
            case 'reward-full':
                return t('pages:player.history.history.reward');
            case 'fight-fee':
                return t('pages:player.history.history.fightFee');
            case 'reward-pending':
                return t('pages:player.history.history.rewardPending');
            case 'bonus-roll':
                return t('pages:player.history.history.bonus');
            case 'guardian-roll':
                return t('pages:player.history.history.guardian');
            case 'deposit':
                return t('pages:player.history.history.deposit');
            case 'withdrawal':
                return t('pages:player.history.history.withdrawal');
            case 'reward-claimed':
                return t('pages:player.history.history.rewardClaimed');
            case 'error':
                return t('pages:player.history.history.error');
            default:
                return t('pages:player.history.history.other');
        }
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        if (status === 'error') {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    }, [status]);

    return (
        <div className={className}>
            <Typography variant="h6" align="center" component="div" color="common.white" className="title">
                {t('pages:player.history.history.history')}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography align="left" color="white">
                                    {t('pages:player.history.history.date')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="left" color="white">
                                    {t('pages:player.history.history.transaction')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="left" color="white">
                                    {t('pages:player.history.history.type')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right" color="white">
                                    {t('pages:player.history.history.amount')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history && !isLoading
                            ? history.list.map((historyItem: Seiren.History) => (
                                  <TableRow key={historyItem.id} className="table-row">
                                      <TableCell className="table-row" align="left">
                                          <Typography color="common.white">
                                              <Moment format="yyyy-MM-DD HH:mm:ss" date={historyItem.created * 1000} />
                                          </Typography>
                                      </TableCell>
                                      <TableCell align="left">
                                          <Link
                                              color="common.white"
                                              href={`https://aokscan.com/transaction/${historyItem.txid}`}
                                              target="_blank"
                                              underline="hover"
                                          >
                                              {historyItem.txid}
                                          </Link>
                                      </TableCell>
                                      <TableCell align="left">
                                          <Typography color="common.white">
                                              {categoryFilter(historyItem.category)}
                                          </Typography>
                                      </TableCell>
                                      <TableCell align="right">
                                          <Typography display="inline" color="common.white">
                                              <NumberFormat
                                                  thousandSeparator
                                                  value={historyItem.amount}
                                                  decimalScale={8}
                                                  fixedDecimalScale
                                                  suffix=" SERG"
                                                  displayType="text"
                                              />
                                          </Typography>
                                      </TableCell>
                                  </TableRow>
                              ))
                            : skeleton}
                    </TableBody>
                </Table>
            </TableContainer>
            {history && history.pagination && (
                <div className="page-box">
                    <Pagination
                        count={history.pagination.pages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        size="large"
                        disabled={isLoading}
                    />
                </div>
            )}
        </div>
    );
};

export default styled(Component)`
    .title {
        margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    .table-row {
        td {
            border-color: ${({ theme }) => theme.palette.primary.light};
        }
    }

    .page-box {
        display: flex;
        justify-content: center;
        margin-top: ${({ theme }) => theme.spacing(5)};
    }
`;
