import { useQuery } from 'react-query';
import { Seiren } from '../types/Seiren';
import axios from 'axios';
import _ from 'lodash';

type Params = {
    page?: number;
};

interface Response {
    list: Seiren.History[];
    pagination: {
        current: number;
        pages: number;
        total: number;
    };
}

async function fetch(token: string, params: Params) {
    try {
        const { data } = await axios.get(`${axios.defaults.baseURL}/user/history`, {
            params,
            headers: { Authentication: token },
        });

        if (!data?.error) {
            return {
                list: data.data.list.map((g: Seiren.History) => _.mapKeys(g, (v: any, k: string) => _.camelCase(k))),
                pagination: data.data.pagination,
            };
        } else {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}

export default function (token: string, params: Params) {
    return useQuery<Response, Error>(['history', token, params], () => fetch(token, params));
}
