import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { languages } from './i18n.constants';

const createI18n = (language: string): i18nInstance => {
    const i18n = i18next.createInstance().use(initReactI18next);

    i18n.use(Backend)
        .use(initReactI18next)
        .init({
            react: {
                useSuspense: true, //   <---- this will do the magic
            },
            debug: true,
            lng: language,
            fallbackLng: language,
            interpolation: {
                escapeValue: false,
            },
            ns: ['app', 'pages', 'common', 'components'],
            defaultNS: 'common',
        });

    return i18n;
};

export const i18n = createI18n(languages.ko);
