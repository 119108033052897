import React, { FC } from 'react';
import { Avatar, Container, Typography } from '@mui/material';
import { Level } from '../../../components/common';

import styled from 'styled-components';
import { RootState } from '../../../redux/Store';
import { useSelector } from 'react-redux';

interface Props {
    className?: string;
    profile?: {
        growth?: number;
        level?: number;
        points?: number;
        completed?: number;
    };
}

const Component: FC<Props> = ({ className }) => {
    const profile = useSelector((state: RootState) => state.player.player);

    return (
        <Container className={className}>
            <div className="wrapper-profile">
                <div className="profile-avatar">
                    <Avatar className="avatar" src={profile.avatar} />
                </div>
                <Typography color="white" gutterBottom>
                    {profile.address}
                </Typography>
                <Level level={profile.level} xp={profile.xp} nextXp={profile.nextXp} className="lvl" />
            </div>
        </Container>
    );
};

Component.defaultProps = {
    profile: {
        growth: 0,
        level: 1,
        points: 0,
        completed: 0,
    },
};

export default styled(Component)`
    .lvl {
        width: 250px;
    }

    .wrapper-profile {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .profile-avatar {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        width: fit-content;
        border-radius: 50vh;
        margin: ${({ theme }) => theme.spacing(3)};
    }

    .avatar {
        width: 100px;
        height: 100px;
        margin: ${({ theme }) => theme.spacing(1)};
    }
`;
