import React, { CSSProperties } from 'react';
import { createTheme } from '@mui/material';
import { ReactComponent as CheckBox } from '../assets/checkbox/checkbox.svg';
import { ReactComponent as CheckBoxOn } from '../assets/checkbox/checkbox-active.svg';
import contained1Right from '../assets/btn/contained1_right.svg';
import contained1Left from '../assets/btn/contained1_left.svg';
import contained2Right from '../assets/btn/contained2_right.svg';
import contained2Left from '../assets/btn/contained2_left.svg';
import outlined1Right from '../assets/btn/outlined1_right.svg';
import outlined1Left from '../assets/btn/outlined1_left.svg';
import outlined2Right from '../assets/btn/outlined2_right.svg';
import outlined2Left from '../assets/btn/outlined2_left.svg';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        confirm: true;
        contained2: true;
        outlined2: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        buttonLg: true;
        h7: true;
    }
}

declare module '@mui/material/styles' {
    interface Theme {
        card: {
            yellow: CSSProperties['color'];
            green: CSSProperties['color'];
            red: CSSProperties['color'];
            blue: CSSProperties['color'];
            yellowLv2: CSSProperties['color'];
            greenLv2: CSSProperties['color'];
            redLv2: CSSProperties['color'];
            blueLv2: CSSProperties['color'];
            lv3: CSSProperties['color'];
            lv4: CSSProperties['color'];
            lv5: CSSProperties['color'];
            white: CSSProperties['color'];
            black: CSSProperties['color'];
        };
    }

    interface ThemeOptions {
        card: {
            yellow: CSSProperties['color'];
            green: CSSProperties['color'];
            red: CSSProperties['color'];
            blue: CSSProperties['color'];
            yellowLv2: CSSProperties['color'];
            greenLv2: CSSProperties['color'];
            redLv2: CSSProperties['color'];
            blueLv2: CSSProperties['color'];
            lv3: CSSProperties['color'];
            lv4: CSSProperties['color'];
            lv5: CSSProperties['color'];
            white: CSSProperties['color'];
            black: CSSProperties['color'];
        };
    }

    interface TypographyVariants {
        buttonLg: CSSProperties;
        title: CSSProperties;
        h7: CSSProperties;
    }

    interface TypographyVariantsOptions {
        buttonLg?: CSSProperties;
        title?: CSSProperties;
        h7?: CSSProperties;
    }
}

const theme = createTheme({
    card: {
        yellow: '#e1ac67',
        green: '#47cb85',
        red: '#eb6868',
        blue: '#3ed5bb',
        yellowLv2: 'linear-gradient(to bottom, #e1ac67, #67e1c0)',
        greenLv2: 'linear-gradient(to bottom, #53c981, #cebc62)',
        redLv2: 'linear-gradient(to bottom, #eb6868, #6c68eb)',
        blueLv2: 'linear-gradient(to bottom, #3ed5bb, #3e9ed5)',
        lv3: 'linear-gradient(135deg, #BBA99C 20%, #6A5F4D 75%, #675E51 150%, #5D504A)',
        lv4: 'linear-gradient(135deg, #D2D2D2 20%, #676767 75%, #585858 150%, #616161)',
        lv5: 'linear-gradient(135deg, #F3D18D 20%, #7A5A1B 75%, #826425 150%, #7A6947)',
        white: 'linear-gradient(to bottom, #e5e5e5, #ccc)',
        black: '#000',
    },
    typography: {
        fontFamily: "'Jost', sans-serif,'Cinzel', serif",
        h1: {
            fontFamily: "'Cinzel', serif",
            fontWeight: 500,
            fontSize: '5rem', //80px
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: "'Cinzel', serif",
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: "'Cinzel', serif",
            textTransform: 'uppercase',
        },
        h4: {
            fontFamily: "'Cinzel', serif",
            fontWeight: 500,
            fontSize: '2rem', //32px
            textTransform: 'uppercase',
        },
        h5: {
            fontFamily: "'Cinzel', serif",
            fontWeight: 500,
            fontSize: '1.5rem', //24px
            textTransform: 'uppercase',
        },
        h6: {
            fontFamily: "'Cinzel', serif",
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        h7: {
            fontFamily: "'Cinzel', serif",
            fontWeight: 500,
            fontSize: '1rem', //16px
            textTransform: 'uppercase',
        },
        button: {
            fontFamily: "'Jost'",
        },
        buttonLg: {
            fontWeight: 500,
            fontSize: '1.25rem', //20px
            textTransform: 'uppercase',
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#F7DA96',
            dark: '#beb79f',
            light: '#f8a649',
        },
        secondary: {
            main: '#06032d',
            dark: '#060421',
            light: '#4153F1',
            contrastText: '#aba8d5',
        },
        text: {
            // primary: '#fff',
        },
        action: {
            hover: 'rgba(193, 171, 109, 0.4)',
            // focus: 'rgba(193, 171, 109, 0.4)',
        },
        background: {
            default: '#0a063e',
        },
    },
});

theme.components = {
    ...theme.components,
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
    },
    MuiButton: {
        variants: [
            // contained2
            {
                props: { variant: 'contained2' },
                style: {
                    position: 'relative',
                    color: theme.palette.secondary.main,
                    background: 'linear-gradient(262.85deg, #f1cd88 7.76%, #d3a064 75.23%)',
                    padding: theme.spacing(0.75, 3),
                    borderRadius: 0,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderImageSlice: 1,
                    borderImageSource: 'linear-gradient(to left, rgba(123, 76, 20, 0.33), rgba(255, 236, 201, 0.65))',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '&::before, &::after': {
                        transition: 'all 250ms',
                        boxShadow: 'none',
                        content: '""',
                        position: 'absolute',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        width: 17,
                        height: 50,
                    },
                    '&::before': {
                        right: -17,
                        backgroundImage: `url(${contained2Left})`,
                    },
                    '&::after': {
                        left: -17,
                        backgroundImage: `url(${contained2Right})`,
                    },
                    '&:disabled': {
                        color: theme.palette.secondary.main,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderImageSlice: 1,
                        borderImageSource:
                            'linear-gradient(to left, rgba(123, 76, 20, 0.33), rgba(255, 236, 201, 0.65))',
                        borderLeft: 'none',
                        borderRight: 'none',
                    },
                    '&:hover': {
                        boxShadow: '0px 2.5px 10px rgba(212, 161, 101, 0.5)',
                    },
                },
            },
            {
                props: {
                    variant: 'contained2',
                    size: 'medium',
                },
                style: {
                    fontSize: '1.25rem',
                    marginLeft: 17,
                    marginRight: 17,
                    height: 50,
                    minWidth: 50,
                },
            },
            {
                props: {
                    variant: 'contained2',
                    size: 'small',
                },
                style: {
                    marginLeft: 11.81,
                    marginRight: 11.81,
                    height: 34.75,
                    minWidth: 34.75,
                    borderWidth: 1,
                    padding: theme.spacing(0.5, 2.1),
                    '&::before, &::after': {
                        width: 11.81,
                        height: 34.75,
                    },
                    '&::before': {
                        right: -11.81,
                    },
                    '&::after': {
                        left: -11.81,
                    },
                },
            },
            {
                props: {
                    variant: 'contained2',
                    size: 'large',
                },
                style: {
                    marginLeft: 19.72,
                    marginRight: 19.72,
                    padding: theme.spacing(0.75, 3.5),
                    fontSize: '1.5rem',
                    height: 58,
                    minWidth: 58,
                    '&::before, &::after': {
                        width: 19.72,
                        height: 58,
                    },
                    '&::before': {
                        right: -19.72,
                    },
                    '&::after': {
                        left: -19.72,
                    },
                },
            },
            // outlined2
            {
                props: { variant: 'outlined2' },
                style: {
                    transition: 'all 250ms',
                    color: theme.palette.primary.main,
                    padding: theme.spacing(0.75, 3),
                    borderRadius: 0,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderImageSlice: 1,
                    borderImageSource: 'linear-gradient(to left, rgba(238, 200, 132, 1), rgba(255, 236, 201, 0.65))',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '&::before, &::after': {
                        transition: 'all 250ms',
                        boxShadow: 'none',
                        content: '""',
                        position: 'absolute',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        width: 17,
                        height: 50,
                    },
                    '&::before': {
                        right: -17,
                        backgroundImage: `url(${outlined2Left})`,
                    },
                    '&::after': {
                        left: -17,
                        backgroundImage: `url(${outlined2Right})`,
                    },
                    '&:disabled': {
                        color: theme.palette.secondary.main,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderImageSlice: 1,
                        borderImageSource:
                            'linear-gradient(to left, rgba(123, 76, 20, 0.33), rgba(255, 236, 201, 0.65))',
                        borderLeft: 'none',
                        borderRight: 'none',
                    },
                    '&:hover': {
                        background: 'transparent',
                        color: theme.palette.common.white,
                    },
                },
            },
            {
                props: {
                    variant: 'outlined2',
                    size: 'medium',
                },
                style: {
                    fontSize: '1.25rem',
                    marginLeft: 17,
                    marginRight: 17,
                    height: 50,
                    minWidth: 50,
                },
            },
            {
                props: {
                    variant: 'outlined2',
                    size: 'small',
                },
                style: {
                    marginLeft: 11.81,
                    marginRight: 11.81,
                    height: 34.75,
                    minWidth: 34.75,
                    borderWidth: 1,
                    padding: theme.spacing(0.5, 2.1),
                    '&::before, &::after': {
                        width: 11.81,
                        height: 34.75,
                    },
                    '&::before': {
                        right: -11.81,
                    },
                    '&::after': {
                        left: -11.81,
                    },
                },
            },
            {
                props: {
                    variant: 'outlined2',
                    size: 'large',
                },
                style: {
                    marginLeft: 19.72,
                    marginRight: 19.72,
                    padding: theme.spacing(0.75, 3.5),
                    fontSize: '1.5rem',
                    height: 58,
                    minWidth: 58,
                    '&::before, &::after': {
                        width: 19.72,
                        height: 58,
                    },
                    '&::before': {
                        right: -19.72,
                    },
                    '&::after': {
                        left: -19.72,
                    },
                },
            },
        ],
        styleOverrides: {
            root: {
                '&:disabled': {
                    opacity: 0.5,
                },
            },
            // contained
            contained: {
                position: 'relative',
                background: 'linear-gradient(262.85deg, #f1cd88 7.76%, #d3a064 75.23%)',
                padding: theme.spacing(0.75, 3),
                borderRadius: 0,
                borderWidth: '2px',
                borderStyle: 'solid',
                borderImageSlice: 1,
                borderImageSource: 'linear-gradient(to left, rgba(123, 76, 20, 0.33), rgba(255, 236, 201, 0.65))',
                borderLeft: 'none',
                borderRight: 'none',
                '&::before, &::after': {
                    transition: 'all 250ms',
                    boxShadow: 'none',
                    content: '""',
                    position: 'absolute',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: 15,
                    height: 50,
                },
                '&::before': {
                    right: -15,
                    backgroundImage: `url(${contained1Left})`,
                },
                '&::after': {
                    left: -15,
                    backgroundImage: `url(${contained1Right})`,
                },
                '&:disabled': {
                    color: theme.palette.secondary.main,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderImageSlice: 1,
                    borderImageSource: 'linear-gradient(to left, rgba(123, 76, 20, 0.33), rgba(255, 236, 201, 0.65))',
                    borderLeft: 'none',
                    borderRight: 'none',
                },
                '&:hover': {
                    boxShadow: '0px 2.5px 10px rgba(212, 161, 101, 0.5)',
                },
            },
            containedSizeMedium: {
                fontSize: '1.25rem',
                marginLeft: 15,
                marginRight: 15,
                height: 50,
                minWidth: 50,
            },
            containedSizeSmall: {
                marginLeft: 10.43,
                marginRight: 10.43,
                height: 34.75,
                minWidth: 34.75,
                borderWidth: 1,
                padding: theme.spacing(0.5, 2.1),
                '&::before, &::after': {
                    width: 10.43,
                    height: 34.75,
                },
                '&::before': {
                    right: -10.43,
                },
                '&::after': {
                    left: -10.43,
                },
            },
            containedSizeLarge: {
                marginLeft: 17.4,
                marginRight: 17.4,
                padding: theme.spacing(0.75, 3.5),
                fontSize: '1.5rem',
                height: 58,
                minWidth: 58,
                '&::before, &::after': {
                    width: 17.4,
                    height: 58,
                },
                '&::before': {
                    right: -17.4,
                },
                '&::after': {
                    left: -17.4,
                },
            },
            // outlined
            outlined: {
                transition: 'all 250ms',
                color: theme.palette.primary.main,
                padding: theme.spacing(0.75, 3),
                borderRadius: 0,
                borderWidth: '2px',
                borderStyle: 'solid',
                borderImageSlice: 1,
                borderImageSource: 'linear-gradient(to left, rgba(238, 200, 132, 1), rgba(255, 236, 201, 0.65))',
                borderLeft: 'none',
                borderRight: 'none',
                '&::before, &::after': {
                    transition: 'all 250ms',
                    content: '""',
                    position: 'absolute',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: 15,
                    height: 50,
                },
                '&::before': {
                    right: -15,
                    backgroundImage: `url(${outlined1Left})`,
                },
                '&::after': {
                    left: -15,
                    backgroundImage: `url(${outlined1Right})`,
                },
                '&:disabled': {
                    color: theme.palette.primary.main,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderImageSlice: 1,
                    borderImageSource: 'linear-gradient(to left, rgba(238, 200, 132, 1), rgba(255, 236, 201, 0.65))',
                    borderLeft: 'none',
                    borderRight: 'none',
                },
                '&:hover': {
                    background: 'transparent',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderImageSlice: 1,
                    borderImageSource: 'linear-gradient(to left, rgba(238, 200, 132, 1), rgba(255, 236, 201, 0.65))',
                    borderLeft: 'none',
                    borderRight: 'none',
                    color: theme.palette.common.white,
                },
            },
            outlinedSizeMedium: {
                fontSize: '1.25rem',
                marginLeft: 15,
                marginRight: 15,
                height: 50,
                minWidth: 50,
            },
            outlinedSizeSmall: {
                marginLeft: 10.43,
                marginRight: 10.43,
                height: 34.75,
                minWidth: 34.75,
                borderWidth: 1,
                padding: theme.spacing(0.5, 2.1),
                '&::before, &::after': {
                    width: 10.43,
                    height: 34.75,
                },
                '&::before': {
                    right: -10.43,
                },
                '&::after': {
                    left: -10.43,
                },
            },
            outlinedSizeLarge: {
                marginLeft: 17.4,
                marginRight: 17.4,
                padding: theme.spacing(0.75, 3.5),
                fontSize: '1.5rem',
                height: 58,
                minWidth: 58,
                '&::before, &::after': {
                    width: 17.4,
                    height: 58,
                },
                '&::before': {
                    right: -17.4,
                },
                '&::after': {
                    left: -17.4,
                },
            },
        },
    },
    MuiPaginationItem: {
        styleOverrides: {
            icon: {
                color: theme.palette.primary.main,
            },
            text: {
                ...theme.typography.buttonLg,
            },
            root: {
                '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    ':hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                },
                color: theme.palette.common.white,
            },
            rounded: {
                borderRadius: 0,
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                borderColor: theme.palette.primary.light,
            },
        },
    },
    MuiCheckbox: {
        defaultProps: {
            icon: <CheckBox />,
            checkedIcon: <CheckBoxOn />,
        },
    },
    MuiBackdrop: {
        styleOverrides: {
            root: {
                backgroundColor: 'rgb(0 0 0 / 90%)',
            },
        },
    },
    MuiTypography: {
        defaultProps: {
            variantMapping: {
                h7: 'h2',
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                position: 'relative',
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.common.white,
                fontSize: theme.typography.pxToRem(12),
                borderRadius: 0,
                '&:after': {
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: -1,
                    margin: 0,
                    borderRadius: 'inherit',
                    background: theme.palette.secondary.dark,
                },
                '&:before': {
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: -2,
                    margin: -2,
                    borderRadius: 'inherit',
                    background: 'linear-gradient(to right, #060421, #3A2BF5)',
                },
            },
            arrow: {
                color: theme.palette.secondary.dark,
            },
        },
    },
    // Todo fix issue with Rating
    // MuiRating: {
    //     defaultProps: {
    //         icon: <RatingOn />,
    //         emptyIcon: <RatingOff />,
    //     },
    // },
};

export default theme;
