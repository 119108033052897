import {
    RESET_BATTLE,
    RESET_BONUS,
    RESET_ENEMY,
    SET_IN_SELECTED_STATE,
    SET_IN_STATE,
    SET_RESULT,
} from '../actions/battle';
import { AnyAction } from 'redux';
import { Seiren } from '../../types/Seiren';

interface ReducerState {
    selected: {
        count: number;
        guardian?: Seiren.GuardianCard;
        bonus?: Seiren.Bonus;
        enemy?: Seiren.GuardianCard[];
    };
    result?: {
        guardian: Seiren.GuardianCard;
        enemy: Seiren.GuardianCard;
        success: boolean;
        reward: number;
    };
    status: 'preparing' | 'fighting' | 'finished';
}

const initialState: ReducerState = {
    status: 'preparing',
    selected: {
        count: 0,
        guardian: undefined,
        bonus: undefined,
        enemy: undefined,
    },
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_IN_STATE: {
            return { ...state, ...action.payload };
        }
        case SET_IN_SELECTED_STATE: {
            return {
                ...state,
                selected: { ...state.selected, ...action.payload },
            };
        }
        case SET_RESULT: {
            return {
                ...state,
                result: {
                    guardian: state.selected.guardian,
                    enemy: state.selected.enemy,
                    success: action.payload.success,
                    reward: action.payload.reward,
                },
            };
        }
        case RESET_ENEMY: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    enemy: undefined,
                },
            };
        }
        case RESET_BATTLE: {
            return { ...state, ...initialState };
        }
        case RESET_BONUS: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    bonus: undefined,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
