import React, { FC } from 'react';
import styled from 'styled-components';
import { LinearProgress, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

interface Props {
    className?: string;
    icon?: string;
    value: number;
    nextValue: number;
    color?: string;
}

const Component: FC<Props> = ({ className, icon, value, nextValue }) => {
    return (
        <div className={className}>
            <div className="scale-wrapper">
                {icon && <img src={icon} alt="Fight Points" className="icon" />}
                <Typography color="white" variant="body1" fontWeight="bold">
                    <NumberFormat value={value} displayType="text" thousandSeparator fixedDecimalScale />
                </Typography>
                <Typography color="white" variant="body1">
                    /
                    <NumberFormat value={nextValue} displayType="text" thousandSeparator fixedDecimalScale />
                </Typography>
            </div>
            <LinearProgress
                variant="determinate"
                value={nextValue - value >= 0 ? (value / nextValue) * 100 : 100}
                className="scale-progress-bar"
            />
        </div>
    );
};

export default styled(Component)`
    padding: ${({ theme }) => theme.spacing(0, 1)};
    width: 100%;

    .scale-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    .scale-progress-bar {
        margin-top: ${({ theme }) => theme.spacing(1)};
        background-color: rgba(0, 0, 0, 0.5);
        min-height: 25px;
        border-radius: 4px;
        border: 3px solid transparent;

        .MuiLinearProgress-barColorPrimary {
            border-radius: 4px;
            background: ${({ theme, color }) =>
                color === 'secondary' ? theme.palette.secondary.light : theme.palette.primary.light};
        }
    }

    .icon {
        height: 20px;
        margin-right: ${({ theme }) => theme.spacing(1)};
    }
`;
