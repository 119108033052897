import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { GuardianCard, UnselectedCard } from '../index';
import { CardTooltip } from './index';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props {
    className?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const cardArr: Seiren.GuardianCard = {
    avatar: 'https://source.boringavatars.com/bauhaus/120/KnottyPiquantNarwhal?colors=264653,2a9d8f,e9c46a,f4a261,e76f51',
    burned: false,
    element: 'lightning',
    id: '2cf313b7-b5b8-4e42-be33-b8a904868c3e',
    level: 0,
    name: 'KnottyPiquantNarwhal',
    nextXp: 16,
    owner: 'Kj8pwBC7MagJivYzdVZT5vaD7cjLoLkyq8',
    seed: '4b92d874b3366dfe2f08cf5c4c73c9a354afb430e043bf3914f35188e119a206',
    stamina: 200,
    xp: 0,
};

const Component: FC<Props> = ({ className, open, setOpen }) => {
    const { t } = useTranslation();
    const [card] = useState<Seiren.GuardianCard>(cardArr);

    return (
        <Dialog open={open} className={className} fullWidth maxWidth="lg" PaperComponent={Box}>
            <Typography variant="h4" align="center" component="div" color="white" className="title">
                {t('components:common.cards.guardianUpgrade.battle')}
            </Typography>
            <Typography className="subtitle" color="secondary.contrastText" align="center" gutterBottom>
                {t('components:common.cards.guardianUpgrade.chooseYourGuardian')}
            </Typography>
            <Grid container>
                <Grid item>
                    <Box className="card-container">
                        <Typography variant="h7" color="white" className="card-title">
                            {t('components:common.cards.guardianUpgrade.guardian')}
                        </Typography>
                        <CardTooltip {...card}>
                            <GuardianCard className="swiper-slide" {...card} key={card.id} />
                        </CardTooltip>
                    </Box>
                </Grid>
                <Grid item md textAlign="center">
                    <Typography>{t('components:common.cards.guardianUpgrade.kkkkdkdkd')}</Typography>
                </Grid>
                <Grid item>
                    <Box className="card-container">
                        <Typography variant="h7" color="white" className="card-title">
                            {t('components:common.cards.guardianUpgrade.materials')}
                        </Typography>
                        <UnselectedCard size="md" />
                    </Box>
                </Grid>
            </Grid>
            <Button variant="contained" className="close-btn" onClick={() => setOpen(false)}>
                {t('components:common.cards.guardianUpgrade.upgrade')}
            </Button>
            <Button variant="outlined" className="close-btn" onClick={() => setOpen(false)}>
                {t('components:common.cards.guardianUpgrade.back')}
            </Button>
        </Dialog>
    );
};

export default styled(Component)`
    .card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-title {
        padding-bottom: ${({ theme }) => theme.spacing(4.5)};
    }

    .close-btn {
        align-self: center;
    }
`;
