import { Dispatch } from 'redux';

export const SET_STATUS = 'swap/SET_STATUS';

export const setStatus = (payload: { status?: 'initial' | 'failed' | 'success' }) => ({
    type: SET_STATUS,
    payload,
});

export const sendForm = (payload: {
    formData: {
        amountFrom: number;
        currencyFrom: number;
        amountTo: number;
        currencyTo: number;
    };
}) => {
    /** Attention: fake logic */
    const { amountTo, amountFrom } = payload.formData;
    return (dispatch: Dispatch) => {
        setTimeout(() => {
            if (amountTo > amountFrom) {
                dispatch(setStatus({ status: 'failed' }));
            } else {
                dispatch(setStatus({ status: 'success' }));
            }
        }, 1000);
    };
};
