import React, { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import disabledImg from '../../../assets/card/noCard/No_Card_Selet_Img_252px@3x.png';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props extends Seiren.GuardianCard {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className="disabled-card">
                <img src={disabledImg} alt={t('components:common.cards.disabledCard.plus')} className="no-card-img" />
                <Typography className="disabled-txt" variant="body2">
                    {t('components:common.cards.disabledCard.noGuardianSelected')}
                </Typography>
            </div>
        </div>
    );
};

export default styled(Component)`
    .disabled-card {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: ${({ theme }) => theme.spacing(6, 6, 8, 6)};
        max-width: 252px;
        border: 1px solid ${({ theme }) => theme.palette.grey.A700};
    }

    .disabled-txt {
        color: ${({ theme }) => theme.palette.grey.A700};
    }

    .no-card-img {
        width: 68px;
        margin: ${({ theme }) => theme.spacing(7)};
    }
`;
