import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { RoadMapItem } from '../components';

import roadmap1 from '../../../assets/home/roadmap/Main_Roadmap_Img_1_440px.png';
import roadmap2 from '../../../assets/home/roadmap/Main_Roadmap_Img_2_440px.png';
import roadmap3 from '../../../assets/home/roadmap/Main_Roadmap_Img_3_440px.png';
import { SectionHeader } from '../../../components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const roadmapData = [
        {
            title: t('pages:home.layout.roadmap.theKingOfTheSea'),
            chapter: t('pages:home.layout.roadmap.seirenWarNow'),
            desc: t('pages:home.layout.roadmap.itsARandomCardBattleGameBuyACardWithAokAndDefeatTh'),
            img: roadmap1,
        },
        {
            title: t('pages:home.layout.roadmap.deepSea'),
            chapter: t('pages:home.layout.roadmap.seirenWarPhase2'),
            desc: t('pages:home.layout.roadmap.weDiscoveredANewWayOfMiningThroughInnovativeIdeasW'),
            img: roadmap2,
        },
        {
            title: t('pages:home.layout.roadmap.lostAtlantis'),
            chapter: t('pages:home.layout.roadmap.seirenWarPhase3'),
            desc: t('pages:home.layout.roadmap.seaMetabusAfterRevealingTheBirdsEyeViewWithAtlanti'),
            img: roadmap3,
        },
    ];

    return (
        <div className={className}>
            <SectionHeader title={t('pages:home.layout.roadmap.roadmap')} className="roadmap-title" />
            <Grid container spacing={8}>
                {roadmapData.map((item, idx) => (
                    <RoadMapItem title={item.title} img={item.img} desc={item.desc} chapter={item.chapter} key={idx} />
                ))}
            </Grid>
        </div>
    );
};

export default styled(Component)`
    padding: ${({ theme }) => theme.spacing(7, 0)};

    .roadmap-title {
        margin: ${({ theme }) => theme.spacing(6, 0, 4, 0)};
    }
`;
