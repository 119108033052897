import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid, LinearProgress, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import icon from '../../assets/card/level.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    level: number;
    xp: number;
    nextXp: number;
}

const Component: FC<Props> = ({ className, level, xp, nextXp }) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item container xs>
                    {icon && <img src={icon} alt="Fight Points" className="icon" />}
                    <Typography color="white" variant="body1" fontWeight="bold">
                        <NumberFormat value={xp} displayType="text" thousandSeparator fixedDecimalScale />
                    </Typography>
                    <Typography color="white" variant="body1">
                        /
                        <NumberFormat value={nextXp} displayType="text" thousandSeparator fixedDecimalScale />
                    </Typography>
                </Grid>
                <Grid item container xs="auto" md="auto" textAlign="left">
                    <Typography color="white" variant="body1" textAlign="right">
                        {t('components:common.level.lvl')}
                        <Typography
                            color="white"
                            variant="body1"
                            component="span"
                            fontWeight="bold"
                            display="inline"
                            pl={1}
                        >
                            {level + 1}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <LinearProgress
                variant="determinate"
                defaultValue={0}
                value={(xp / nextXp) * 100}
                className="scale-progress-bar"
            />
        </div>
    );
};

export default styled(Component)`
    max-width: 250px;

    .scale-progress-bar {
        margin-top: ${({ theme }) => theme.spacing(1)};
        background-color: transparent;
        min-height: 25px;
        border-radius: 2rem;
        border: 2px solid transparent;
        outline: 1px solid ${({ theme }) => theme.palette.primary.main};

        .MuiLinearProgress-barColorPrimary {
            border-radius: 2rem;
            background: ${({ theme }) => theme.palette.primary.main};
        }
    }

    .icon {
        height: 20px;
        margin-right: ${({ theme }) => theme.spacing(1)};
    }
`;
