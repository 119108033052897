import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { Theme } from '@mui/material';

interface CompinedTheme extends DefaultTheme, Theme {}

interface GlobalStyleProps {
    theme: CompinedTheme;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`

  ${({ theme }) => theme.breakpoints.down('md')} {
    html {
      font-size: 14px;
    }
  }

  body {
    min-height: 100vh;
  }

  #root {
    min-height: inherit;
  }

  .background {
    background-repeat: no-repeat;
    background-color: #0a063e;
    min-height: inherit;
    background-position: top;
    background-size: 100% auto;
  }

  .star {
    height: 12px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing(8)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }

  .tabs {
    border-bottom: solid 0.5px ${({ theme }) => theme.palette.primary.main} !important;
  }

  .tab {
    color: ${({ theme }) => theme.palette.common.white} !important;
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px !important;
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px !important;
  }
`;

export default GlobalStyle;
