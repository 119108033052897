import React, { FC, useEffect } from 'react';
import { Box, Button, Dialog, styled, Typography } from '@mui/material';
import { DefeatWrapper, GuardianCard, Level, VictoryWrapper } from '../../components/common';
import { RootState, useAppDispatch } from '../../redux/Store';
import { useSelector } from 'react-redux';
import { resetBattle, resetEnemy, setCards, setStatus } from '../../redux/actions/battle';
import coins from '../../assets/card/wrapper/coins.png';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

// import battleWebm from '../../assets/battle.webm';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const battleCount = useSelector((state: RootState) => state.battle.selected.count);
    const status = useSelector((state: RootState) => state.battle.status);
    const result = useSelector((state: RootState) => state.battle.result);
    const profile = useSelector((state: RootState) => state.player.player);
    // const [battleAnimationEnded, setBattleAnimationEnded] = useState(false);
    const [initialCloseTime, setInitialCloseTime] = React.useState(10);
    const [startCloseTimer, setStartCloseTimer] = React.useState(false);

    const fighting = () => (
        <>
            <Typography variant="h3" align="center" color="primary.light">
                {t('pages:battle.battleResult.fight')}
            </Typography>
            <Typography variant="h7" color="white" align="center" className="detail-subtitle">
                {t('pages:battle.battleResult.waitingForFightResults')}
            </Typography>
            {/*<video*/}
            {/*    loop={false}*/}
            {/*    autoPlay={true}*/}
            {/*    onEnded={() => setBattleAnimationEnded(true)}*/}
            {/*    muted*/}
            {/*    className="battle-animation"*/}
            {/*>*/}
            {/*    <source src={battleWebm} type="video/webm"></source>*/}
            {/*</video>*/}
        </>
    );

    const win = () => (
        <>
            <Typography variant="h3" align="center" color="primary.light">
                {t('pages:battle.battleResult.youWon')}
            </Typography>
            <Typography variant="h7" color="white" align="center" className="detail-subtitle">
                {t('pages:battle.battleResult.getANewRewardNow')}
            </Typography>
            {result.guardian && (
                <VictoryWrapper>
                    <GuardianCard
                        className="swiper-slide"
                        {...result.guardian}
                        size="lg"
                        checkbox={false}
                        titleDisabled
                        key={result.guardian.id}
                    />
                </VictoryWrapper>
            )}
            <Level level={profile.level} xp={profile.xp} nextXp={profile.nextXp} className="lvl" />
            <div className="reward-box">
                <Typography variant="h7" className="reward-txt" color="white">
                    <NumberFormat
                        prefix="+"
                        suffix={' SERG'}
                        value={result.reward}
                        displayType="text"
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                    />
                </Typography>
                <img src={coins} alt={t('pages:battle.battleResult.reward')} />
            </div>
        </>
    );

    const lose = () => (
        <>
            <Typography variant="h3" align="center" color="primary.light">
                {t('pages:battle.battleResult.youLose')}
            </Typography>
            <Typography variant="h7" color="white" align="center" className="detail-subtitle">
                {t('pages:battle.battleResult.guardianFoughtHardButUnfortunatelyLost')}
            </Typography>
            <DefeatWrapper>
                <GuardianCard
                    className="swiper-slide"
                    type="default"
                    {...result.guardian}
                    size="lg"
                    checkbox={false}
                    titleDisabled
                    key={result.guardian.id}
                />
            </DefeatWrapper>
        </>
    );

    const close = () => {
        setStartCloseTimer(false);
        setInitialCloseTime(10);
        // setBattleAnimationEnded(false);

        if (battleCount > 5) {
            dispatch(resetBattle());
        } else {
            dispatch(resetEnemy());
            dispatch(setStatus({ status: 'preparing' }));
        }
    };

    useEffect(() => {
        /*if (startCloseTimer) {
            if (initialCloseTime > 0) {
                setTimeout(() => {
                    // console.log('startTime, ', initialCloseTime);
                    setInitialCloseTime(initialCloseTime - 1);
                }, 1000);
            }

            if (initialCloseTime === 0 && startCloseTimer) {
                // console.log('done');
                close();
            }
        }*/
    }, [initialCloseTime, startCloseTimer]);

    useEffect(() => {
        if (status === 'finished') {
            dispatch(setCards({ count: battleCount + 1 }));
            setStartCloseTimer(true);
        }
    }, [status]);

    return (
        <Dialog
            open={status === 'fighting' || status === 'finished'}
            className={className}
            fullWidth
            PaperProps={{
                className: 'dialog-paper',
            }}
            maxWidth="lg"
            PaperComponent={Box}
        >
            {status === 'fighting' && fighting()}
            {status === 'finished' && (result.success ? win() : lose())}
            {status !== 'fighting' && (
                <Button variant="outlined" className="close-btn" onClick={close}>
                    Close
                </Button>
            )}
        </Dialog>
    );
};

export default styled(Component)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    .lvl {
        width: 250px;
    }

    .close-btn {
        align-self: center;
    }

    .detail-subtitle {
        margin-top: ${({ theme }) => theme.spacing(4)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    .close-progress {
        margin: ${({ theme }) => theme.spacing(3, 0)};
        border-radius: 50px;
        height: 10px;
        width: 33%;
    }

    .dialog-paper {
        align-items: center;
    }

    .reward-box {
        margin: ${({ theme }) => theme.spacing(2, 0)};
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .reward-txt {
            margin-right: ${({ theme }) => theme.spacing(1)};
        }
    }

    .battle-animation {
        width: 50%;
        height: 50%;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        .battle-animation {
            width: 100%;
            height: 100%;
        }
    }
`;
