import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

interface Props {
    className?: string;
    icon?: string;
    title?: string | number;
}

const Component: FC<Props> = ({ className, icon, title }) => {
    return (
        <div className={className}>
            {icon && <img src={icon} alt="Fight Points" className="icon" />}
            <Typography color="white" variant="body1" fontWeight="bold">
                <NumberFormat value={title} displayType="text" thousandSeparator fixedDecimalScale />
            </Typography>
        </div>
    );
};

export default styled(Component)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 50vh;
    background-color: #00000066;
    padding: ${({ theme }) => theme.spacing(0, 1)};
    height: 28px;
    width: fit-content;

    .icon {
        height: 15px;
        margin-right: ${({ theme }) => theme.spacing(1)};
    }
`;
