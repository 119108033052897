import React, { FC, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import styled from 'styled-components';
import { CardWrapper, Divider, GuardianCard, SectionHeader } from '../../../components';

import marketBack from '../../../assets/backgrounds/market/background-market-carousel.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { loadTopGuardians } from '../../../redux/actions/market';
import { CardTooltip } from '../../../components/common/cards';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const cards = useSelector((state: RootState) => state.market.topGuardians);
    const authKey = useSelector((state: RootState) => state.app.authKey);

    const renderCard = (item: Seiren.GuardianCard) => (
        <SwiperSlide key={item.id}>
            <CardWrapper {...item} key={item.id} priceOnly={!authKey}>
                <CardTooltip {...item}>
                    <GuardianCard {...item} size="lg" checkbox={false} />
                </CardTooltip>
            </CardWrapper>
        </SwiperSlide>
    );

    useEffect(() => {
        dispatch(loadTopGuardians());
    }, []);

    return (
        <div className={className}>
            <SectionHeader title={t('pages:home.layout.market.market2')} />
            <Typography className="market-subtitle" color="secondary.contrastText" gutterBottom>
                {t('pages:home.layout.market.newlyUpdatedGuardianInTheMarket')}
            </Typography>
            <Divider alignContent="center" />
            <Swiper slidesPerView="auto" spaceBetween={100} centeredSlides className="market-carousel">
                {cards.map(renderCard)}
            </Swiper>
            <Button variant="confirm" className="market-btn" component={Link} to={'/market'}>
                <Typography variant="buttonLg" color="white">
                    {t('pages:home.layout.market.market')}
                </Typography>
            </Button>
        </div>
    );
};

export default styled(Component)`
    display: flex;
    align-items: center;
    flex-direction: column;

    .market-subtitle {
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }

    .market-carousel {
        width: 100%;
        padding-top: ${({ theme }) => theme.spacing(3)};
        background-image: url(${marketBack});
        background-repeat: no-repeat;
        background-position: center;
    }

    .market-btn {
        margin-top: ${({ theme }) => theme.spacing(6)};
    }

    .swiper-wrapper {
        box-sizing: inherit;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        width: 252px;
    }
`;
