import React, { FC } from 'react';
import { Container, Typography } from '@mui/material';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Profile } from './layout';
import { Navigation } from './components';

import playerBack from '../../assets/backgrounds/background-2/background_02@2x.jpg';
import Guardians from './guardians/Guardians';
import Bonuses from './bonuses/Bonuses';
import Deposit from './deposit/Deposit';
import Withdraw from './withdraw/Withdraw';
import History from './history/History';
import { useTranslation } from 'react-i18next';
import { Divider } from '../../components';

interface Props {
    className?: string;
}

const Player: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={`${className} background`}>
            <Container>
                <Divider />
                <Typography variant="h3" textAlign="center" color="primary" pt={3} fontWeight={700}>
                    {t('pages:player.player.player')}
                </Typography>
                <Profile />
                <Navigation className="player-nav" />
                <Switch>
                    <Route path="/player/guardians" exact>
                        <Guardians />
                    </Route>
                    <Route path="/player/bonuses" exact>
                        <Bonuses />
                    </Route>
                    <Route path="/player/deposit" exact>
                        <Deposit />
                    </Route>
                    <Route path="/player/withdraw" exact>
                        <Withdraw />
                    </Route>
                    <Route path="/player/history" exact>
                        <History />
                    </Route>
                    <Redirect to="/player/guardians" />
                </Switch>
            </Container>
        </div>
    );
};

export default styled(Player)`
    background-image: url(${playerBack});
    padding-top: ${({ theme }) => theme.spacing(21)};
    padding-bottom: ${({ theme }) => theme.spacing(5)};

    .player-nav {
        padding: ${({ theme }) => theme.spacing(4, 0)};
    }
`;
