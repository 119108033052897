import React, { FC } from 'react';
import { styled } from '@mui/material';
import { ReactComponent as Versus } from '../../../assets/battle/vs.svg';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    return <Versus className={className} />;
};

export default styled(Component)``;
