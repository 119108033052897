import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { Grid, Skeleton } from '@mui/material';

type Props = {
    className?: string;
    withButtons?: boolean;
    withTitle?: boolean;
    withTop?: boolean;
    size?: 'md' | 'lg';
};

const Component: FC<Props> = ({ className, withButtons, withTitle, withTop, size }) => {
    return (
        <div className={className}>
            {withTop && (
                <div className="card-top">
                    <Skeleton variant="rectangular" className="info" width={30} height={20} />
                    <Skeleton variant="rectangular" className="info" width={30} height={20} />
                </div>
            )}
            <Skeleton
                variant="rectangular"
                width={size === 'lg' ? 250 : 142}
                height={size === 'lg' ? 360 : 180}
                className="card"
            />
            {withTitle && <Skeleton variant="text" width="80%" className="title" />}
            {withButtons && (
                <>
                    <Grid container spacing={1}>
                        <Grid item md xs>
                            <Skeleton variant="rectangular" width="100%" height={36} className="button" />
                        </Grid>
                        <Grid item md xs>
                            <Skeleton variant="rectangular" width="100%" height={36} className="button" />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default styled(Component)`
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
    }

    .card-top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: ${({ theme }) => theme.spacing(1)};
    }

    .button {
        border-radius: ${({ theme }) => theme.spacing(0.5)};
    }

    .title {
        margin: ${({ theme }) => theme.spacing(1, 0)};
    }
`;
