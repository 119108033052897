import React, { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import noCard from '../../../assets/card/noCard/No_Card_Selet_Enermy_Img_252px@2x.png';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props extends Seiren.GuardianCard {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <img src={noCard} alt={t('components:common.cards.autoSelectedCard.plus')} className="no-card-img" />
            <Typography color="white" variant="body2" textAlign="center">
                {t('components:common.cards.autoSelectedCard.selectTheGuardianTheEnemyIsAutomaticallySelected')}
            </Typography>
        </div>
    );
};

export default styled(Component)`
    padding: ${({ theme }) => theme.spacing(6)};
    width: 252px;
    height: 364px;
    border: 1px solid ${({ theme }) => theme.palette.common.white};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .no-card-img {
        width: 68px;
        margin: ${({ theme }) => theme.spacing(0, 0, 4, 0)};
    }
`;
