import React, { FC } from 'react';
import { Skeleton, Typography } from '@mui/material';
import styled from 'styled-components';

interface Props {
    className?: string;
    rank: number;
}

const Component: FC<Props> = ({ className, rank }) => {
    switch (rank) {
        case 1:
            return (
                <div className={className}>
                    <Skeleton variant="circular" animation="wave" width={176} height={176} />
                    <Typography variant="h3" py={1}>
                        <Skeleton variant="text" animation="wave" width={20} />
                    </Typography>
                    <Skeleton variant="text" animation="wave" width={144} />
                    <Typography pt={1} variant="h6">
                        <Skeleton variant="text" animation="wave" width={176} />
                    </Typography>
                    <Typography pt={1} variant="h7">
                        <Skeleton variant="text" animation="wave" width={40} />
                    </Typography>
                    <Typography variant="h7" fontSize={14}>
                        <Skeleton variant="text" animation="wave" width={40} />
                    </Typography>
                </div>
            );
        case 2:
        case 3:
            return (
                <div className={className}>
                    <Skeleton variant="circular" animation="wave" width={126} height={126} />
                    <Typography variant="h4" pt={1}>
                        <Skeleton variant="text" animation="wave" width={20} />
                    </Typography>
                    <Typography pt={1} variant="h7">
                        <Skeleton variant="text" animation="wave" width={140} />
                    </Typography>
                    <Typography pt={1} variant="h7">
                        <Skeleton variant="text" animation="wave" width={40} />
                    </Typography>
                    <Typography variant="h7" fontSize={14}>
                        <Skeleton variant="text" animation="wave" width={40} />
                    </Typography>
                </div>
            );
        default:
            return null;
    }
};

export default styled(Component)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
