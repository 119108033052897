import { Seiren } from '../types/Seiren';
import axios from 'axios';
import _ from 'lodash';

type Params = {
    bonus: Seiren.Bonus;
    burn: Seiren.Bonus;
    token: string;
};

export default async function fetch(params: Params) {
    try {
        const { data } = await axios.post(
            `${axios.defaults.baseURL}/enhance`,
            {
                bonus: params.bonus.id,
                burn: params.burn.id,
            },
            {
                headers: { Authentication: params.token },
            },
        );

        if (!data?.error) {
            return _.mapKeys(data.data, (v: any, k: string) => _.camelCase(k)) as Seiren.Bonus;
        } else {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}
