import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { resetRoll } from '../../../redux/actions/player';
import { useTranslation } from 'react-i18next';
import { rollBonus, useBonuses, useSettings } from '../../../api';
import { Dialog } from '../../../components';
import { useMutation } from 'react-query';

interface Props {
    className?: string;
    onClose?: any;
}

const Component: FC<Props> = ({ className, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const status = useSelector((state: RootState) => state.player.status);
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const { refetch: refetchBonuses } = useBonuses(authKey, { page: 1 });
    const { data: settings } = useSettings();

    const { data: newCard, mutate: roll, isError, isLoading } = useMutation(rollBonus);

    const start = () => {
        roll(authKey);
    };

    const close = () => {
        dispatch(resetRoll());
        refetchBonuses();

        if (!isLoading && newCard && onClose) {
            onClose();
        }
    };

    const dialog = () => (
        <>
            <Typography marginBottom={3}>
                {t('pages:player.bonuses.bonusRoll.youNeed')}{' '}
                <Typography component="span" fontWeight={600}>
                    {settings ? settings.bonusFee! : 0} {t('pages:player.bonuses.bonusRoll.serg')}
                </Typography>{' '}
                {t('pages:player.bonuses.bonusRoll.toRollANewBonusCard')}
            </Typography>
            <Button variant="contained" onClick={start}>
                {t('pages:player.bonuses.bonusRoll.roll')}
            </Button>
        </>
    );

    return (
        <Dialog
            title={t('pages:player.bonuses.bonusRoll.rollBonus')}
            description={t('pages:player.bonuses.bonusRoll.getANewBonusNow')}
            onClose={close}
            open={status === 'roll'}
            className={className}
        >
            {!isLoading && !newCard && !isError && dialog()}
            {isLoading && <Typography>{t('pages:player.bonuses.bonusRoll.rollingANewBonus')}</Typography>}
            {!isLoading && newCard && (
                <Typography>{t('pages:player.bonuses.bonusRoll.congratulationsYouRolledANewBonus')}</Typography>
            )}
            {!isLoading && isError && (
                <Typography>{t('pages:player.bonuses.bonusRoll.unfortunatelySomethingGoesWrong')}</Typography>
            )}
        </Dialog>
    );
};

export default styled(Component)``;
