import React, { FC, useEffect, useState } from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    Divider as MUIDivider,
    Drawer,
    FormControl,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    SvgIcon,
    Tab,
    Tabs,
    Theme,
    Toolbar,
    Tooltip,
    useMediaQuery,
    useScrollTrigger,
} from '@mui/material';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import logo from '../assets/navigation/Seiren_Logo_114px.svg';
import styled from 'styled-components';
import { PlayerBar } from '../components';
import CloseIcon from '@mui/icons-material/Close';
import { Menu } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../redux/Store';
import { logoutUser, setLanguage } from '../redux/actions/app';
import { useTranslation } from 'react-i18next';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { claimCard } from '../redux/actions/player';
import Claim from './Claim';
import { ReactComponent as ExitIcon } from '../assets/exit.svg';

interface Props {
    className?: string;
    window?: any;
}

const StyledDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        width: 100%;
        padding: ${({ theme }) => theme.spacing(1, 0)};
        background: ${({ theme }) => theme.palette.background.default};
        color: ${({ theme }) => theme.palette.common.white};
    }
`;

const Component: FC<Props> = ({ className, window }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const language = useSelector((state: RootState) => state.app.language);
    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const history = useHistory();
    const [isDrawerMenuOpened, setIsDrawerMenuOpened] = useState(false);
    const location = useLocation();
    const [[, currentRoot]] = location.pathname.matchAll(/^(\/[^/]*)/g);
    const status = useSelector((state: RootState) => state.player.status);
    const [currLang, setCurrLang] = useState(language);

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    const goTo = (path: string) => {
        setIsDrawerMenuOpened(false);
        history.push(path);
    };

    const logout = () => {
        dispatch(logoutUser());
    };

    const onClaim = () => {
        dispatch(claimCard());
    };

    const toggleLang = () => {
        if (currLang === 'en') {
            setCurrLang('ko');
        } else {
            setCurrLang('en');
        }
    };

    useEffect(() => {
        i18n.changeLanguage(currLang);
        dispatch(setLanguage({ language: currLang }));
    }, [currLang]);

    return (
        <AppBar className={className} color="transparent" elevation={0}>
            <StyledDrawer className={className} anchor="top" open={isDrawerMenuOpened}>
                <Container>
                    <div className="mobile-nav">
                        <NavLink to="/">
                            <img src={logo} alt={t('app:navigation.logo')} style={{ height: 75 }} />
                        </NavLink>
                        <Button color="inherit" size="large" onClick={() => setIsDrawerMenuOpened(false)}>
                            <CloseIcon />
                        </Button>
                    </div>
                </Container>
                <List>
                    <ListItem button onClick={() => goTo('/player')}>
                        <ListItemText primary={t('app:navigation.player')} />
                    </ListItem>
                    {/*<ListItem button onClick={() => goTo('/market')}>*/}
                    {/*    <ListItemText primary="Market" />*/}
                    {/*</ListItem>*/}
                    <ListItem button onClick={() => goTo('/battle')}>
                        <ListItemText primary={t('app:navigation.battle')} />
                    </ListItem>
                    {/*<ListItem button onClick={() => goTo('/swap')}>*/}
                    {/*    <ListItemText primary="Swap" />*/}
                    {/*</ListItem>*/}
                    <ListItem button onClick={() => goTo('/ranking')}>
                        <ListItemText primary={t('app:navigation.ranking')} />
                    </ListItem>
                    <ListItem button component="a" target="_blank" href="https://aok.gitbook.io/what-is-seiren-war/">
                        <ListItemText primary={t('app:navigation.howToPlay')} />
                    </ListItem>
                </List>
                <MUIDivider light />
                <List>
                    {authKey && (
                        <ListItem button onClick={logout}>
                            <ListItemText primary={t('app:navigation.logOut')} />
                        </ListItem>
                    )}
                    <ListItem>
                        <FormControl fullWidth size="small" color="primary">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currLang}
                                onChange={(e) => setCurrLang(e.target.value as string)}
                            >
                                <MenuItem value="en">EN</MenuItem>
                                <MenuItem value="ko">KO</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                </List>
                <Container>
                    {authKey ? (
                        <Box display="flex">
                            <PlayerBar fullWidth className="mobile-balance" />
                            <Button color="primary" onClick={onClaim}>
                                <MoveDownIcon />
                            </Button>
                        </Box>
                    ) : (
                        <Box display="flex">
                            <Button onClick={() => goTo('/login')} fullWidth variant="contained">
                                {t('app:navigation.connectToWallet')}
                            </Button>
                        </Box>
                    )}
                </Container>
            </StyledDrawer>
            {!hidden ? (
                <div className="desktop-nav">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <NavLink to="/" className="logo">
                                <img
                                    src={logo}
                                    alt={t('app:navigation.logo')}
                                    className="logo-img"
                                    style={{
                                        width: trigger ? '75px' : '114px',
                                    }}
                                />
                            </NavLink>
                            <Toolbar
                                disableGutters
                                sx={{
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Tabs
                                    value={currentRoot === '/login' || currentRoot === '/' ? false : currentRoot}
                                    centered
                                    className="nav"
                                >
                                    <Tab
                                        label={t('app:navigation.player')}
                                        value="/player"
                                        className="tab nav-tab"
                                        component={Link}
                                        to="/player"
                                    />
                                    <Tab
                                        label={t('app:navigation.battle')}
                                        value="/battle"
                                        className="tab nav-tab"
                                        component={Link}
                                        to="/battle"
                                    />
                                    <Tab
                                        label={t('app:navigation.ranking')}
                                        value="/ranking"
                                        className="tab nav-tab"
                                        component={Link}
                                        to="/ranking"
                                    />
                                    <Tab
                                        label={t('app:navigation.howToPlay')}
                                        target="_blank"
                                        value="/how"
                                        className="tab nav-tab"
                                        href="https://aok.gitbook.io/what-is-seiren-war/"
                                    />
                                </Tabs>
                                <div>
                                    {!authKey ? (
                                        <Button onClick={() => goTo('/login')} variant="contained">
                                            {t('app:navigation.connectToWallet')}
                                        </Button>
                                    ) : (
                                        <>
                                            <IconButton sx={{ marginRight: 1 }} onClick={onClaim}>
                                                <MoveDownIcon />
                                            </IconButton>
                                            <PlayerBar />
                                        </>
                                    )}
                                    <Button variant="outlined2" sx={{ padding: 0 }} onClick={toggleLang}>
                                        {currLang}
                                    </Button>
                                    {authKey && (
                                        <>
                                            <Tooltip title={t('app:navigation.logOut')}>
                                                <Button variant="contained" sx={{ padding: 0 }} onClick={logout}>
                                                    <SvgIcon fontSize="large">
                                                        <ExitIcon width={24} height={24} />
                                                    </SvgIcon>
                                                </Button>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            </Toolbar>
                        </Toolbar>
                    </Container>
                </div>
            ) : (
                <Container>
                    <div className="mobile-nav">
                        <NavLink to="/">
                            <img src={logo} alt={t('app:navigation.logo')} style={{ height: 75 }} />
                        </NavLink>
                        <Button color="inherit" size="large" onClick={() => setIsDrawerMenuOpened(true)}>
                            <Menu />
                        </Button>
                    </div>
                </Container>
            )}
            {status === 'claim' && <Claim />}
        </AppBar>
    );
};

export default styled(Component)`
    padding: ${({ theme }) => theme.spacing(1, 0)};
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    .mobile-nav {
        color: ${({ theme }) => theme.palette.common.white};
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-item {
        padding: ${({ theme }) => theme.spacing(1.5, 2)};
        text-decoration: none;
        transition: 0.1s ease;

        &:hover {
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }

    .nav-active {
        text-decoration: none;
        position: relative;
        color: ${({ theme }) => theme.palette.primary.main};
        //border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};
    }

    .nav-active:after {
        position: absolute;
        content: '';
        height: 4px;
        bottom: -${({ theme }) => theme.spacing(3)};
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 100%;
        background: ${({ theme }) => theme.palette.primary.main};
    }

    .logo {
        margin-right: ${({ theme }) => theme.spacing(4)};
        transition: 0.1s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    .logo-img {
        transition: all 0.2s;
        width: 114px;
        height: 100%;
    }

    .nav-tab {
        margin: ${({ theme }) => theme.spacing(0, 0.5)};
        border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    }

    .desktop-nav {
        position: relative;
        padding: ${({ theme }) => theme.spacing(1, 0)};

        /*&:after {
            content: ' ';
            position: absolute;
            top: calc(50% - 1.5px);
            background: transparent;
            border-width: 1px;
            border-style: solid;
            border-color: ${({ theme }) => theme.palette.primary.main};
            width: 100px;
            height: 3px;
            border-left: none;
            border-right: none;
        }

        &:before {
            content: ' ';
            position: absolute;
            top: calc(50% - 1.5px);
            right: 0;
            background: transparent;
            border-width: 1px;
            border-style: solid;
            border-color: ${({ theme }) => theme.palette.primary.main};
            width: 100px;
            height: 3px;
            border-left: none;
            border-right: none;
        }*/
    }
`;
