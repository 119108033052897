import React, { FC, useEffect, useState } from 'react';
import {
    Container,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import styled from 'styled-components';
import { Header, TopPlayers } from './layout';
import { RankingListItem } from '../ranking/components/';
import Divider from '../../components/common/Divider';
import rankingBack from '../../assets/backgrounds/background-2/background_02@2x.jpg';
import { RootState } from '../../redux/Store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../types/Seiren';
import { useRanking } from '../../api';
import { useSnackbar } from 'notistack';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const [page, setPage] = useState(1);
    const { error, status, data: players, isLoading } = useRanking(authKey, { page });
    const { enqueueSnackbar } = useSnackbar();

    const rankingSkeleton = new Array(10).fill(null);
    const skeleton = rankingSkeleton.map((item, idx) => (
        <TableRow key={idx}>
            <TableCell align="left">
                <Typography>
                    <Skeleton animation="wave" variant="circular" height={60} width={60} />
                </Typography>
            </TableCell>
            <TableCell align="left">
                <div>
                    <Typography align="center" display="flex" alignItems="center">
                        <Skeleton className="margin-right" animation="wave" variant="circular" height={40} width={40} />
                        <Skeleton variant="text" animation="wave" width={400} />
                    </Typography>
                </div>
            </TableCell>
            <TableCell align="left">
                <Skeleton variant="text" animation="wave" />
            </TableCell>
            <TableCell align="left">
                <Skeleton variant="text" animation="wave" />
            </TableCell>
        </TableRow>
    ));

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        if (status === 'error') {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    }, [status]);

    return (
        <div className={`${className} background`}>
            <Container>
                <Header />
                <TopPlayers />
                <TableContainer>
                    <Table aria-label={t('pages:ranking.ranking.simpleTable')} className="table">
                        <TableHead className="header">
                            <TableRow>
                                <TableCell className="table-head" align="left">
                                    <Typography align="left" color="white">
                                        {t('pages:ranking.ranking.position')}
                                    </Typography>
                                </TableCell>
                                <TableCell className="table-head" align="left">
                                    <Typography align="left" color="white">
                                        {t('pages:ranking.ranking.player')}
                                    </Typography>
                                </TableCell>
                                <TableCell className="table-head" align="left">
                                    <Typography align="center" color="white">
                                        {t('pages:ranking.ranking.level')}
                                    </Typography>
                                </TableCell>
                                <TableCell className="table-head" align="left">
                                    <Typography align="center" color="white">
                                        {t('pages:ranking.ranking.xp')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {players && !isLoading
                                ? players.list.map((item: Seiren.Player) => {
                                      if (item.rank && item.rank > 3) {
                                          return <RankingListItem key={item.rank} {...item} />;
                                      } else return;
                                  })
                                : skeleton}
                        </TableBody>
                    </Table>
                </TableContainer>
                {players && (
                    <div className="page-box">
                        <Pagination
                            count={players.pagination.pages}
                            page={page}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                            size="large"
                            disabled={isLoading}
                        />
                    </div>
                )}
                <Divider type="bottom" className="divider-bottom" />
            </Container>
        </div>
    );
};

export default styled(Component)`
    background-image: url('${rankingBack}');
    padding-top: ${({ theme }) => theme.spacing(21)};

    .table {
        margin: auto;
        width: 90%;
    }

    .header {
        position: relative;
    }

    .table-head {
        border-bottom: 3px double ${({ theme }) => theme.palette.primary.light};
    }

    .divider-bottom {
        margin-top: ${({ theme }) => theme.spacing(5)};
    }

    .margin-right {
        margin-right: ${({ theme }) => theme.spacing(2)};
    }

    .page-box {
        display: flex;
        justify-content: center;
        margin-top: ${({ theme }) => theme.spacing(5)};
    }

    //.top-players {
    //    display: flex;
    //    align-items: center;
    //    justify-content: space-evenly;
    //}
`;
