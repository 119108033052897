import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    title: string;
    chapter?: string;
    desc: string;
    img: string;
}

const Component: FC<Props> = ({ className, title, chapter, desc, img }) => {
    const { t } = useTranslation();
    return (
        <Grid container item className={className} alignItems="center" spacing={8}>
            <Grid item xs={12} md={6}>
                <img src={img} alt={t('pages:home.components.roadMapItem.roadMap')} className="img" />
            </Grid>
            <Grid item xs={12} md>
                <Typography className="chapter-txt" variant="h7" component="h2" color="primary.light" gutterBottom>
                    {chapter}
                </Typography>
                <Typography variant="h4" className="title-txt" color="white">
                    {title}
                </Typography>
                <Typography color="secondary.contrastText">{desc}</Typography>
            </Grid>
        </Grid>
    );
};

export default styled(Component)`
    .chapter-txt {
        text-transform: uppercase;
    }

    .title-txt {
        text-transform: uppercase;
        padding-bottom: ${({ theme }) => theme.spacing(4)};
    }

    .img {
        display: block;
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
`;
