import axios from 'axios';

type Params = { token: string; address?: string; amount?: number; lockup?: string; withdrawCurrency?: number };

export default async function (params: Params) {
    try {
        const { data } = await axios.post(
            `${axios.defaults.baseURL}/user/withdraw`,
            {
                address: params.address,
                amount: params.amount,
            },
            { headers: { Authentication: params.token } },
        );

        if (!data?.error) {
            return data.data;
        }
        if (data?.error) {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}
