import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Button, Grid, Typography } from '@mui/material';

import octopus from '../../../assets/caption/Login_Object_Octopus_190px.png';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    // title?: string;
    // info?:string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <div className="caption-box">
                <img src={octopus} alt={t('pages:login.layout.caption.octopus')} className="caption-img" />
                <div className="caption-container">
                    <Typography color="primary.light" align="center" gutterBottom fontWeight="600">
                        {t('pages:login.layout.caption.caption')}
                    </Typography>
                    <Typography color="gray" align="center" gutterBottom>
                        {t('pages:login.layout.caption.weNeedAnAokWalletForTheDesiredUseOfSeirenWarPlease')}
                    </Typography>
                    <Grid container spacing={2} className="btn-grid">
                        <Grid item md={6} xs={12}>
                            <Box display="flex">
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() =>
                                        window.open(
                                            'https://play.google.com/store/apps/details?id=com.aokey&hl=uk&gl=US',
                                            '_blank',
                                        )
                                    }
                                >
                                    {t('pages:login.layout.caption.android')}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box display="flex">
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() =>
                                        window.open('https://apps.apple.com/us/app/aok-wallet/id1514809987', '_blank')
                                    }
                                >
                                    iOS
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default styled(Component)`
    background-color: #0009;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(2)};

    .btn-grid {
        padding: ${({ theme }) => theme.spacing(2, 4)};
    }

    .btn {
        margin: 0;
    }

    .caption-box {
        width: 100%;
        border-radius: 4px;
        padding: ${({ theme }) => theme.spacing(2)};
        border: 1px solid ${({ theme }) => theme.palette.primary.light};
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .caption-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 168px;
        max-width: 540px;
    }

    .caption-img {
        position: absolute;
        left: ${({ theme }) => theme.spacing(4)};
        bottom: ${({ theme }) => theme.spacing(3)};
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        .caption-img {
            opacity: 0.3;
        }
    }
`;
