import { Dispatch } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { enqueueSnackbar } from './notification';
import { Seiren } from '../../types/Seiren';

export const SET_AUTH_KEY = 'app/SET_AUTH_KEY';
export const SET_PLAYER = 'app/SET_PLAYER';
export const RESET_APP = 'app/RESET_APP';
export const SET_DESTROY_ADDRESS = 'app/SET_DESTROY_ADDRESS';
export const SET_ONBOARDING = 'app/SET_ONBOARDING';
export const SET_LANGUAGE = 'app/SET_LANGUAGE';

const API_URL = 'https://api.seirenwar.com/v1';

export const setAuthKey = (payload: { authKey?: string }) => ({
    type: SET_AUTH_KEY,
    payload,
});

export const setPlayer = (payload: { player: Seiren.Player }) => ({
    type: SET_PLAYER,
    payload,
});

export const setOnboarding = (payload: { onboarding: boolean }) => ({
    type: SET_ONBOARDING,
    payload,
});

export const setDestroyAddress = (payload: { destroyAddress: string }) => ({
    type: SET_DESTROY_ADDRESS,
    payload,
});

export const resetApp = () => ({
    type: RESET_APP,
});

export const setLanguage = (payload: { language: 'en' | 'ko' }) => ({
    type: SET_LANGUAGE,
    payload,
});

export const loginUser = (payload: { address: string; message: string; signature: string }) => {
    return (dispatch: Dispatch) => {
        axios.post(`${API_URL}/auth`, payload).then(
            ({
                data: {
                    data: { token },
                },
            }: AxiosResponse<{ data: { token: string } }>) => {
                dispatch(setAuthKey({ authKey: token }));

                dispatch(
                    enqueueSnackbar({
                        message: 'You has been successfully logged in!',
                        options: {
                            variant: 'success',
                        },
                    }),
                );
            },
        );
    };
};

export const logoutUser = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetApp());
    };
};

export const loadDestroyAddress = () => {
    return (dispatch: Dispatch) => {
        axios.get(`${axios.defaults.baseURL}/system/destroy`).then((response) => {
            if (!response.data?.error) {
                dispatch(setDestroyAddress({ destroyAddress: response.data?.data.address }));
            } else {
                dispatch(
                    enqueueSnackbar({
                        message: response.data?.error,
                        options: {
                            variant: 'error',
                        },
                    }),
                );
            }
        });
    };
};
