import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Divider, FormControlLabel, Grid, Rating, Typography } from '@mui/material';

import elBlue from '../../../assets/elements/blue.png';
import elGreen from '../../../assets/elements/green.png';
import elRed from '../../../assets/elements/red.png';
import elYellow from '../../../assets/elements/yellow.png';
import { SecondaryInput } from '../../../components/common';

import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { filterBy } from '../../../redux/actions/market';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const element = useSelector((state: RootState) => state.market.filters.element);
    const level = useSelector((state: RootState) => state.market.filters.level);

    const setElement = (checked: boolean, newElement: Seiren.CardElement) => {
        dispatch(filterBy({ filters: { element: checked ? newElement : undefined } }));
    };

    const setLevel = (newLevel: number | null) => {
        dispatch(filterBy({ filters: { level: newLevel ? newLevel : undefined } }));
    };

    return (
        <div className={className}>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <Typography color="secondary.contrastText" className="filter-text">
                        {t('pages:market.layout.filter.element')}
                    </Typography>
                    <Grid item container>
                        <Grid item md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={element === 'red'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setElement(e.target.checked, 'fire')
                                        }
                                    />
                                }
                                label={
                                    <img
                                        className="elements"
                                        src={elRed}
                                        alt={t('pages:market.layout.filter.redElement')}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={element === 'green'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setElement(e.target.checked, 'earth')
                                        }
                                    />
                                }
                                label={
                                    <img
                                        className="elements"
                                        src={elGreen}
                                        alt={t('pages:market.layout.filter.greenElement')}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={element === 'blue'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setElement(e.target.checked, 'water')
                                        }
                                    />
                                }
                                label={
                                    <img
                                        className="elements"
                                        src={elBlue}
                                        alt={t('pages:market.layout.filter.blueElement')}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={element === 'yellow'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setElement(e.target.checked, 'lightning')
                                        }
                                    />
                                }
                                label={
                                    <img
                                        className="elements"
                                        src={elYellow}
                                        alt={t('pages:market.layout.filter.yellowElement')}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md>
                    <Typography color="secondary.contrastText" className="filter-text">
                        {t('pages:market.layout.filter.level')}
                    </Typography>
                    <Rating
                        size="large"
                        defaultValue={0}
                        value={level}
                        onChange={(e: React.SyntheticEvent, value: number | null) => setLevel(value)}
                    />
                </Grid>
                <Grid item md={3}>
                    <Typography color="secondary.contrastText" className="filter-text">
                        {t('pages:market.layout.filter.sortByPrice')}
                    </Typography>
                    <Grid item container spacing={2}>
                        <Grid item md xs>
                            <SecondaryInput placeholder={t('pages:market.layout.filter.min')} />
                        </Grid>
                        <Grid item md xs>
                            <SecondaryInput placeholder={t('pages:market.layout.filter.max')} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Typography color="secondary.contrastText" className="filter-text">
                        {t('pages:market.layout.filter.searchWord')}
                    </Typography>
                    <div className="search-field">
                        <SecondaryInput fullWidth placeholder={t('pages:market.layout.filter.addressNft')} />
                        <Button variant="contained" size="small" className="search-button">
                            <SearchIcon fontSize="small" />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Divider className="divider" />
        </div>
    );
};

export default styled(Component)`
    .filter-text {
        margin-bottom: ${({ theme }) => theme.spacing(1)};
    }

    .elements {
        height: 40px;
    }

    .search-button {
        min-width: 40px;
        min-height: 40px;
        margin-left: ${({ theme }) => theme.spacing(2)};
    }

    .search-field {
        display: flex;
    }

    .divider {
        background: ${({ theme }) => theme.palette.primary.main};
        margin-top: ${({ theme }) => theme.spacing(1.5)};
        height: 2px;
    }
`;
