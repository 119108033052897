import { RESET_APP, SET_AUTH_KEY, SET_DESTROY_ADDRESS, SET_ONBOARDING, SET_PLAYER, SET_LANGUAGE } from '../actions/app';
import { AnyAction } from 'redux';
import { Seiren } from '../../types/Seiren';

interface ReducerState {
    player?: Seiren.Player;
    authKey?: string;
    destroyAddress?: string;
    onboarding: boolean;
    language: 'en' | 'ko';
}

const initialState: ReducerState = {
    player: undefined,
    authKey: undefined,
    destroyAddress: undefined,
    onboarding: false,
    language: 'en',
};

const reducer = (state: ReducerState = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_AUTH_KEY: {
            return { ...state, ...action.payload };
        }
        case SET_PLAYER: {
            return { ...state, ...action.payload };
        }
        case SET_DESTROY_ADDRESS: {
            return { ...state, ...action.payload };
        }
        case SET_ONBOARDING: {
            return { ...state, ...action.payload };
        }
        case RESET_APP: {
            return initialState;
        }
        case SET_LANGUAGE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
