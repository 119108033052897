import React, { FC } from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import { Header, Roadmap, Story } from './layout';

import homeBack from '../../assets/backgrounds/background-1/background_01@2x.jpg';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    return (
        <div className={`${className} background`}>
            <Header />
            <Container>
                <Story />
            </Container>
            {/*<Market />*/}
            <Container>
                <Roadmap />
            </Container>
        </div>
    );
};

export default styled(Component)`
    background-image: url(${homeBack});
`;
