import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './app';
import store, { persistor } from './redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import './i18n/i18n';

axios.defaults.baseURL = 'https://api.seirenwar.com/v1';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10000,
            cacheTime: 0,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider>
                    <QueryClientProvider client={queryClient}>
                        <React.Suspense fallback="Loading...">
                            <App />
                        </React.Suspense>
                    </QueryClientProvider>
                </SnackbarProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
