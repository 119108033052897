import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../redux/Store';
import { claim, useSettings } from '../api';
import { resetClaim } from '../redux/actions/player';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../components';
import { useMutation } from 'react-query';

interface Props {
    className?: string;
    onClose?: any;
}

const Component: FC<Props> = ({ className, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const status = useSelector((state: RootState) => state.player.status);
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const { data: settings } = useSettings();

    const { data: claimed, mutate: makeClaim, isError, isLoading } = useMutation(claim);

    const start = () => {
        makeClaim(authKey);
    };

    const close = () => {
        dispatch(resetClaim());

        if (!isLoading && claimed && onClose) {
            onClose();
        }
    };

    const dialog = () => (
        <>
            <Typography marginBottom={3}>
                <Typography component="span" fontWeight={600}>
                    {settings ? Math.round((1 - settings!.earlyClaim!) * 100) : 0}%
                </Typography>{' '}
                {t('app:claim.feeAppliesForCurrentClaims')}
            </Typography>
            <Button variant="contained" onClick={start}>
                {t('app:claim.claim')}
            </Button>
        </>
    );

    return (
        <Dialog
            title={t('app:claim.claimAReward')}
            description={t('app:claim.getAClaimNow')}
            onClose={close}
            open={status === 'claim'}
            className={className}
        >
            {!isLoading && !claimed && !isError && dialog()}
            {isLoading && <Typography>{t('app:claim.claiming')}</Typography>}
            {!isLoading && claimed && <Typography>{t('app:claim.claimingHasGoneSuccessfully')}</Typography>}
            {!isLoading && isError && <Typography>{t('app:claim.unfortunatelySomethingGoesWrong')}</Typography>}
        </Dialog>
    );
};

export default styled(Component)``;
