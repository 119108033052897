import React, { Dispatch, FC, SetStateAction } from 'react';
import { Grid, styled } from '@mui/material';
import { CardSkeleton, CardWrapper, Dialog, GuardianCard } from '../../components';
import { RootState, useAppDispatch } from '../../redux/Store';
import { setEnemy } from '../../redux/actions/battle';
import { useSelector } from 'react-redux';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../types/Seiren';
import { useEnemies } from '../../api';

interface Props {
    className?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const Component: FC<Props> = ({ className, open, setOpen }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const guardian = useSelector((state: RootState) => state.battle.selected.guardian);
    const bonus = useSelector((state: RootState) => state.battle.selected.bonus);
    const authKey = useSelector((state: RootState) => state.app.authKey);

    const { isLoading, data: cards } = useEnemies(
        authKey,
        {
            guardian,
            bonus,
        },
        { cacheTime: 0 },
    );

    const confirm = (card: Seiren.GuardianCard) => {
        dispatch(setEnemy({ enemy: card }));

        setOpen(false);
    };

    const renderCard = (item: Seiren.GuardianCard) => (
        <Grid item lg={3} md={4} sm={6} xs={12} key={item.id}>
            <CardWrapper>
                <GuardianCard {...item} size="lg" onClick={() => confirm(item)} key={item.id} />
            </CardWrapper>
        </Grid>
    );

    const renderCardSkeletons = () => {
        return new Array(4).fill(null).map((tx, index) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                <CardSkeleton withTitle size="lg" />
            </Grid>
        ));
    };

    const close = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            title={t('pages:battle.enemySelect.selectYourEnemy')}
            description={t('pages:battle.enemySelect.pleaseChooseOneEnemy')}
            className={className}
            fullWidth
            maxWidth="lg"
            onClose={close}
        >
            <Grid
                marginTop={4}
                container
                alignItems="flex-start"
                justifyContent={{
                    xs: 'center',
                    md: 'flex-start',
                }}
                spacing={4}
                pt={2}
            >
                {!isLoading && cards!.map(renderCard)}
                {isLoading && renderCardSkeletons()}
            </Grid>
        </Dialog>
    );
};

export default styled(Component)``;
