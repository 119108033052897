import React, { ChangeEvent, FC } from 'react';
import {
    Button,
    Container,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import { sendForm } from '../../../redux/actions/swap';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props {
    className?: string;
}

interface IFormInput {
    amountFrom: number;
    currencyFrom: number;
    amountTo: number;
    currencyTo: number;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const balances: Seiren.Balance[] = useSelector((state: RootState) => state.player.balance);
    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<IFormInput>();

    const currencyTo = watch('currencyTo');
    const currencyFrom = watch('currencyFrom');

    const handleCurrencyFromChange = (event: SelectChangeEvent) => {
        setValue('currencyFrom', Number(event.target.value));
        // @ts-ignore
        const v = watch('amountTo') * (balances[watch('currencyFrom')]?.rate / balances[watch('currencyTo')]?.rate);
        setValue('amountFrom', v);
    };
    const handleCurrencyToChange = (event: SelectChangeEvent) => {
        setValue('currencyTo', Number(event.target.value));
        // @ts-ignore
        const v = watch('amountFrom') * (balances[watch('currencyTo')]?.rate / balances[watch('currencyFrom')]?.rate);
        setValue('amountTo', v);
    };

    const handleAmountFromChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue('amountFrom', Number(event.target.value));
        // @ts-ignore
        const v = watch('amountFrom') * (balances[watch('currencyTo')]?.rate / balances[watch('currencyFrom')]?.rate);
        setValue('amountTo', v);
    };
    const handleAmountToChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue('amountTo', Number(event.target.value));
        // @ts-ignore
        const v = watch('amountTo') * (balances[watch('currencyFrom')]?.rate / balances[watch('currencyTo')]?.rate);
        setValue('amountFrom', v);
    };

    const onSubmit = (data: IFormInput) => {
        dispatch(sendForm({ formData: data }));
        alert(JSON.stringify(data));
    };

    return (
        <div className={className}>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)} className="swap-form">
                    <FormControl variant="outlined">
                        <Typography align="right" variant="body2">
                            {t('pages:swap.layout.swapInitial.balance')}
                            <NumberFormat
                                value={balances[currencyFrom]?.balance}
                                displayType="text"
                                thousandSeparator
                            />{' '}
                            {balances[currencyFrom]?.name}
                        </Typography>
                        <div className="swap-form-box">
                            <Controller
                                render={({ field }) => (
                                    <FormControl error={errors.amountFrom !== null} {...field} className="txt-field">
                                        <NumberFormat
                                            customInput={TextField}
                                            allowNegative={false}
                                            placeholder={t('pages:swap.layout.swapInitial.enterTheAmount')}
                                            label={t('pages:swap.layout.swapInitial.from')}
                                            variant="filled"
                                            value={field.value.toString()}
                                            onChange={handleAmountFromChange}
                                            color="secondary"
                                        />
                                        <FormHelperText>
                                            {errors.amountFrom && errors.amountFrom.message}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                                name="amountFrom"
                                defaultValue={0}
                                control={control}
                                rules={{
                                    max: {
                                        value: balances[currencyFrom]?.balance,
                                        message: "Amount's bigger than your balance",
                                    },
                                    required: 'Amount required',
                                }}
                            />
                            <Controller
                                render={({ field }) => (
                                    <FormControl {...field}>
                                        <Select
                                            value={field.value.toString()}
                                            onChange={handleCurrencyFromChange}
                                            variant="filled"
                                        >
                                            {balances.map((balance) => (
                                                <MenuItem value={balance.id} key={balance.id}>
                                                    {balance.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                name="currencyFrom"
                                defaultValue={0}
                                control={control}
                            />
                        </div>
                        <Typography align="right" variant="body2">
                            {t('pages:swap.layout.swapInitial.balance')}
                            <NumberFormat
                                value={balances[currencyTo]?.balance}
                                displayType="text"
                                thousandSeparator
                            />{' '}
                            {balances[currencyTo]?.name}
                        </Typography>
                        <div className="swap-form-box">
                            <Controller
                                render={({ field }) => (
                                    <FormControl error={errors.amountTo !== null} {...field} className="txt-field">
                                        <NumberFormat
                                            customInput={TextField}
                                            allowNegative={false}
                                            variant="filled"
                                            placeholder={t('pages:swap.layout.swapInitial.enterTheAmount')}
                                            label={t('pages:swap.layout.swapInitial.to')}
                                            value={field.value.toString()}
                                            onChange={handleAmountToChange}
                                            color="secondary"
                                        />
                                        <FormHelperText>{errors.amountTo && errors.amountTo.message}</FormHelperText>
                                    </FormControl>
                                )}
                                name="amountTo"
                                control={control}
                                defaultValue={0}
                                rules={{
                                    required: 'Amount required',
                                }}
                            />
                            <Controller
                                render={({ field }) => (
                                    <FormControl {...field}>
                                        <Select
                                            value={field.value.toString()}
                                            onChange={handleCurrencyToChange}
                                            className="select-field"
                                            variant="filled"
                                        >
                                            {balances.map((balance) => (
                                                <MenuItem value={balance.id} key={balance.id}>
                                                    {balance.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                name="currencyTo"
                                defaultValue={1}
                                control={control}
                            />
                        </div>
                        <div>
                            <Button variant="confirm" type="submit">
                                <Typography color="white">{t('pages:swap.layout.swapInitial.trade')}</Typography>
                            </Button>
                        </div>
                    </FormControl>
                </form>
            </Container>
        </div>
    );
};

export default styled(Component)`
    max-width: 340px;

    .swap-form {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .txt-field {
        padding-bottom: ${({ theme }) => theme.spacing(4)};
    }

    .select-field {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        min-width: 80px;
    }

    .swap-form-box {
        display: flex;
        flex-direction: row;
    }
`;
