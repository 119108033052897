import { AnyAction } from 'redux';
import { SET_STATUS } from '../actions/swap';

interface ReducerState {
    status: 'initial' | 'failed' | 'success';
}

const initialState: ReducerState = {
    status: 'initial',
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_STATUS: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
