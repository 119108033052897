import React, { FC } from 'react';
import { Grid, GridProps, styled } from '@mui/material';
import DividerCorner from '../../assets/divider/divider_corner.svg';
import DividerLine from '../../assets/divider/divider_line.svg';
import DefaultDivider from '../../assets/divider/default_divider.svg';
import DivideMiddleLine from '../../assets/divider/divider_middle_line.svg';

interface Props extends GridProps {
    className?: string;
    type?: 'top' | 'middle' | 'full-middle' | 'bottom' | 'default';
}

const Component: FC<Props> = ({ className, type, ...props }) => {
    if (type === 'default') {
        return (
            <div className={className}>
                <img alt="default-divider" src={DefaultDivider} className="default-divider" />
            </div>
        );
    }

    return (
        <Grid
            container
            className={className}
            alignItems={type === 'bottom' ? 'flex-end' : type === 'top' ? 'flex-start' : 'center'}
            spacing={2}
            {...props}
        >
            {type !== 'full-middle' && (
                <Grid item xs="auto" sm="auto" md="auto" sx={{ minWidth: 50 }}>
                    {type !== 'middle' && <img alt="corner-left" className="corner-left" src={DividerCorner} />}
                </Grid>
            )}
            <Grid item xs sm md>
                <img
                    alt="divider-line"
                    className="line"
                    src={type === 'middle' || type === 'full-middle' ? DivideMiddleLine : DividerLine}
                />
            </Grid>
            {type !== 'full-middle' && (
                <Grid item xs="auto" sm="auto" md="auto" sx={{ minWidth: 50 }}>
                    {type !== 'middle' && <img alt="corner-right" className="corner-right" src={DividerCorner} />}
                </Grid>
            )}
        </Grid>
    );
};

Component.defaultProps = {
    type: 'default',
};

export default styled(Component)`
    .default-divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .corner-left {
        transform: rotate(${({ type }) => (type === 'bottom' ? '180deg' : '-90deg')});
    }

    .corner-right {
        transform: rotate(${({ type }) => (type === 'bottom' ? '90deg' : '0deg')});
    }

    .line {
        width: 100%;
        height: ${({ type }) => (type === 'middle' || type === 'full-middle' ? '10px' : '4px')};
        object-fit: cover;
        transform: scaleY(${({ type }) => (type === 'bottom' ? '-1' : '1')});
    }
`;
