import { Seiren } from '../types/Seiren';
import axios from 'axios';
import _ from 'lodash';

interface Params {
    token: string;
    guardian: Seiren.GuardianCard;
    bonus: Seiren.Bonus;
    enemy: Seiren.GuardianCard;
}

export default async function (params: Params) {
    try {
        const { data } = await axios.post(
            `${axios.defaults.baseURL}/fight`,
            {
                guardian: params.guardian.id,
                bonus: params.bonus.id,
                enemy: params.enemy.id,
            },
            {
                headers: { Authentication: params.token },
            },
        );

        if (!data?.error) {
            return _.mapKeys(data.data, (v: any, k: string) => _.camelCase(k));
        } else {
            throw data.error;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e.response?.data.error);

            if (e.response?.data) {
                throw e.response?.data.error;
            } else {
                throw e;
            }
        } else {
            console.error('unexpected error: ', e);

            throw e;
        }
    }
}
