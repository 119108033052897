import { Seiren } from '../../types/Seiren';

export const SET_IN_STATE = 'battle/SET_IN_STATE';
export const SET_IN_SELECTED_STATE = 'battle/IN_SELECTED_STATE';
export const SET_RESULT = 'battle/SET_RESULT';
export const RESET_ENEMY = 'battle/RESET_ENEMY';
export const RESET_BATTLE = 'battle/RESET_BATTLE';
export const RESET_BONUS = 'batte/RESET_BONUS';

export const setCards = (payload: { guardian?: Seiren.GuardianCard; bonus?: Seiren.Bonus; count?: number }) => ({
    type: SET_IN_SELECTED_STATE,
    payload,
});

export const setEnemy = (payload: { enemy?: Seiren.GuardianCard }) => ({
    type: SET_IN_SELECTED_STATE,
    payload,
});

export const setStatus = (payload: { status: 'preparing' | 'finished' | 'fighting' }) => ({
    type: SET_IN_STATE,
    payload,
});

export const setResult = (payload: { success: boolean; reward: number }) => ({
    type: SET_RESULT,
    payload,
});

export const resetEnemy = () => ({
    type: RESET_ENEMY,
});

export const resetBattle = () => ({
    type: RESET_BATTLE,
});

export const resetBonus = () => ({
    type: RESET_BONUS,
});
