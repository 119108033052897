import React, { FC } from 'react';
import styled from 'styled-components';
import background from '../../../assets/card/wrapper/reward/background.png';
import BottomFront from '../../../assets/card/wrapper/reward/bottom-front.png';

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
}

const Component: FC<Props> = ({ className, children }) => {
    return (
        <div className={className}>
            <div />
            <img src={BottomFront} alt="" className="bottom" />
            <div className="children">{children}</div>
        </div>
    );
};

export default styled(Component)`
    background-image: url(${background});
    background-repeat: no-repeat;
    min-height: 400px;
    background-size: contain;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    margin-bottom: ${({ theme }) => theme.spacing(8)};

    .bottom {
        margin-bottom: -2%;
    }

    .children {
        position: absolute;
        z-index: -1;
        top: calc(50% - 182px);
    }
`;
