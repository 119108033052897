import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { SectionHeader } from '../../../components';
import styled from 'styled-components';

import storyImg from '../../../assets/home/story/Main_Story_Img_1200px.png';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <SectionHeader title={t('pages:home.layout.story.story')} />
            <Typography textAlign="center" gutterBottom variant="h5" className="story-txt margin" color="white">
                {t('pages:home.layout.story.serenSStoryAboutTheSea')}
            </Typography>
            <Typography textAlign="center" gutterBottom variant="h5" className="story-txt" color="white">
                {t('pages:home.layout.story.aLongTimeAgo')}
            </Typography>
            <Button color="secondary">{t('pages:home.layout.story.more')}</Button>
            <img src={storyImg} className="img" alt={t('pages:home.layout.story.story')} />
        </div>
    );
};

export default styled(Component)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(7, 0)};

    .story-txt {
        text-transform: uppercase;
        width: 80%;
    }

    .margin {
        margin: ${({ theme }) => theme.spacing(5, 0, 3, 0)};
    }

    .img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
`;
