import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { resetShowInfo } from '../../../redux/actions/player';
import { Dialog } from '../../../components';
import yellowEl from '../../../assets/elements/yellow.png';
import greenEl from '../../../assets/elements/green.png';
import blueEl from '../../../assets/elements/blue.png';
import redEl from '../../../assets/elements/red.png';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch();
    const status = useSelector((state: RootState) => state.player.status);
    const bonus = useSelector((state: RootState) => state.player.selected.bonus);
    const { power, element, name, avatar, stars, durability, attributes } = bonus;
    const { t } = useTranslation();

    const close = () => {
        dispatch(resetShowInfo());
    };

    let elImg;
    switch (element) {
        case 'lightning':
            elImg = yellowEl;
            break;
        case 'earth':
            elImg = greenEl;
            break;
        case 'water':
            elImg = blueEl;
            break;
        default:
            elImg = redEl;
    }

    return (
        <Dialog
            title="Information about Bonus"
            onClose={close}
            open={bonus && status === 'info'}
            className={className}
            fullWidth
            maxWidth="md"
            background={elImg}
        >
            <Grid container direction="row" paddingY={3} spacing={6} alignItems="center" justifyContent="center">
                <Grid item md={7} xs="auto">
                    <img src={avatar} alt="" width="100%" className="item" height={400} />
                </Grid>
                <Grid item md xs={12} className="info-box">
                    <img src={elImg} alt="" height={40} />
                    <Typography
                        variant="h7"
                        color="primary"
                        textTransform="uppercase"
                        fontWeight={700}
                        marginBottom={2.5}
                        pt={2}
                    >
                        {name}
                    </Typography>
                    <div>
                        <Typography color="secondary.contrastText" textTransform="uppercase" display="inline" pr={1}>
                            {t('components:common.cards.bonusTooltip.element')}
                        </Typography>
                        <Typography display="inline">{element[0]?.toUpperCase() + element?.slice(1)}</Typography>
                    </div>
                    <div>
                        <Typography display="inline" textTransform="uppercase" pr={1} color="secondary.contrastText">
                            {t('components:common.cards.bonusTooltip.durability')}
                        </Typography>
                        <Typography display="inline">{durability} / 200</Typography>
                    </div>
                    <div>
                        <Typography display="inline" textTransform="uppercase" pr={1} color="secondary.contrastText">
                            {t('components:common.cards.bonusTooltip.stars')}
                        </Typography>
                        <Typography display="inline">{stars}</Typography>
                    </div>
                    <div>
                        <Typography display="inline" textTransform="uppercase" pr={1} color="secondary.contrastText">
                            {t('components:common.cards.bonusTooltip.power')}
                        </Typography>
                        <Typography display="inline">{power}</Typography>
                    </div>
                    {attributes.map((attr: Seiren.Attribute) => (
                        <div key={attr.id}>
                            <Typography
                                display="inline"
                                textTransform="uppercase"
                                pr={1}
                                color="secondary.contrastText"
                            >
                                {attr.element}
                            </Typography>
                            <Typography display="inline">{attr.value}</Typography>
                        </div>
                    ))}
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default styled(Component)`
    .info-box {
        text-align: left;
    }

    .item {
        object-fit: contain;
    }
`;
