import React, { FC, useEffect } from 'react';
import { Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Divider } from '../../components';
import styled from 'styled-components';

import { RootState, useAppDispatch } from '../../redux/Store';
import { useSelector } from 'react-redux';
import { SwapFailed, SwapInitial, SwapSuccess } from './layout';
import { setStatus } from '../../redux/actions/swap';

import swapPageBack from '../../assets/backgrounds/background-3/background_03.jpg';
import swapBack from '../../assets/swap/swap-background.png';
import fish from '../../assets/swap/fish.png';
import algae from '../../assets/swap/algae.png';
import bubbles from '../../assets/swap/bubbles.png';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useAppDispatch();
    const status = useSelector((state: RootState) => state.swap.status);
    useEffect(() => {
        dispatch(setStatus({ status: 'initial' }));
    }, []);

    return (
        <div className={`${className} background`}>
            <Container maxWidth="md">
                <Typography variant="h4" textAlign="center" color="white">
                    {t('pages:swap.swap.swap')}
                </Typography>
                <Typography color="secondary.contrastText" align="center" className="swap-subtitle">
                    {t('pages:swap.swap.swapYourSerOrAokWithEachOther')}
                </Typography>
                <Divider />
                <div className="swap-background-wrapper">
                    {hidden && (
                        <>
                            <img src={fish} alt="fish" className="fish-img" />
                            <img src={algae} alt="algae" className="algae-img" />
                            <img src={bubbles} alt="bubbles" className="bubbles-img" />
                        </>
                    )}

                    <div className="swap-content-wrapper">
                        {status === 'initial' ? (
                            <SwapInitial />
                        ) : status === 'success' ? (
                            <SwapSuccess />
                        ) : (
                            <SwapFailed />
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default styled(Component)`
    background-image: url(${swapPageBack});
    padding: ${({ theme }) => theme.spacing(21, 0)};

    .swap-subtitle {
        margin: ${({ theme }) => theme.spacing(2.5, 0, 1.5, 0)};
    }

    .swap-background-wrapper {
        height: 577px;
        position: relative;
    }

    .swap-content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        //position: absolute;
        //left: 0;
        //right: 0;
        //top: -50px;
        height: 577px;
        background: url(${swapBack}) no-repeat top center;
    }

    .fish-img {
        position: absolute;
        left: 75px;
        top: 50px;
    }

    .algae-img {
        position: absolute;
        right: 75px;
        bottom: 0;
    }

    .bubbles-img {
        position: absolute;
        left: 75px;
        top: -50px;
    }
`;
