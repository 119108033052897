import React, { FC } from 'react';
import styled from 'styled-components';
import rankNext from '../../assets/rank/rank-next.svg';
import { Typography } from '@mui/material';

interface Props {
    className?: string;
    rank?: number;
}

const Component: FC<Props> = ({ className, rank }) => {
    return (
        <div className={className}>
            <div className="wrapper">
                <Typography>{rank}</Typography>
            </div>
        </div>
    );
};

Component.defaultProps = {
    rank: 1,
};

export default styled(Component)`
    .wrapper {
        background: url('${rankNext}');
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
