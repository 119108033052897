import React, { FC } from 'react';
import styled from 'styled-components';
import { Avatar, Link, TableCell, TableRow, Typography } from '@mui/material';
import { Seiren } from '../../../types/Seiren';
import { Rank } from '../../../components';

interface Props extends Seiren.Player {
    className?: string;
}

const Component: FC<Props> = ({ className, avatar, rank, address, level, xp }) => {
    return (
        <TableRow key={rank} className={className}>
            <TableCell>
                <Rank rank={rank} />
            </TableCell>
            <TableCell>
                <Typography align="center" color="white" display="flex" alignItems="center">
                    <Avatar src={avatar} className="margin-right" />
                    <Link
                        color="common.white"
                        href={`https://aokscan.com/address/${address}`}
                        target="_blank"
                        underline="hover"
                    >
                        {address}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Typography align="center" color="white">
                    {level + 1}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Typography align="center" color="white">
                    {xp}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default styled(Component)`
    .margin-right {
        margin-right: ${({ theme }) => theme.spacing(2)};
    }
`;
