import React, { FC, MouseEventHandler } from 'react';
import { ButtonBase, Typography } from '@mui/material';
import styled from 'styled-components';
import playerPlus from '../../../assets/card/noCard/Player_Plus_Img_48px.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    unselectedTitle?: string;
    disabled?: boolean;
    size: 'md' | 'lg';
}

const Component: FC<Props> = ({ className, onClick, unselectedTitle, disabled, size }) => {
    const { t } = useTranslation();
    const lgCard = (
        <div className="lg-card">
            <Typography color="primary" variant="caption" textTransform="uppercase" flex={1}>
                {unselectedTitle ? unselectedTitle : 'No Guardian selected.'}
            </Typography>
            <div className="center">
                <div className="plus" />
                <div className="rect large" />
                <div className="rect top" />
                <div className="rect bottom" />
                <div className="rect left" />
                <div className="rect right" />
            </div>
            <div className="rect top" />
            <div className="rect bottom" />
            <div className="rect left" />
            <div className="rect right" />
        </div>
    );

    const mdCard = (
        <div className="md-card">
            <img src={playerPlus} alt={t('components:common.cards.unselectedCard.plus')} className="plus-img" />
            <Typography variant="body2" color="white">
                {unselectedTitle ? unselectedTitle : 'No Guardian selected.'}
            </Typography>
        </div>
    );

    return (
        <ButtonBase onClick={onClick} disabled={disabled}>
            <div className={className}>{size === 'lg' ? lgCard : mdCard}</div>
        </ButtonBase>
    );
};

export default styled(Component)`
    text-transform: none;
    transition: 0.2s ease;

    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    .plus {
        position: absolute;
        width: 50px;
        height: 50px;
        background: linear-gradient(
                ${({ theme }) => theme.palette.primary.main},
                ${({ theme }) => theme.palette.primary.main}
            ),
            linear-gradient(${({ theme }) => theme.palette.primary.main}, ${({ theme }) => theme.palette.primary.main});
        background-position: center;
        background-size: 50% 2px, 2px 50%; /*thickness = 2px, length = 50% (25px)*/
        background-repeat: no-repeat;
    }

    .rect {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        opacity: 0.5;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        transform: rotate(45deg);
        transition: 0.2s ease;
    }

    .center {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        height: 85px;
    }

    .rect.top {
        top: -10px;
    }

    .rect.bottom {
        bottom: -10px;
    }

    .rect.left {
        left: -10px;
    }

    .rect.right {
        right: -10px;
    }

    .rect.large {
        width: 60px;
        height: 60px;
    }

    .lg-card {
        width: 250px;
        height: 360px;
        padding: ${({ theme }) => theme.spacing(6, 3)};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 2px solid ${({ theme }) => theme.palette.primary.main};
        position: relative;
    }

    .md-card {
        width: 142px;
        height: 180px;
        padding: ${({ theme }) => theme.spacing(1.5)};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid ${({ theme }) => theme.palette.common.white};
        color: #646464;
        border-radius: ${({ theme }) => theme.spacing(1)};
    }

    .plus-img {
        margin: ${({ theme }) => theme.spacing(0, 0, 4, 0)};
    }

    &:hover {
        box-shadow: 0px 0px 20px 0px rgba(252, 238, 174, 0.5);

        .rect {
            box-shadow: 0px 0px 20px 0px rgba(252, 238, 174, 0.5);
        }
    }
`;
