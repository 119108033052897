import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid, Pagination } from '@mui/material';
import { CardWrapper, GuardianCard } from '../../../components';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/Store';
import { setCurrentPage } from '../../../redux/actions/market';
import { GuardianBuy } from '../components';
import { CardTooltip } from '../../../components/common/cards';
import { Seiren } from '../../../types/Seiren';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch();
    const currentPage = useSelector((state: RootState) => state.market.currentPage);
    const pages = useSelector((state: RootState) => state.market.pages);
    const guardians = useSelector((state: RootState) => state.market.guardians);

    const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        dispatch(setCurrentPage({ currentPage: page }));
    };

    const renderCard = (card: Seiren.GuardianCard) => (
        <Grid key={card.id} item md={3} sm={6} xs="auto">
            <CardWrapper price={card.price}>
                <CardTooltip {...card}>
                    <GuardianCard {...card} size="lg" checkbox={false} />
                </CardTooltip>
            </CardWrapper>
        </Grid>
    );

    return (
        <div className={className}>
            <Grid
                container
                spacing={4}
                justifyContent={{
                    xs: 'center',
                    sm: 'center',
                    md: 'flex-start',
                }}
            >
                {guardians.map((guardian: Seiren.GuardianCard) => renderCard(guardian))}
            </Grid>
            {pages && (
                <Pagination
                    className="pagination"
                    count={pages}
                    page={currentPage}
                    onChange={onPageChange}
                    size="large"
                    showFirstButton
                    showLastButton
                />
            )}
            <GuardianBuy />
        </div>
    );
};

export default styled(Component)`
    margin-top: ${({ theme }) => theme.spacing(4)};
`;
