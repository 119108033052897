import React, { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';

interface Props extends Seiren.GuardianCard {
    className?: string;
    children: ReactElement<any, any>;
}

const Component: FC<Props> = ({ className, children, ...props }) => {
    const { power, level, stamina, xp, nextXp, element } = props;
    const { t } = useTranslation();

    const tooltipContent = (
        <div>
            {element && (
                <div>
                    <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                        {t('components:common.cards.cardTooltip.element')}
                    </Typography>
                    <Typography display="inline" variant="body2">
                        {element[0]?.toUpperCase() + element?.slice(1)}
                    </Typography>
                </div>
            )}
            {(xp || nextXp) && (
                <div>
                    <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                        {t('components:common.cards.cardTooltip.xp')}
                    </Typography>
                    <Typography display="inline" variant="body2">
                        {xp}/{nextXp}
                    </Typography>
                </div>
            )}
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {t('components:common.cards.cardTooltip.stamina')}
                </Typography>
                <Typography display="inline" variant="body2">
                    {stamina}/200
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {t('components:common.cards.cardTooltip.level')}
                </Typography>
                <Typography display="inline" variant="body2">
                    {level ? level + 1 : 1}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {t('components:common.cards.cardTooltip.power')}
                </Typography>
                <Typography display="inline" variant="body2">
                    {power}
                </Typography>
            </div>
        </div>
    );

    return (
        <Tooltip className={className} title={tooltipContent} arrow followCursor placement="right">
            <div>{children}</div>
        </Tooltip>
    );
};

export default styled(Component)``;
