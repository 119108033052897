import React, { FC } from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import { Filter, GuardianCardList } from './layout';
import marketBack from '../../assets/backgrounds/background-2/background_02@2x.jpg';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    return (
        <div className={`${className} background`}>
            <Container>
                <Filter />
                <GuardianCardList />
            </Container>
        </div>
    );
};

export default styled(Component)`
    background-image: url('${marketBack}');
    padding-top: ${({ theme }) => theme.spacing(21)};
`;
