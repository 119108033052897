import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import defaultAvatar from '../../../assets/card/guardianAvatar/guardian-avatar-default.png';

interface Props {
    title?: string;
    className?: string;
}

const Component: FC<Props> = ({ title, className }) => {
    return (
        <div className={className}>
            <div className="rectangle">
                <img src={defaultAvatar} alt="defaultAvatar" className="sm-avatar" />
            </div>
            <Typography color="white">{title}</Typography>
        </div>
    );
};

export default styled(Component)`
    display: flex;
    align-items: center;

    .rectangle {
        border-radius: 1rem;
        height: 60px;
        width: 60px;
        background-color: #544fa1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: ${({ theme }) => theme.spacing(2.5)};
    }
    .sm-avatar {
        height: 50px;
        width: 40px;
    }
`;
