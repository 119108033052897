import React, { FC, MouseEventHandler, useState } from 'react';
import { CardInfoItem, CardScaleItem } from '../index';
import { ButtonBase, Chip, Grid, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Seiren } from '../../../types/Seiren';
import { a, useSpring } from 'react-spring';

import yellowEl from '../../../assets/elements/yellow.png';
import greenEl from '../../../assets/elements/green.png';
import blueEl from '../../../assets/elements/blue.png';
import redEl from '../../../assets/elements/red.png';
import fightPoints from '../../../assets/card/fightPoints/fightPoints.png';
import levelIcon from '../../../assets/card/level.svg';
import defaultAvatar from '../../../assets/card/guardianAvatar/guardian-avatar-default.png';
import staminaIcon from '../../../assets/card/stamina.svg';
import backImage from '../../../assets/card/noCard/No_Card_Selet_Img_252px@3x.png';
import frame_light from '../../../assets/card/frames/frame_light.png';
import frame_earth from '../../../assets/card/frames/frame_earth.png';
import frame_fire from '../../../assets/card/frames/frame_fire.png';
import frame_water from '../../../assets/card/frames/frame_water.png';
import back_earth from '../../../assets/card/background/earth.png';
import back_fire from '../../../assets/card/background/fire.png';
import back_water from '../../../assets/card/background/water.png';
import back_lightning from '../../../assets/card/background/lightning.png';

interface Props extends Seiren.GuardianCard {
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    unselectedTitle?: string;
    disabled?: boolean;
    titleDisabled?: boolean;
    flipEnabled?: boolean;
}

const Component: FC<Props> = ({ className, onClick, titleDisabled, flipEnabled, ...props }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { name, power, level, element, size, stamina, checkbox, checked, maxLevel, setChecked, avatar, xp, nextXp } =
        props;

    const [flipped, set] = useState(false);
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
        config: {
            mass: 5,
            tension: 500,
            friction: 80,
            duration: 150,
        },
    });

    // element and background color logic
    let elImg;
    let color;

    switch (element) {
        case 'lightning':
            elImg = yellowEl;
            color = theme.card.yellowLv2;
            break;
        case 'earth':
            elImg = greenEl;
            color = theme.card.greenLv2;
            break;
        case 'water':
            elImg = blueEl;
            color = theme.card.blueLv2;
            break;
        default:
            elImg = redEl;
            color = theme.card.redLv2;
    }

    const flipCard = () => {
        if (flipEnabled) {
            set((state) => !state);
        }
    };

    const backContent = (
        <div className="back-content">
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Element'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {element && element[0]?.toUpperCase() + element?.slice(1)}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Xp'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {xp}/{nextXp}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Stamina'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {stamina}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Level'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {level ? level + 1 : 1}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" variant="body2" display="inline" pr={1}>
                    {'Power'}
                </Typography>
                <Typography display="inline" variant="body2">
                    {power}
                </Typography>
            </div>
        </div>
    );

    const title = name && (
        <Typography
            color={checked ? 'primary' : 'white'}
            variant="h7"
            textOverflow="ellipsis"
            width="80%"
            align="center"
            minHeight={48}
        >
            {name}
        </Typography>
    );

    const lgCardContent = (
        <>
            <a.div
                className="lg-card"
                style={{
                    opacity: opacity.to((o) => 1 - o),
                    transform,
                    background:
                        element === 'earth'
                            ? `url(${back_earth})`
                            : element === 'fire'
                            ? `url(${back_fire})`
                            : element === 'lightning'
                            ? `url(${back_lightning})`
                            : `url(${back_water})`,
                }}
            >
                {maxLevel === level && (
                    <Chip size="small" className="upgrade-chip" variant="filled" color="warning" label="Max Level" />
                )}
                <Grid container alignItems="center" columnSpacing={1}>
                    <Grid xs="auto" item>
                        <CardInfoItem title={power} icon={fightPoints} />
                    </Grid>
                    <Grid xs="auto" item>
                        <CardInfoItem title={level ? level + 1 : 1} icon={levelIcon} />
                    </Grid>
                    <Grid item xs display="flex" justifyContent="flex-end">
                        <img src={elImg} alt={t('components:common.cards.guardianCard.element')} height={34} />
                    </Grid>
                </Grid>
                <Grid container py={1} justifyContent="center">
                    <img
                        src={avatar || defaultAvatar}
                        alt={t('components:common.cards.guardianCard.avatar')}
                        height={195}
                    />
                </Grid>
                <Grid container justifyContent="space-between">
                    {nextXp !== undefined && xp !== undefined && (
                        <Grid item xs md={6}>
                            <CardScaleItem value={xp ? xp : 0} nextValue={nextXp ? nextXp : 0} icon={levelIcon} />
                        </Grid>
                    )}
                    <Grid item xs md={6}>
                        <CardScaleItem
                            value={stamina ? stamina : 0}
                            nextValue={200}
                            icon={staminaIcon}
                            color="secondary"
                        />
                    </Grid>
                </Grid>
            </a.div>
            <a.div
                className="card-back"
                style={{
                    opacity,
                    transform,
                    rotateY: '180deg',
                }}
            >
                {backContent}
            </a.div>
        </>
    );

    const lgCard = (
        <div onClick={flipCard}>
            {checkbox ? (
                <ButtonBase onClick={() => setChecked!(!checked)} className="lg-card-box">
                    {lgCardContent}
                </ButtonBase>
            ) : (
                <div className="lg-card-box">{lgCardContent}</div>
            )}
            {!titleDisabled && (
                <Grid container justifyContent="center" width={250}>
                    {title}
                </Grid>
            )}
        </div>
    );

    const mdCardContent = (
        <Grid container flexDirection="column" alignItems="center" rowSpacing={2}>
            <Grid item>
                <img
                    src={avatar || defaultAvatar}
                    alt={t('components:common.cards.guardianCard.avatar')}
                    height={100}
                />
            </Grid>
            <Grid item>
                <CardInfoItem title={power} icon={fightPoints} />
            </Grid>
        </Grid>
    );

    const mdCard = (
        <div>
            {checkbox ? (
                <ButtonBase onClick={() => setChecked!(!checked)} className="md-card" style={{ background: color }}>
                    {mdCardContent}
                </ButtonBase>
            ) : (
                <div style={{ background: color }} className="md-card">
                    {mdCardContent}
                </div>
            )}
            {!titleDisabled && (
                <Grid container justifyContent="center" width={140}>
                    {title}
                </Grid>
            )}
        </div>
    );

    if (onClick) {
        return (
            <ButtonBase className={className} onClick={onClick}>
                {size === 'lg' ? lgCard : mdCard}
            </ButtonBase>
        );
    }

    return <div className={className}>{size === 'lg' ? lgCard : mdCard}</div>;
};

export default styled(Component)`
    .upgrade-chip {
        position: absolute;
        top: -${({ theme }) => theme.spacing(1.5)};
        right: -${({ theme }) => theme.spacing(2)};
    }

    .lg-card {
        position: relative;
        // border-radius: ${({ theme }) => theme.spacing(1)};
        padding: ${({ theme }) => theme.spacing(3)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        width: 250px;
        height: 360px;
        background: ${({ theme }) => theme.palette.primary.main};
        opacity: ${({ checked }) => (checked ? '0.6 !important' : '1')};
        // border: ${({ theme, checked }) => (checked ? `2px solid ${theme.palette.primary.main}` : 'none')};

        /*&:after {
            content: '';
            display: ${({ checked }) => (checked ? 'block' : 'none')};
            position: absolute;
            width: 100%;
            height: 100%;
            background: black;
            opacity: 0.5;
        }*/

        &:before {
            content: '';
            position: absolute;
            background: url(${({ element }) =>
                element === 'earth'
                    ? frame_earth
                    : element === 'fire'
                    ? frame_fire
                    : element === 'lightning'
                    ? frame_light
                    : frame_water});
            width: calc(100% + 20px);
            height: calc(100% + 25px);
            background-size: contain;
            background-repeat: no-repeat;
            top: -15px;
            left: -10px;
        }
    }

    .md-card {
        position: relative;
        overflow: hidden;
        border-radius: ${({ theme }) => theme.spacing(2)};
        padding: ${({ theme }) => theme.spacing(2)};
        margin-bottom: ${({ theme }) => theme.spacing(1)};
        width: 140px;
        height: 180px;
        border: ${({ theme, checked }) => (checked ? `2px solid ${theme.palette.primary.main}` : 'none')};

        &:after {
            content: '';
            display: ${({ checked }) => (checked ? 'block' : 'none')};
            position: absolute;
            width: 100%;
            height: 100%;
            background: black;
            opacity: 0.5;
        }
    }

    .card-back {
        height: 360px;
        width: 250px;
        position: absolute;
        border-radius: ${({ theme }) => theme.spacing(1)};
        background: ${({ theme }) => theme.palette.secondary.main};
        border: ${({ theme }) => theme.palette.primary.main} solid 1px;

        &:before {
            content: '';
            position: absolute;
            background: url(${({ element }) =>
                element === 'earth'
                    ? frame_earth
                    : element === 'fire'
                    ? frame_fire
                    : element === 'lightning'
                    ? frame_light
                    : frame_water});
            width: calc(100% + 20px);
            height: calc(100% + 25px);
            background-size: contain;
            background-repeat: no-repeat;
            top: -15px;
            left: -10px;
        }
    }

    .lg-card-box {
        display: flex;
    }

    .back-content {
        color: ${({ theme }) => theme.palette.common.white};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background: url(${backImage}) no-repeat center;
    }
`;
