import React, { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { RootState } from '../../../redux/Store';
import { useRanking } from '../../../api';
import { TopPlayer, TopPlayerSkeleton } from '../components';

interface Props {
    className?: string;
}

const Component: FC<Props> = ({ className }) => {
    const authKey = useSelector((state: RootState) => state.app.authKey);
    const { error, status, data: players, isLoading } = useRanking(authKey, { page: 1 });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (status === 'error') {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    }, [status]);

    return (
        <div className={className}>
            {!isLoading && players ? (
                <Grid container alignItems="center" justifyContent="center" columnSpacing={8}>
                    <Grid item>
                        <TopPlayer topPlayer={players.list[1]} />
                    </Grid>
                    <Grid item>
                        <TopPlayer topPlayer={players.list[0]} />
                    </Grid>
                    <Grid item>
                        <TopPlayer topPlayer={players.list[2]} />
                    </Grid>
                </Grid>
            ) : (
                <Grid container alignItems="center" justifyContent="center" columnSpacing={8}>
                    <Grid item>
                        <TopPlayerSkeleton rank={2} />
                    </Grid>
                    <Grid item>
                        <TopPlayerSkeleton rank={1} />
                    </Grid>
                    <Grid item>
                        <TopPlayerSkeleton rank={2} />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default styled(Component)``;
